<form [formGroup]="formProduct">
    <mat-expansion-panel #expProduct class="card">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-checkbox class="mt-3 mb-3" [(ngModel)]="this.product.selected" formControlName="productId"><span
                        class="checkbox-label">{{this.product.productName}}</span></mat-checkbox>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="container">
            <div *ngFor="let dv of this.product.deliverables">
                <app-deliverable [deliverable]="dv"></app-deliverable>
            </div>
        </div>
    </mat-expansion-panel>

</form>