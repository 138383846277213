import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss'],
})
export class FilterChipComponent {
  @Input() label: string;
  @Input() value: string;
  @Output() removeEvent = new EventEmitter<void>();

  remove() {
    this.removeEvent.emit();
  }
}
