import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-column-stylizer',
  templateUrl: './column-stylizer.component.html',
  styleUrls: ['./column-stylizer.component.scss']
})
export class ColumnStylizerComponent implements OnChanges {
  @Input() columnValue: any;
  @Input() typeColumn: any;
  @Input() columnRules: ColumnColoringRules[];

  actualRules: ColumnColoringRules;

  ngOnChanges(): void {
    this.updateActualRule();
  }

  updateActualRule() {
    this.actualRules = this.columnRules.find(cr => cr.columnValue == this.columnValue);
    if (!this.actualRules) this.actualRules = this.columnRules.find(cr => cr.columnValue == 'default');
  }

  getStars(rating: number): string[] {
    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = Math.floor(roundedRating);
    const halfStars = roundedRating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    for (let i = 0; i < fullStars; i++) {
      stars.push('filled');
    }

    if (halfStars) {
      stars.push('half');
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push('empty');
    }

    return stars;
  }

  convertStatus(status:string) {
    return status.replace('_', ' ')
  }

}

export interface ColumnColoringRules {
  columnValue: any,
  softValue?: string,
  containerClass?: string,
  containerStyle?: object,
  valueClass?: string,
  valueStyle?: object
}