import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MissionFinancialExpenseComponent } from './components/mission-financial-expense.component';

const routes: Routes = [
  {
    path: '',
    component: MissionFinancialExpenseComponent,
    canActivate: [AuthGuard, PermissionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MissionFinancialExpenseRoutingModule {}
