import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';

@Injectable()
export class ChangePasswordInterceptor implements HttpInterceptor {

  constructor(private toaster: ToasterService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if(event.status == 204 && event.url.includes('account/my-profile/change-password')){
          this.toaster.success("Password changed successfully");
        }
      }
      return event;
    }));
  }
}
