import { ABP, ListService, PagedResultDto, TrackByService, downloadBlob } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type {
  GetLocationInput,
  GetPortafolioInput,
  GetProjectInput,
  LocationsDto,
  PortafoliosDto,
  ProjectsDto,
} from '../../../proxy/missions-service/basics/models';
import { ProjectsService } from '../../../proxy/missions-service/controllers/basics/projects.service';
import { EnumStateOptions, enumState } from '../../../proxy/missions-service/shared';
import { PortafoliosService } from '../../../proxy/missions-service/controllers/basics/portafolios.service';
import { LocationsService } from '../../../proxy/missions-service/controllers/basics/locations.service';
import {
  ConfigurationAttributeTypesDto,
  ConfigurationTypesDto,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { ColumnAction } from 'projects/flyguys-pilot/src/app/components/columns/components/column-actions/column-actions.component';
import { ColumnStatus } from 'projects/flyguys-pilot/src/app/components/columns/components/column-displayer/column-displayer.component';
import { stateRules } from 'projects/flyguys-pilot/src/app/components/columns/rules/lookup-rules';
@Component({
  selector: 'lib-projects',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './projects.component.html',
  styles: [],
})
export class ProjectsComponent implements OnInit {
  private readonly MAX_COUNT_ATTR = 'MaxResultCount';

  data: PagedResultDto<ProjectsDto> = {
    items: [],
    totalCount: 0,
  };

  portafolios: PagedResultDto<PortafoliosDto> = {
    items: [],
    totalCount: 0,
  };

  locations: PagedResultDto<LocationsDto> = {
    items: [],
    totalCount: 0,
  };

  filters = { isPaginated: true } as GetProjectInput;

  portafolioFilter = { state: enumState.Enabled, isPaginated: true } as GetPortafolioInput;

  locationFilter = { state: enumState.Enabled, isPaginated: true } as GetLocationInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: ProjectsDto;

  states = EnumStateOptions;

  portafolioDict: { [id: string]: string } = {};

  locationDict: { [id: string]: string } = {};

  @Input() export: Boolean = false;

  MaxResultCount = 100;

  clientMaxResultCount = 10;
  pageNumber = 0;

  showedColumns = {
    actions: true,
    name: true,
    portafolio: true,
    recurence: true,
    location: true,
    state: true,
  };

  stateRules = stateRules;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: ProjectsService,
    public readonly portafolioService: PortafoliosService,
    public readonly portafolioList: ListService,
    public readonly locationService: LocationsService,
    public readonly locationList: ListService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    private confirmation: ConfirmationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.getMaxCountAttribute();
    this.getProjectValues();
    this.getPortfolioValues();
    this.getLocationValues();
  }

  clearFilters() {
    this.filters = { isPaginated: true } as GetProjectInput;
  }

  buildForm() {
    const { name, portafolioId, recurence, locationId, state } = this.selected || {};

    this.form = this.fb.group({
      name: [name ?? null, [Validators.required, Validators.maxLength(100)]],
      portafolioId: [portafolioId ?? null, [Validators.required]],
      recurence: [recurence ?? null, [Validators.required]],
      locationId: [locationId ?? null, [Validators.required]],
      state: [state ?? '1', [Validators.required]],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.id, this.form.value)
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: ProjectsDto) {
    this.selected = record;
    this.showForm();
  }

  delete(record: ProjectsDto) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'Projects.xlsx');
      });
  }

  updateLocations(value) {
    this.locationFilter.gpsCoordinates = value;
    this.getLocationValues();
  }

  updatePortafolio(value) {
    this.portafolioFilter.name = value;
    this.getPortfolioValues();
  }

  private getProjectValues(): void {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<ProjectsDto>) => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  private getPortfolioValues(): void {
    const query = {} as ABP.PageQueryParams;
    this.portafolioFilter.maxResultCount = this.MaxResultCount;

    this.portafolioService
      .getList({
        ...query,
        ...this.portafolioFilter,
        filterText: query.filter,
      })
      .subscribe(result => {
        this.portafolios = result;
        for (let dep of this.portafolios.items) {
          this.portafolioDict[dep.id] = dep.name;
        }
      });
  }

  private getLocationValues(): void {
    const query = {} as ABP.PageQueryParams;

    this.locationFilter.maxResultCount = this.MaxResultCount;

    this.locationService
      .getList({
        ...query,
        ...this.locationFilter,
        filterText: query.filter,
      })
      .subscribe(result => {
        this.locations = result;
        for (let dep of this.locations.items) {
          this.locationDict[dep.id] = dep.siteContact;
        }
      });
  }

  private getMaxCountAttribute(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        })
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.MaxResultCount = parseInt(attribute.description);
      });
  }

  columnActions(record: ProjectsDto) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'edit',
        abpPermission: 'missionsService.Project.Edit',
        action: { callAction: () => this.update(record) },
      },
      {
        actionIcon: 'delete',
        abpPermission: 'missionsService.Project.Delete',
        action: { callAction: () => this.delete(record) },
      },
    ];

    return columnActions;
  }

  toggler = (column: string, checked: boolean) => (this.showedColumns[column] = checked);

  columnsStatus() {
    const columnsStatus: ColumnStatus[] = [
      {
        columnName: 'actions',
        softColumnName: 'Actions',
        defaultChecked: this.showedColumns.actions,
        abpPermission: 'missionsService.Project.ShowColumn.Actions',
      },
      {
        columnName: 'name',
        softColumnName: 'Name',
        defaultChecked: this.showedColumns.name,
        abpPermission: 'missionsService.Project.ShowColumn.Name',
      },
      {
        columnName: 'portafolio',
        softColumnName: 'Portafolio',
        defaultChecked: this.showedColumns.portafolio,
        abpPermission: 'missionsService.Project.ShowColumn.Portafolio',
      },
      {
        columnName: 'recurence',
        softColumnName: 'Recurence',
        defaultChecked: this.showedColumns.recurence,
        abpPermission: 'missionsService.Project.ShowColumn.Recurence',
      },
      {
        columnName: 'location',
        softColumnName: 'Location',
        defaultChecked: this.showedColumns.location,
        abpPermission: 'missionsService.Project.ShowColumn.Location',
      },
      {
        columnName: 'state',
        softColumnName: 'State',
        defaultChecked: this.showedColumns.state,
        abpPermission: 'missionsService.Project.ShowColumn.State',
      },
    ];

    return columnsStatus;
  }
}
