import { Subscription } from 'rxjs';
import { ChunkFileModel } from './chunk-file-model';

export interface QueuedFile {
  chunkFileModel: ChunkFileModel;
  fileData: File;
  progress: number;
  status: QueuedFileStatus;
  activeRequests: number;
  directoryId: string;
  chunkRetryCount: number;
  retryCount: number;
  waitRetryUntil: Date;
  activeSubscriptions: Subscription[];
  uploadNetworkRate: number;
  lastFailedUploadTime?: Date;
  accumulatedFailedTime: number;
}

export enum QueuedFileStatus {
  AwaitingChunking,
  Chunking,
  ChunkingFailed,
  AwaitingUpload,
  Uploading,
  UploadFailed,
  Finishing,
  FinishingFailed,
  Canceled,
  Succeeded,
}
