import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GenderDto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  apiName = 'CoreService';

  GetGenders = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GenderDto>>({
      method: 'GET',
      url: `/api/core-service/genders`
    },
      { apiName: this.apiName, ...config });

  constructor(private restService: RestService) { }
}
