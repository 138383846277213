import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { MissionsServiceRoutingModule } from './missions-service-routing.module';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { environment } from 'projects/flyguys-pilot/src/environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    ThemeSharedModule,
    MissionsServiceRoutingModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
  ],
  exports: [],
})
export class MissionsServiceModule {
  static forChild(): ModuleWithProviders<MissionsServiceModule> {
    return {
      ngModule: MissionsServiceModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<MissionsServiceModule> {
    return new LazyModuleFactory(MissionsServiceModule.forChild());
  }
}
