import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ABP, ListService, TrackByService, PagedResultDto } from '@abp/ng.core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { PilotNotesService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import { finalize, tap } from 'rxjs/operators';
import { CategoriesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { CategoriesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { GetCaptureInput } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { enumState } from 'projects/missions-service/src/lib/proxy/missions-service/shared';
import {
  GetPilotNoteInput,
  PilotNotesDto,
} from 'projects/pilots-service/src/lib/proxy/pilots-service/relationals';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pilot-profile-notes',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './pilot-profile-notes.component.html',
  styleUrls: ['./pilot-profile-notes.component.scss'],
})
export class PilotProfileNotesComponent implements OnInit {
  @ViewChild('modal') modal: TemplateRef<any>;
  @Input() pilotId: string;

  notes: PagedResultDto<PilotNotesDto> = {
    items: [],
    totalCount: 0,
  };

  categories: PagedResultDto<CategoriesDto> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup;

  filters = {} as GetPilotNoteInput;

  isModalBusy = false;

  isModalOpen = false;

  searchText = '';

  userDict: { [id: string]: string } = {};

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: PilotNotesService,
    public readonly identityService: IdentityUserService,
    public readonly categoryService: CategoriesService,
    public readonly categoriesList: ListService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.filters = { pilotId: this.pilotId } as GetPilotNoteInput;
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<PilotNotesDto>) => (this.notes = list);

    this.list.hookToQuery(getData).subscribe(setData);

    this.getCategoryListValues();

    this.identityService.getList({ maxResultCount: 10 }).subscribe(res => {
      for (let user of res.items) {
        this.userDict[user.id] = user.name;
      }
    });

    this.buildForm();
  }

  hideForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showForm() {
    this.isModalOpen = true;
    this.dialog.open(this.modal, {
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });
  }

  buildForm() {
    this.form = this.fb.group({
      subject: [null, [Validators.required, Validators.maxLength(100)]],
      body: [null, [Validators.required]],
      categoryId: [null, [Validators.required]],
      pilotId: [this.pilotId, [Validators.required]],
    });
  }

  submitForm() {
    for (let cat of this.categories.items) {
      if (cat.id == this.form.value.categoryId) {
        this.form.value.descriptionCategory = cat.description;
        break;
      }
    }
    if (this.form.invalid) return;

    const request = this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  create() {
    this.showForm();
  }

  private getCategoryListValues(): void {
    const query = {} as ABP.PageQueryParams;
    const categoryFilter = { state: enumState.Enabled } as GetCaptureInput;

    this.categoryService
      .getList({
        ...query,
        ...categoryFilter,
        filterText: query.filter,
      })
      .subscribe(result => {
        this.categories = result;
      });
  }
}
