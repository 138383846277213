import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  NgZone,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';

import { FlyguysMapComponent, FlyguysMapMarker } from '@flyguys/map';

import {
  AirSpaceClassificationsDto,
  CountriesDto,
  GetAirSpaceClassificationInput,
  GetCountryInput,
  GetStateInput,
  StatesDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups/models';
import { OrderFormSiteModel } from '../../model/order-form-site.model';

import {
  AirSpaceClassificationsService,
  CountriesService,
  StatesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { ContactsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

import { FormHelpers } from 'projects/flyguys-pilot/src/app/form-helpers';

import { OrderRequestMissionDTO } from '../../dto/order-request-mission.dto';
import {
  AddressesCreateDto,
  CapturesCreateDto,
  ContactsCreateDto,
  DeliverableAspectCreateDto,
  LocationsCreateDto,
  MissionsCreateDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { contactTypeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-type.enum';
import { contactMethodEnum } from '../../../../../../../missions-service/src/lib/proxy/missions-service/shared/contact-method.enum';
import { levelCoordinationEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/level-coordination.enum';
import { recurrenceEnum } from '../../../../../../../missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
import { enumState } from 'projects/missions-service/src/lib/proxy/missions-service/shared';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { GoogleMap, MapKmlLayer } from '@angular/google-maps';

@Component({
  selector: 'app-order-site',
  templateUrl: './order-site.component.html',
  styleUrls: ['./order-site.component.scss'],
})
export class OrderSiteComponent implements OnInit, OnDestroy {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  @Input() site: OrderFormSiteModel;
  formOrderSite: FormGroup;

  datafrequency = Object.keys(recurrenceEnum)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: key,
    }));

  dataCaptureMust = [{ id: 1, name: 'On selected date' }];

  dataflexible = [
    { id: 1, name: 'No' },
    { id: 2, name: 'Yes' },
  ];

  dataAirspace: PagedResultDto<AirSpaceClassificationsDto> = {
    items: [],
    totalCount: 0,
  };
  filtersAirSpace = {} as GetAirSpaceClassificationInput;

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterCountries = {} as GetCountryInput;
  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };
  filterStates = {} as GetStateInput;

  private subscriptions: Subscription[] = [];

  public mapMarkers: FlyguysMapMarker[] = [];

  kmlLayer: MapKmlLayer;
  maplayer: any;

  constructor(
    public readonly list: ListService,
    public readonly airspaceService: AirSpaceClassificationsService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly contactsService: ContactsService,
    private datePipe: DatePipe,
    private ngZone: NgZone
  ) {
    this.formOrderSite = FormHelpers.buildValidatorSite();
  }

  ngOnInit() {
    this.getAirSpaceClasifications();
    this.getCountries();
    this.getStates();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getAirSpaceClasifications() {
    const subsairspace = this.airspaceService.getList(this.filtersAirSpace).subscribe(
      (data: PagedResultDto<AirSpaceClassificationsDto>) => {
        if (data?.totalCount != 0) {
          this.dataAirspace = data;
        }
      },
      error => {
        console.log('Error on getting Air Space classfications: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.push(subsairspace);
  }

  setDescriptionFromId(
    id: string,
    items: any[],
    fieldtoSet: string,
    fieldName: string,
    isEnum: Boolean = false
  ) {
    let item = {};
    if (isEnum) {
      item = items.find(x => x.value === Number(id?.toString()));
    } else {
      item = items.find(x => x.id === id?.toString());
    }
    this.site[fieldtoSet] = item?.[fieldName];
  }

  getCountries() {
    const subscountries = this.countriesService.getList(this.filterCountries).subscribe(
      (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount != 0) {
          this.dataCountries = data;
        }
      },
      error => {
        console.log('Error on getting Countries: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.push(subscountries);
  }

  getStates() {
    const subsstates = this.statesService.getList(this.filterStates).subscribe(
      (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount != 0) {
          this.dataStates = data;
        }
      },
      error => {
        console.log('Error on getting States : ' + JSON.stringify(error));
      }
    );
    this.subscriptions.push(subsstates);
  }

  uploadFile(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.kml';
    input.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const kmlContent = e.target.result;
          this.renderKML(kmlContent);
        };
        reader.readAsText(file);
      }
    });
    input.click();
  }

  renderKML(kmlContent: string) {
    /* console.log('pasa por aca');
    var chicago = new google.maps.LatLng(41.875696, -87.624207);
    var mapOptions = { zoom: 11, center: chicago };
    this.maplayer = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
    this.ngZone.run(() => {
      var ctaLayer = new google.maps.KmlLayer({
        url: 'https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml',
        suppressInfoWindows: true,
        map: this.maplayer,
        clickable: false,
      });
      console.log('pasa por tambien');
    });*/
  }

  handleMarkerClick(marker: FlyguysMapMarker): void {
    this.site.loclatitude = marker.lat.toString();
    this.site.loclongitude = marker.lng.toString();
    console.log('event  latitude' + marker.lat);
  }

  buildOrderRequestMissionDto(): OrderRequestMissionDTO {
    return {
      projectName: this.getProjectName(),
      recurrence: this.getRecurrenceFromFromById(this.site.frequencyId),
      mission: this.buildMissionDto(),
      capture: this.buildCaptureDto(),
      location: this.buildLocationDto(),
      address: this.buildAddressDto(),
      deliverableAspect: this.buildDeliveryAspectsDto(),
      locationContact: this.buildLocationContactDto(),
    };
  }

  getRecurrenceFromFromById(id: string) {
    for (const key of Object.keys(recurrenceEnum)) {
      if (recurrenceEnum[key] === id) {
        return recurrenceEnum[key];
      }
    }
  }

  buildMissionDto(): MissionsCreateDto {
    return {
      name: this.site.missionName,
      summary: this.site.missionSummary,
      state: enumState.Enabled,
      additionalNotes: this.site.aditionalNotes,
    };
  }

  buildCaptureDto(): CapturesCreateDto {
    return {
      date: this.datePipe.transform(this.site.captureDate, 'yyyy-MM-dd'),
      time: this.site.captureTime,
      flexibleCaptureTime: this.site.flexibleCaptureId == '2',
      captureDateMustId: this.site.captureDateMustId,
      state: enumState.Enabled,
    };
  }

  buildLocationDto(): LocationsCreateDto {
    return {
      gpsCoordinates: this.site.loclatitude + ',' + this.site.loclongitude,
      geoFenceRadio: 'geofenceratio', //TODO: Get Geofence Radio
      airSpaceClasificationId: this.site.locAirSpaceClasfId,
      state: enumState.Enabled,
    };
  }

  buildAddressDto(): AddressesCreateDto {
    return {
      streetAddress: this.site.locAddress,
      countryId: this.site.locCountryId,
      stateId: this.site.locStateId,
      city: this.site.locCity,
      zipCode: this.site.locZipCode,
      state: enumState.Enabled,
    };
  }

  buildDeliveryAspectsDto(): DeliverableAspectCreateDto {
    return {
      delirevableduedate: this.datePipe.transform(this.site.deliveryDate, 'yyyy-MM-dd'),
      notes: this.site.deliveryNotes,
      instructions: this.site.uploadingDataInstruction,
      deliveryAllSameTime: this.site.deliveryAllSameTime,
      state: enumState.Enabled,
    };
  }

  buildLocationContactDto(): ContactsCreateDto {
    return {
      contactTypeId: contactTypeEnum.External,
      firstName: this.site.siteContactName,
      lastName: this.site.siteContactLastName,
      phone: this.site.siteContactPhone,
      email: this.site.siteContactEmail,
      shareData: false,
      levelCommunicationId: this.getPreferredLevelCommunication(),
      contactMethodId: this.getPreferredContactMethod(),
      state: enumState.Enabled,
    };
  }

  getPreferredContactMethod(): contactMethodEnum | undefined {
    if (this.site.preferredContactEmail) {
      return contactMethodEnum.Email;
    } else if (this.site.preferredContactPhone) {
      return contactMethodEnum.Phone;
    } else {
      return undefined;
    }
  }

  getProjectName(): string | undefined {
    if (this.getRecurrenceFromFromById(this.site.frequencyId) == recurrenceEnum.NoRepeat) {
      return this.site.missionName;
    } else {
      return this.site.projectName;
    }
  }

  getPreferredLevelCommunication(): levelCoordinationEnum | undefined {
    if (this.site.levelCoordinationCoordinate) {
      return levelCoordinationEnum.Coordinate;
    } else if (this.site.levelCoordinationMeet) {
      return levelCoordinationEnum.Meet;
    } else if (this.site.levelCoordinationNotify) {
      return levelCoordinationEnum.Notify;
    } else {
      return undefined;
    }
  }

  /**
   * Processes the selected address so it can be used to fill the inputs
   * @param data
   */
  handleSelectedAddress(address: any) {
    // In theory we shouldn't combine ngModel with a FormControl, so I'm
    // putting this extra code for when we remove the unneded bindings
    this.site.loclatitude = address.lat;
    this.formOrderSite.get('loclatitude').patchValue(address.lat);

    this.site.loclongitude = address.lng;
    this.formOrderSite.get('loclongitude').patchValue(address.lng);

    this.site.locCity = address.city;
    this.formOrderSite.get('locCity').patchValue(address.city);

    this.site.locZipCode = address.zip;
    this.formOrderSite.get('locZipCode').patchValue(address.zip);

    this.site.locCountryId = this.discoverCountryId(address.country);
    this.formOrderSite.get('locCountryId').patchValue(this.site.locCountryId);

    this.site.locStateId = this.discoverStateId(address.state);
    this.formOrderSite.get('locStateId').patchValue(this.site.locStateId);

    this.site.locAddress = address.name;

    const siteMarker: FlyguysMapMarker = {
      id: 1,
      lat: parseFloat(address.lat),
      lng: parseFloat(address.lng),
    };

    this.map.clearMarkers();
    this.map.addMarker(siteMarker);
  }

  /**
   * Filters the countries collection by a name to obtain a country ID
   * @param name
   * @returns string
   */
  discoverCountryId(name: string): string {
    return this.dataCountries.items.find(country => country.description === name)?.id;
  }

  /**
   * Filters the states collection by a name to obtain a state ID
   * @param name string
   * @returns string
   */
  discoverStateId(name: string): string {
    return this.dataStates.items.find(state => state.description === name)?.id;
  }
}
