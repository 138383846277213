import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetQuestionTypeV2Input, QuestionTypeV2Dto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class QuestionTypesV2Service {
  apiName = 'CoreService';

  getList = (input: GetQuestionTypeV2Input, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<QuestionTypeV2Dto>>({
      method: 'GET',
      url: '/api/core-service/question-type-v2',
      params: { filterText: input.filterText },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
