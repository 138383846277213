import { ViewChild, TemplateRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToasterService } from '@abp/ng.theme.shared';
import { MatDialog } from '@angular/material/dialog';

import {
  RequestingCustomerDto,
  BillingCustomerDto,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';

import { ContactsTmpDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
})
export class ClientInformationComponent {
  requestingCustomer: RequestingCustomerDto = {
    companyName: 'Drone Deploy',
    projectManager: 'Kaitlin Blando',
    projectManagerEmail: 'kaitlin.bando@gmail.com',
    projectManagerPhone: '+1 888 888 88 88',
    subclientName: 'EVgo',
  };
  billingCustomer: BillingCustomerDto = {
    customerEmail: 'hello@dronedeploy.com',
    customerName: 'Drone Deploy',
    customerPhone: '+1 888 888 88 88',
    sameAsSubclient: true,
  };

  contacts: ContactsTmpDto[] = new Array();
  contact: ContactsTmpDto;
  updateContact: boolean = false;

  @ViewChild('contactModal') contactModal: TemplateRef<any>;
  @ViewChild('requestingModal') requestingModal: TemplateRef<any>;
  @ViewChild('billingModal') billingModal: TemplateRef<any>;

  contactColumns = ['contactFrom', 'name', 'email', 'phone', 'sharedData'];

  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    // Mocked data for contacts
    this.contacts = [
      {
        id: 1,
        contactFrom: 'Company',
        name: 'Andres Aldana',
        email: 'andres.aldana@newtonvisionco.com',
        phone: '1234567891',
        sharedData: false,
      },
    ];
  }

  openContactModal(contact?: ContactsTmpDto): void {
    if (contact) {
      this.contact = {
        id: contact.id,
        contactFrom: contact.contactFrom,
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        sharedData: contact.sharedData,
      };
      this.updateContact = true;
    } else {
      this.contact = {} as ContactsTmpDto;
    }

    this.dialog.open(this.contactModal, { width: '600px', minHeight: '600px' });
  }

  openRequestingModal(): void {
    this.dialog.open(this.requestingModal, { width: '600px', minHeight: '600px' });
  }

  openBillingModal(): void {
    this.dialog.open(this.billingModal, { width: '600px', minHeight: '600px' });
  }

  closeModal(): void {
    this.dialog.closeAll();
    this.contact = {} as ContactsTmpDto;
  }

  saveContact(): void {
    let message = 'Contact added!';
    if (this.updateContact) {
      this.contacts = this.contacts.filter(item => item.id !== this.contact.id);
      this.updateContact = false;
      message = 'Contact updated!';
    } else {
      this.contact.id = this.contacts.length + 1;
    }

    this.contacts = this.contacts.concat(this.contact);
    this.closeModal();
    this.cdr.detectChanges();
    this.toasterService.info(message);
  }

  delete(contact?: ContactsTmpDto): void {
    this.contacts = this.contacts.filter(item => item.name !== contact.name);
    this.toasterService.info('Contact deleted!');
  }

  saveRequestingCustomer(): void {
    this.closeModal();
    this.cdr.detectChanges();
    this.toasterService.info('Requesting customer updated!');
  }

  saveBillingCustomer(): void {
    this.closeModal();
    this.cdr.detectChanges();
    this.toasterService.info('Billing Requesting Customer updated!');
  }
}
