import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialSharedModule } from '../../material-shared/material-shared.module';

import { OrdersRoutingModule } from './orders-routing.module';

import { NewOrderFormComponent } from './new-order-form/new-order-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrdersListComponent } from './orders-list/orders-list.component';

import { OrdersMissionsComponent } from './orders-misssions/orders-missions.component';
import { OrdersProjectsComponent } from './projects/orders-projects.component';
import { OrdersPortfolioComponent } from './orders-portfolio/orders-portfolio.component';
import { PageModule } from '@abp/ng.components/page';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { CoreModule } from '@abp/ng.core';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { MissionsModule } from 'projects/missions-service/src/lib/basics/missions/missions.module';
import { ProjectsModule } from 'projects/missions-service/src/lib/basics/projects/projects.module';
import { PortafoliosModule } from 'projects/missions-service/src/lib/basics/portafolios/portafolios.module';
import { PilotSourcingModule } from '../../pilot-sourcing/pilot-sourcing.module';
import { OrderContactsTableComponent } from './order-contacts/order-contacts.component';
import { SharedModule } from '../../shared/shared.module';
import { OrderContactComponent } from './order-contact/order-contact.component';
import { OrderSiteComponent } from './new-order-form/order-site/order-site.component';
import { ProductComponent } from './new-order-form/product/product.component';
import { DeliverableComponent } from './new-order-form/deliverable/deliverable.component';
import { NewDeliverableComponent } from './new-order-form/new-deliverable/new-deliverable.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MissionOverviewComponent } from './mission-overview/mission-overview.component';

@NgModule({
  declarations: [
    OrdersListComponent,
    OrdersMissionsComponent,
    OrdersProjectsComponent,
    OrdersPortfolioComponent,
    NewOrderFormComponent,
    OrderContactsTableComponent,
    OrderContactComponent,
    OrderSiteComponent,
    ProductComponent,
    DeliverableComponent,
    NewDeliverableComponent,
    MissionOverviewComponent,
  ],
  imports: [
    CoreModule,
    CommercialUiModule,
    OrdersRoutingModule,
    CommonModule,
    MaterialSharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxValidateCoreModule,
    PageModule,
    MissionsModule,
    ProjectsModule,
    PortafoliosModule,
    PilotSourcingModule,
    SharedModule,
    DigitOnlyModule,
  ],
  exports: [
    OrdersListComponent,
    OrdersMissionsComponent,
    OrdersProjectsComponent,
    OrdersPortfolioComponent,
    OrderContactsTableComponent,
    OrderContactComponent,
  ],
})
export class OrdersModule {}
