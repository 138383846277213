import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetMissionPilotNotificationInput, MissionPilotNotificationsCreateDto, MissionPilotNotificationsDto, MissionPilotNotificationsUpdateDto } from '../../basics/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';
import { MissionPilotsExcelDownloadDto } from '../../relationals';

@Injectable({
  providedIn: 'root',
})
export class MissionPilotNotificationsService {
  apiName = 'missionsService';
  

  create = (input: MissionPilotNotificationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotNotificationsDto>({
      method: 'POST',
      url: '/api/missions-service/mission-pilot-notification',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/mission-pilot-notification/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotNotificationsDto>({
      method: 'GET',
      url: `/api/missions-service/mission-pilot-notification/${id}`,
    },
    { apiName: this.apiName,...config });
  
getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/mission-pilot-notification/download-token',
    },
    { apiName: this.apiName,...config });

getListAsExcelFile = (input: MissionPilotsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/mission-pilot-notification/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetMissionPilotNotificationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionPilotNotificationsDto>>({
      method: 'GET',
      url: '/api/missions-service/mission-pilot-notification',
      params: { filterText: input.filterText, pilotId: input.pilotId, missionId: input.missionId, notificationId: input.notificationId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });
  
  getMissionLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/missions-service/mission-pilot-notification/mission-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });

  update = (id: string, input: MissionPilotNotificationsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotNotificationsDto>({
      method: 'PUT',
      url: `/api/missions-service/mission-pilot-notification/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
