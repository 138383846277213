import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-fg-search-bar',
  templateUrl: './fg-search-bar.component.html',
  styleUrls: ['./fg-search-bar.component.scss'],
})
export class FgSearchBarComponent {
  @Output() search = new EventEmitter<string>();
  searchText: string;

  onSubmit(event: Event) {
    event.preventDefault();
    this.search.emit(this.searchText);
  }
}
