import type {
  ExtensibleAuditedEntityDto,
  ExtensibleObject,
  FullAuditedEntityDto,
  PagedAndSortedResultRequestDto,
} from '@abp/ng.core';
import type { FileIconInfo } from '../files/models';

export interface CreateDirectoryInput extends ExtensibleObject {
  parentId?: string;
  name: string;
  missionFilter?: FilterMission;
}

export interface DirectoryContentDto extends ExtensibleAuditedEntityDto<string> {
  id: string;
  name?: string;
  isDirectory: boolean;
  size: number;
  iconInfo: FileIconInfo;
  concurrencyStamp?: string;
  imagePreviewUrl?: string;
  videoPreviewUrl?: string;
  fileContentPreviewUrl?: string;
  thumbnailUrl?: string;
  exifData?: string;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
  isRoot?: boolean;
  parentId?: string;
}

export interface ExifData {
  Latitude?: number;
  longitude?: number;
  RelativeHeight?: number;
  GimbalAngle?: string;
}

export interface DirectoryContentRequestInput extends PagedAndSortedResultRequestDto {
  filter?: string;
  id?: string;
}

export interface DirectoryDescriptorDto extends ExtensibleAuditedEntityDto<string> {
  name?: string;
  parentId?: string;
  concurrencyStamp?: string;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
}

export interface DirectoryDescriptorInfoDto {
  id?: string;
  name?: string;
  parentId?: string;
  hasChildren: boolean;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
}

export interface MoveDirectoryInput {
  id?: string;
  newParentId?: string;
  concurrencyStamp?: string;
}

export interface RenameDirectoryInput {
  name: string;
  concurrencyStamp?: string;
}

export interface FilterMission {
  missionId: string;
  captureId: string;
  deliverableId: string;
  orderDetailsId?: string;
  rootFolder?: { id: string; name: string };
}

export class FolderPermissionResultModel {
  read: Array<string>;
  write: Array<string>;
  delete: Array<string>;
}

export interface FileSelected {
  isSelected: boolean;
  item: DirectoryContentDto;
}

export interface RootFolderDetailsDto {
  folderId?: string;
  size: number;
  itemsCount: number;
}

export interface SftpUserCredentialsDto {
  serverName: string;
  user: string;
  password: string;
}

export interface SftpFileDto {
  isFolder: boolean;
  name: string;
  path: string;
  size: number;
}

export interface SftpFileTransferDto {
  rootFolderId: string;
  missionId: string;
  included: string[];
  excluded: string[];
}

export interface SftpTransferStatusDto extends FullAuditedEntityDto {
  userId: string;
  missionId: string;
  isInProgress: boolean;
  isFinished: boolean;
  filesToTransfer: number;
  succeeded: number;
  failed: number;
  failedFiles: string;
}
