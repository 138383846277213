import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// We use this componet to redirect to elsa page, but we need to check this to improve this task 
@Component({template: ''})
export class RedirectGuardComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(
        ({externalUrl}) => {
            window.location.replace(externalUrl);
        });
  }
  
}
