import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ConfigurationTypesCreateDto, ConfigurationTypesDto, ConfigurationTypesExcelDownloadDto, ConfigurationTypesUpdateDto, GetConfigurationTypeInput } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { Cacheable, CacheBuster, LocalStorageStrategy } from 'ts-cacheable';
import { Subject } from 'rxjs';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class ConfigurationTypesService {
  apiName = 'CoreService';
  
  @CacheBuster({ cacheBusterNotifier: cacheBuster$, isInstant: true })
  create(input: ConfigurationTypesCreateDto, config?: Partial<Rest.Config>) {
    return this.restService.request<any, ConfigurationTypesDto>({
      method: 'POST',
      url: '/api/core-service/configuration-type',
      body: input,
    },
    { apiName: this.apiName,...config });
  }

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/configuration-type/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ConfigurationTypesDto>({
      method: 'GET',
      url: `/api/core-service/configuration-type/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/configuration-type/download-token',
    },
    { apiName: this.apiName,...config });
  

  @Cacheable({ cacheBusterObserver: cacheBuster$, storageStrategy: LocalStorageStrategy })
  getList(input: GetConfigurationTypeInput, config?: Partial<Rest.Config>) {
    return this.restService.request<any, PagedResultDto<ConfigurationTypesDto>>({
      method: 'GET',
      url: '/api/core-service/configuration-type',
      params: { filterText: input.filterText, description: input.description, stateMin: input.state, migrated: input.migrated, idOldMin: input.idOld, code: input.code, pk_CONFIGURATION_TYPE_ID: input.pk_CONFIGURATION_TYPE_ID, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  }


  getListAsExcelFile = (input: ConfigurationTypesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/configuration-type/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  @CacheBuster({ cacheBusterNotifier: cacheBuster$, isInstant: true })
  update(id: string, input: ConfigurationTypesUpdateDto, config?: Partial<Rest.Config>) {
    return this.restService.request<any, ConfigurationTypesDto>({
      method: 'PUT',
      url: `/api/core-service/configuration-type/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });
  }

  constructor(private restService: RestService) {}
}
