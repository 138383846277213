import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageSucessComponent } from './components/common/message/message.success.component';

export class Helpers {
  public static openDialog(
    dialog: MatDialog,
    msgtitle: string,
    msg: String,
    route: Router,
    routerurl: string
  ): void {
    const dialogRef = dialog.open(MessageSucessComponent, {
      data: { title: msgtitle, message: msg },
      disableClose: true,
      panelClass: 'modal-base',
      width: '475px',
      height: '428px',
      maxWidth: '475px',
    });

    dialogRef.afterClosed().subscribe(result => {
      route.navigate([routerurl]);
    });
  }

  public static getLocalTime(captureTime: string, captureDate: string) {
    let dateParts= captureDate.split("/").reverse();
    if(dateParts.length === 3){
      const aux = dateParts[1];
      dateParts[1] = dateParts[2];
      dateParts[2] = aux;
      captureDate = dateParts.join("-");
    }

    const timeRegex = /(\d+):(\d+)/;
    let time = null;
    const match = String(captureTime).match(timeRegex);

    if (match) {
      time = match[0] + ':00';
    }
    if (time) {
      const captureDateTime = new Date(`${captureDate.split("T")[0]}T${time}`);
      return captureDateTime;
    } else {
      return null;
    }
  }

}
