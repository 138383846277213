import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { ColDef } from 'ag-grid-community';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { PrioritiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/priorities.service';
import { CustomersDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics/models';
import {
  GetMissionInput,
  LocationsDto,
  MissionsDto,
  PilotSourcingMissionsDto,
  PortafoliosDto,
  ProjectsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  LocationsService,
  MissionsService,
  PortafoliosService,
  ProjectsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

import { PrioritiesDto, StatesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { StatesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'orders-missions',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './orders-missions.component.html',
})
export class OrdersMissionsComponent {
  constructor() {}
}
