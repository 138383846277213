import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

enum CellActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss'],
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
  private params: any;

  CellActions = CellActions;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  handleAction(action: CellActions) {
    switch (action) {
      case CellActions.DELETE: {
        this.params.delete(this.params.data.id);
        break;
      }
      case CellActions.EDIT: {
        this.params.edit(this.params.rowIndex);
        break;
      }
      default:
        break;
    }
  }
}
