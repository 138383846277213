import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

export class FormHelpers {
  public static ValidationStatus = {
    VALID: 'VALID',
    INVALID: 'INVALID',
  } as const;

  public static buildValidators(validated) {
    var keyNames = Object.keys(validated);
    var form = new FormBuilder().group({});
    for (var i in keyNames) {
      form.addControl(
        keyNames[i],
        new FormControl('', {
          validators: Validators.compose([Validators.required]),
          asyncValidators: [],
        })
      );
    }
    return form;
  }

  public static buildValidatorsOrderContact() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'contactFromDescription',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'email',
      new FormControl('', [Validators.required, Validators.email])
    );
    form.addControl('name', new FormControl(''));
    form.addControl('lastname', new FormControl(''));
    form.addControl('number', new FormControl(''));
    form.addControl('shareData', new FormControl(''));
    form.addControl('contactFromId', new FormControl(''));
    return form;
  }

  public static buildValidatorsOrderStep1() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'customerDescription',
      new FormControl('', [Validators.required])
    );
    form.addControl(
      'customerContactDescription',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'customerContactEmail',
      new FormControl('', [Validators.required, Validators.email])
    );

    form.addControl(
      'customerContactNumber',
      new FormControl('')
    );

    form.addControl('subclientName', new FormControl(''));

    form.addControl(
      'billingCustomerContactEmail',
      new FormControl('', [Validators.required, Validators.email])
    );

    form.addControl(
      'billingCustomerName',
      new FormControl('', [Validators.required])
    );
    form.addControl(
      'billingCustomerConctactNumber',
      new FormControl('')
    );
    return form;
  }

  public static buildValidatorSite() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'missionName',
      new FormControl('', [Validators.required])
    );
    form.addControl(
      'missionSummary',
      new FormControl('', [Validators.required])
    );
    form.addControl(
      'captureDate',
      new FormControl('', [Validators.required])
    );

    form.addControl('captureTime', new FormControl(''));

    form.addControl('frequencyId', new FormControl(''));

    form.addControl(
      'captureDateMustId',
      new FormControl('')
    );
    form.addControl(
      'flexibleCaptureId',
      new FormControl('')
    );

    form.addControl(
      'deliveryAllSameTime',
      new FormControl('')
    );

    form.addControl('deliveryDate', new FormControl(''));

    form.addControl(
      'deliveryNotes',
      new FormControl('')
    );

    form.addControl(
      'uploadingDataInstruction',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'siteContactName',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'siteContactLastName',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'siteContactEmail',
      new FormControl('', [Validators.required, Validators.email])
    );

    form.addControl(
      'siteContactPhone',
      new FormControl('')
    );

    form.addControl(
      'preferredContactEmail',
      new FormControl('')
    );

    form.addControl(
      'preferredContactPhone',
      new FormControl('')
    );

    form.addControl(
      'levelCoordinationNotify',
      new FormControl('')
    );

    form.addControl(
      'levelCoordinationMeet',
      new FormControl('')
    );

    form.addControl(
      'levelCoordinationCoordinate',
      new FormControl('')
    );

    form.addControl(
      'locAddress',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'locCountryId',
      new FormControl('', [Validators.required])
    );

    form.addControl('locCity', new FormControl('', [Validators.required]));

    form.addControl(
      'locStateId',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'locZipCode',
      new FormControl('', [Validators.required])
    );

    form.addControl('loclatitude', new FormControl(''));
    form.addControl('loclongitude', new FormControl(''));

    form.addControl(
      'locAirSpaceClasfId',
      new FormControl('', [Validators.required])
    );

    form.addControl('aditionalNotes', new FormControl(''));
    return form;
  }

  public static buildValidatorsOrderStep2() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'priorityId',
      new FormControl('', [Validators.required])
    );

    form.addControl(
      'industryid',
      new FormControl('', [Validators.required])
    );
    return form;
  }

  public static buildValidatorsProduct() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl('productId', new FormControl(''));
    return form;
  }
  public static buildValidatorsDeliverables(newDeliverable: Boolean = false) {
    var form: FormGroup = new FormBuilder().group({});
    if (newDeliverable) {
      form.addControl(
        'deliverableId',
        new FormControl('', [Validators.required])
      );
      form.addControl(
        'altitude',
        new FormControl('', [Validators.required])
      );
    } else {
      form.addControl('altitude', new FormControl(''));
    }
    form.addControl('deliverableName', new FormControl(''));
    form.addControl('grimbalangle', new FormControl(''));
    form.addControl('overlap', new FormControl(''));
    return form;
  }
}
