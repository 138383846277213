import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule()
export class CustomersServiceConfigModule {
  static forRoot(): ModuleWithProviders<CustomersServiceConfigModule> {
    return {
      ngModule: CustomersServiceConfigModule,
      providers: [ ],
    };
  }
}
