import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { LoadingComponent } from '../../components/common/loading/loading.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingOverlayService {
  private overlayRef: OverlayRef = null;

  constructor(private overlay: Overlay) {}

  /**
   * Shows the loading overlay with the passed message
   *
   * @param message string
   * @returns void
   */
  showOverlay(message: string | null = null): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    const loadingPortal = new ComponentPortal(LoadingComponent);

    const component = this.overlayRef.attach(loadingPortal);
    component.instance.message = message ?? component.instance.message;
  }

  /**
   * Hides the loading overlay
   *
   * @returns void
   */
  hideOverlay(): void {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
