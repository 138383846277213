<div class="main-container d-flex flex-column align-content-between" *ngIf="dataLoaded">
  <div class="header d-flex flex-column gap-2">
    <div class="w-100 d-flex">
      <div class="flex-grow-1">
        <ng-content select="[header]"></ng-content>
      </div>

      <div *ngIf="!DisplayInList">
        <mat-progress-spinner
          diameter="70"
          [value]="
            ((questionnaireLogicManager.selectedQuestionIndex + 1) /
              questionnaireLogicManager.PrimaryQuestionsCount) *
            100
          "
          aria-label="Rating"
          mode="determinate"
        >
        </mat-progress-spinner>
        <div style="position: relative; top: -40px; left: 0px">
          <p style="font-size: 18px" class="spinner-text text-center">
            {{ questionnaireLogicManager.selectedQuestionIndex + 1 }}/{{
              questionnaireLogicManager.PrimaryQuestionsCount
            }}
          </p>
        </div>
      </div>

      <div
        class="header d-flex justify-content-end gap-2"
        *ngIf="questionnaire.allowEdit && DisplayInList"
      >
        <button
          mat-flat-button
          type="button"
          class="common-button"
          *ngIf="!editMode"
          (click)="enableEditMode()"
        >
          Edit
        </button>
        <button
          *ngIf="questionnaireLogicManager.alreadyAnswered && editMode"
          mat-flat-button
          type="button"
          class="outline-button"
          (click)="disableEditMode()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          type="button"
          class="common-button"
          *ngIf="editMode"
          (click)="submit()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="w-100" *ngIf="questionnaireLogicManager.selectedQuestion.isOptional">
      <ng-content select="[details]"></ng-content>
    </div>
  </div>

  <div class="content">
    <div
      *ngIf="!DisplayInList"
      class="w-100 d-flex justify-content-center align-items-center flex-column question-container"
    >
      <lib-question-details
        class="w-100"
        [QuestionnaireLogic]="questionnaireLogicManager"
        [question]="questionnaireLogicManager.selectedQuestion"
      >
      </lib-question-details>
      <div class="w-100 nav-button-container d-flex justify-content-start flex-grow-1 gap-2">
        <button
          *ngIf="questionnaireLogicManager.selectedQuestionIndex != 0"
          mat-flat-button
          (click)="previous()"
          class="outline-button"
          style="height: 34px"
        >
          Previous
        </button>
        <button
          *ngIf="
            questionnaireLogicManager.selectedQuestionIndex + 1 !=
            questionnaireLogicManager.PrimaryQuestionsCount
          "
          mat-flat-button
          (click)="next()"
          class="common-button"
          style="height: 34px"
          [class.question-ready]="questionnaireLogicManager.isQuestionReady()"
        >
          Continue
        </button>
        <button
          *ngIf="
            questionnaireLogicManager.selectedQuestionIndex + 1 ==
            questionnaireLogicManager.PrimaryQuestionsCount
          "
          mat-flat-button
          (click)="submit()"
          class="outline-button"
          style="height: 34px"
        >
          Submit
        </button>
      </div>
    </div>
    <div
      *ngIf="DisplayInList"
      class="w-100 d-flex justify-content-center align-items-center flex-column gap-3"
    >
      <ng-container *ngFor="let question of questionnaireLogicManager.primaryQuestions">
        <div class="w-100" [ngClass]="{ 'question-container': editMode }">
          <lib-question-details
            class="w-100"
            [QuestionnaireLogic]="questionnaireLogicManager"
            [question]="question"
            [DisplayInList]="DisplayInList"
            [EditMode]="editMode"
          >
          </lib-question-details>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="footer d-flex justify-content-between mt-2"></div>
</div>
