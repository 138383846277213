import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetQuestionV2Input, QuestionV2Dto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class QuestionV2Service {
  apiName = 'CoreService';

  getList = (input: GetQuestionV2Input, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<QuestionV2Dto>>({
      method: 'GET',
      url: '/api/core-service/question-v2',
      params: { filterText: input.filterText, questionnaireId: input.questionnaireId },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
