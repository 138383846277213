import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePaginatorComponent } from './components/table-paginator/table-paginator.component';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@abp/ng.core';


@NgModule({
  declarations: [
    TablePaginatorComponent
  ],
  imports: [
    CommonModule,
    ThemeSharedModule,
    FormsModule,
    CoreModule
  ],
  exports: [TablePaginatorComponent]
})
export class TablePageModule { }
