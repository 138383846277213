import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetReportColumnInput, ReportColumnsCreateDto, ReportColumnsDto, ReportColumnsExcelDownloadDto, ReportColumnsUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ReportColumnsService {
  apiName = 'pilotsService';
  

  create = (input: ReportColumnsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportColumnsDto>({
      method: 'POST',
      url: '/api/pilots-service/report-column',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/report-column/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportColumnsDto>({
      method: 'GET',
      url: `/api/pilots-service/report-column/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/report-column/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetReportColumnInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ReportColumnsDto>>({
      method: 'GET',
      url: '/api/pilots-service/report-column',
      params: { filterText: input.filterText, reportColumnTypeId: input.reportColumnTypeId, reportId: input.reportId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: ReportColumnsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/report-column/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

  getReportLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/pilots-service/report-column/report-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: ReportColumnsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportColumnsDto>({
      method: 'PUT',
      url: `/api/pilots-service/report-column/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
