import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { switchMap } from 'rxjs';
import { CaptureWithDeliverablesDto } from '../../models/capture-with-deliverables-dto';

@Component({
  selector: 'app-captures',
  templateUrl: './captures.component.html',
  styleUrls: ['./captures.component.scss'],
})
export class CapturesComponent {
  captures: CaptureWithDeliverablesDto[] = [];
  missionId: string = '';

  captureColumns = [
    { name: 'Capture #', prop: 'captureNumber' },
    { name: 'Capture Date', prop: 'captureDate' },
    { name: 'Capture Time', prop: 'captureTime' },
    { name: 'Capture Must be', prop: 'captureMustBe' },
    { name: 'Pilots', prop: 'pilots' },
    { name: 'Flexible Capture Time', prop: 'flexibleCaptureTime' },
    { name: 'Deliverables Included', prop: 'deliverablesIncluded' },
    { name: 'Actions' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private missionFlowService: MissionFlowService
  ) {}

  ngOnInit() {
    this.route.parent?.paramMap
      .pipe(
        switchMap(params => {
          this.missionId = params.get('missionId');
          return this.missionFlowService.getCapturesWithDeliverables(this.missionId);
        })
      )
      .subscribe({
        next: response => (this.captures = response),
        error: err => console.log(err),
      });
  }

  navigateToSourcing(captureId: string) {
    if (this.missionId && captureId) {
      try {
        const path = 'pilot-sourcing';
        this.router.navigate(['pilot-sourcing/missions', this.missionId, path], {
          queryParams: { captureId: captureId },
        });
      } catch (error) {
        console.log('Navigation error:', error);
      }
    } else {
      console.log('missionId or captureId not available for navigation');
    }
  }
}
