import { Component, Input, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { ToasterService } from '@abp/ng.theme.shared';

import { AgGridAngular } from 'ag-grid-angular';

import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from 'ag-grid-community';

import { ButtonCellRendererComponent } from '../table/renderers/button-cell-renderer/button-cell-renderer.component';
import { ActionsCellRendererComponent } from '../table/renderers/actions-cell-renderer/actions-cell-renderer.component';

import { SampleDialogComponent } from '../dialog/sample-dialog/sample-dialog.component';
import { CustomizerSettingsService } from '../tagus/customizer-settings/customizer-settings.service';

interface rowData {
  make: string;
  model: string;
  price: number;
  info: string;
}

const lightTheme = 'ag-theme-alpine';
const darkTheme = 'ag-theme-alpine-dark';

// Example from https://www.ag-grid.com/angular-data-grid/getting-started/
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {
  themeClass: string;
  @Input() columns: ColDef[];
  @Input() data: any[];
  public columnVisibility: { [key: string]: boolean } = {};
  public originalcolumnDefs: ColDef[] = [];

  public columnDefs: ColDef[] = [
    { field: 'info' },
    { field: 'action' },
    { field: 'make' },
    { field: 'model' },
    { field: 'price' },
  ];
  public rowData = [
    {
      id: 'A1',
      make: 'Toyota',
      model: 'Celica',
      price: 35000,
      info: 'The Toyota Celica (/ˈsɛlɪkə/ or /sɛˈliːkə/) (Japanese: トヨタ・セリカ, Hepburn: Toyota Serika) is an automobile produced by Toyota from 1970 until 2006. The Celica name derives from the Latin word coelica meaning heavenly or celestial. In Japan, the Celica was exclusive to the Toyota Corolla Store dealer chain. Produced across seven generations, the Celica was powered by various four-cylinder engines, and bodystyles included convertibles, liftbacks, coupés and notchback coupés.',
    },
    {
      id: 'A2',
      make: 'Ford',
      model: 'Mondeo',
      price: 32000,
      info: 'The Ford Mondeo I (first generation) is a mid-size car that was produced by Ford, beginning on 23 November 1992, with sales beginning on 22 March 1993. It is also known as the Mk I Mondeo; the 1996 facelift versions are usually designated Mk II. Available as a four-door saloon, a five-door hatchback, and a five-door estate, all models for the European market were produced at Fords plant in the Belgian city of Genk. In December 1992, Autocar published a section on the Mondeo, and how it would conquer rivals.',
    },
    {
      id: 'A3',
      make: 'Porsche',
      model: 'Boxster',
      price: 72000,
      info: 'The Porsche Boxster and Cayman are mid-engine two-seater sports cars manufactured and marketed by German automobile manufacturer Porsche across four generations—as a two-door, two-seater roadster (Boxster) and a three-door, two-seater fastback coupé (Cayman).',
    },
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    initialWidth: 150,
    filter: true,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    resizable: true,
  };

  constructor(
    private dialog: MatDialog,
    private toaster: ToasterService,
    private themeService: CustomizerSettingsService
  ) {
    this.themeService.isDarkTheme$.subscribe((isDarkTheme: boolean) => {
      this.themeClass = isDarkTheme ? darkTheme : lightTheme;
    });
  }

  ngOnInit() {
    if (this.columns?.length > 0) {
      this.columnDefs = [];
      for (const column of this.columns) {
        this.columnDefs.push(column);
      }
    }

    for (const columnDef of this.columnDefs) {
      this.columnVisibility[columnDef.field] = !columnDef.hide;
      this.originalcolumnDefs.push(columnDef);
    }

    this.showColumnOptions = false;
  }

  public selectedRows = [];
  public totalCost = 0;

  public sidebarFloating = false;
  public showColumnOptions = true;

  private gridApi!: GridApi;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  getRowId(params: any) {
    return params.data.id;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  updateColumnVisibility(field: string, visible: boolean) {
    this.columnVisibility[field] = visible;
    // Update the column definitions based on visibility configuration
    this.columnDefs = [];

    this.originalcolumnDefs.forEach(column => {
      if (this.columnVisibility[column.field]) {
        this.columnDefs.push(column);
      }
    });
  }

  onColumnMoved(event: any) {
    const { column } = event;
    this.columnVisibility[column.colId] = false;
  }

  onSelectionChanged(e: CellClickedEvent): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = selectedRows;
    this.totalCost = this.selectedRows.reduce((acc, row) => acc + parseInt(row.price, 10), 0);
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  openDialog(data: rowData): void {
    this.dialog.open(SampleDialogComponent, { data });
  }

  editCellFor(rowIndex: number) {
    this.gridApi.setFocusedCell(0, 'price');
    this.gridApi.startEditingCell({ rowIndex, colKey: 'price' });
  }

  removeRowFor(rowId: string) {
    const rowToRemove = this.gridApi.getRowNode(rowId);
    this.gridApi.applyTransaction({ remove: [rowToRemove] });
    this.toaster.info('Cell Removed');
  }

  onEditingStopped(e: CellEditingStoppedEvent) {
    this.onSelectionChanged(e);
  }

  toggleColumnOptions() {
    this.showColumnOptions = !this.showColumnOptions;
  }

  updateGridData(data: any[]): void {
    this.rowData = data;
  }
}
