export interface ChunkModel {
    start: number;
    end: number;
    chunkNumber: number;
    identifier: string;
    webAppChunkId: string;
    status: ChunkModelStatus;
    startTime?: number;
    endTime?: number;
}

export enum ChunkModelStatus{
    Waiting,
    Uploading,
    Canceled,
    Failed,
    Succeeded
}
