import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { ePilotsServiceRouteNames } from '../enums/route-names';

export const PILOTS_PROFILE_ROUTE_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

export function configureRoutes(routesService: RoutesService) {
  return () => {
    routesService.add([
      {
        path: '/pilot-profile',
        name: ePilotsServiceRouteNames.PilotsProfile,
        iconClass: 'account_circle',
        layout: eLayoutType.application,
        requiredPolicy: 'pilotsService.Pilots',
        order: 5,
      },
    ]);
  };
}
