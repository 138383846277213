import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetReportParameterInput, ReportParametersCreateDto, ReportParametersDto, ReportParametersExcelDownloadDto, ReportParametersUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ReportParametersService {
  apiName = 'pilotsService';
  

  create = (input: ReportParametersCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportParametersDto>({
      method: 'POST',
      url: '/api/pilots-service/report-parameter',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/report-parameter/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportParametersDto>({
      method: 'GET',
      url: `/api/pilots-service/report-parameter/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/report-parameter/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetReportParameterInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ReportParametersDto>>({
      method: 'GET',
      url: '/api/pilots-service/report-parameter',
      params: { filterText: input.filterText, reportParameterTypeId: input.reportParameterTypeId, reportId: input.reportId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: ReportParametersExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/report-parameter/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

  getReportLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/pilots-service/report-parameter/report-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });

  update = (id: string, input: ReportParametersUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportParametersDto>({
      method: 'PUT',
      url: `/api/pilots-service/report-parameter/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
