import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetHubNotificationContentInput, HubNotificationContentsCreateDto, HubNotificationContentsDto, HubNotificationContentsExcelDownloadDto, HubNotificationContentsUpdateDto, HubNotificationCreateByTagDto, HubNotificationCreateByUserDto, HubNotificationDevicesDto } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class HubNotificationContentsService {
  apiName = 'notificationsService';
  

  create = (input: HubNotificationContentsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, HubNotificationContentsDto>({
      method: 'POST',
      url: '/api/notifications-service/hub-notification-content',
      body: input,
    },
    { apiName: this.apiName,...config });
  
  createByTag = (input: HubNotificationCreateByTagDto) =>
    this.restService.request<any, HubNotificationCreateByTagDto>({
      method: 'POST',
      url: '/api/notification-service/hub/tags/notification',
      body: input,
    },
    { apiName: this.apiName });

  createByUser = (input: HubNotificationCreateByUserDto) =>
    this.restService.request<any, HubNotificationCreateByUserDto>({
      method: 'POST',
      url: `/api/notification-service/hub/user/${input.user}/notification`,
      body: input,
    },
    { apiName: this.apiName });

  getDevices = (user: string) =>
    this.restService.request<any, Array<HubNotificationDevicesDto>>({
      method: 'GET',
      url: `/api/notification-service/hub/${user}/devices`,
    },
    { apiName: this.apiName });

  getDevicesByTag = (tag: string) =>
    this.restService.request<any, Array<HubNotificationDevicesDto>>({
      method: 'GET',
      url: `/api/notification-service/hub/tag/${tag}/devices`,
    },
    { apiName: this.apiName });

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/notifications-service/hub-notification-content/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, HubNotificationContentsDto>({
      method: 'GET',
      url: `/api/notifications-service/hub-notification-content/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/notification-service/hub/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetHubNotificationContentInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<HubNotificationContentsDto>>({
      method: 'GET',
      url: '/api/notification-service/hub',
      params: { 
        filterText: input.filterText, 
        notificationId: input.notificationId, 
        deviceId: input.deviceId, 
        userId: input.userId, 
        title: input.title,
        message: input.message, 
        tags: input.tags, 
        silent: input.silent, 
        isGlobalMessage: input.isGlobalMessage,
        pushNotificationsStateId: input.pushNotificationsStateId, 
        status: input.status, 
        sorting: input.sorting,
        skipCount: input.skipCount, 
        maxResultCount: input.maxResultCount
      },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: HubNotificationContentsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/notification-service/hub/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: HubNotificationContentsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, HubNotificationContentsDto>({
      method: 'PUT',
      url: `/api/notifications-service/hub-notification-content/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
