import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-overall-rating',
  templateUrl: './overall-rating.component.html',
  styleUrls: ['./overall-rating.component.scss']
})
export class OverallRatingComponent {
  @Input() pilotId: string;
}
