<abp-page [title]="'missionsService::Expenses' | abpLocalization">
  <abp-page-toolbar-container class="col">
    <div class="text-lg-end pt-2">
      <button
        class="btn btn-primary btn-sm mx-2"
        type="button"
        [disabled]="isExportToExcelBusy"
        (click)="exportToExcel()"
      >
        {{ 'missionsService::ExportToExcel' | abpLocalization }}
      </button>
      <!-- 
      <button
        *abpPermission="'missionsService.MissionFinancialExpenses.Create'"
        class="btn btn-primary btn-sm"
        type="button"
        (click)="create()"
      >
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'missionsService::NewMissionFinancialExpense' | abpLocalization }}
      </button> -->

      <button class="btn btn-primary btn-sm" type="button" *ngIf="action">
        <lib-action-form
          [row]="{ title: 'New Expense', defDescription: '' }"
          [gridAction]="action"
          [isMobile]="false"
          [config]="{ modalWidth: '900px', showCloseOnModal: true }"
        >
          <span style="color: white !important">
            <i class="fa fa-plus me-1" aria-hidden="true"></i>
            {{ 'missionsService::NewMissionFinancialExpense' | abpLocalization }}
          </span>
        </lib-action-form>
      </button>
    </div>
  </abp-page-toolbar-container>

  <div class="card">
    <div class="card-body">
      <ngx-datatable
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        default
        [externalPaging]="true"
        [offset]="pageNumber"
        [limit]="clientMaxResultCount"
        #dataTable
      >
        <ngx-datatable-column
          name="{{ 'missionsService::Expense Type' | abpLocalization }}"
          prop="expenseTypeId"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ expenseTypes[row.expenseTypeId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Description' | abpLocalization }}"
          prop="description"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.description }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::Units' | abpLocalization }}" prop="units">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.units }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::UOMId' | abpLocalization }}" prop="uomId">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.uomId }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::ItemCost' | abpLocalization }}"
          prop="itemCost"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.itemCost }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::TotalItemCost' | abpLocalization }}"
          prop="totalItemCost"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.totalItemCost }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::ExpenseDate' | abpLocalization }}"
          prop="expenseDate"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.expenseDate | date }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <div ngbDropdown container="body" class="d-inline-block">
              <!-- <button
                class="btn btn-primary btn-sm dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                ngbDropdownToggle
              >
                <i class="fa fa-cog me-1" aria-hidden="true"></i>
                {{ 'AbpUi::Actions' | abpLocalization }}
              </button>

              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  *abpPermission="'missionsService.MissionFinancialExpenses.Edit'"
                  (click)="update(row)"
                >
                  {{ 'AbpUi::Edit' | abpLocalization }}
                </button>

                <button
                  ngbDropdownItem
                  *abpPermission="'missionsService.MissionFinancialExpenses.Delete'"
                  (click)="delete(row)"
                >
                  {{ 'AbpUi::Delete' | abpLocalization }}
                </button>
              </div> -->

              <lib-action-form
                *ngIf="action"
                [row]="{
                  id: row.id,
                  title: 'Edit Expense',
                  defExpenseTypeId: row.expenseTypeId ?? '',
                  defDescription: row.description ?? '',
                  defUnits: row.units ?? '',
                  defUomId: row.uomId,
                  defItemCost: row.itemCost,
                  defTotalItemCost: row.totalItemCost,
                  defExpenseDate: row.expenseDate
                }"
                [gridAction]="action"
                [isMobile]="false"
                [config]="{ modalWidth: '900px', showCloseOnModal: true }"
              >
                <i class="fa fa-pen me-1" aria-hidden="true"></i>
              </lib-action-form>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
              style="width: 100%"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{
        (selected ? 'AbpUi::Edit' : 'missionsService::NewMissionFinancialExpense') | abpLocalization
      }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form
      [formGroup]="form"
      id="mission-financial-expense"
      (ngSubmit)="submitForm()"
      validateOnSubmit
    >
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-expense-type-id">
            {{ 'missionsService::ExpenseTypeId' | abpLocalization }}
          </label>

          <input
            id="mission-financial-expense-expense-type-id"
            formControlName="expenseTypeId"
            class="form-control"
            autofocus
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-description">
            {{ 'missionsService::Description' | abpLocalization }} *
          </label>

          <input
            id="mission-financial-expense-description"
            formControlName="description"
            class="form-control"
            maxlength="100"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-units">
            {{ 'missionsService::Units' | abpLocalization }}
          </label>

          <input
            type="number"
            id="mission-financial-expense-units"
            formControlName="units"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-uomid">
            {{ 'missionsService::UOMId' | abpLocalization }}
          </label>

          <input
            id="mission-financial-expense-uomid"
            formControlName="uomId"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-item-cost">
            {{ 'missionsService::ItemCost' | abpLocalization }}
          </label>

          <input
            type="number"
            id="mission-financial-expense-item-cost"
            formControlName="itemCost"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-total-item-cost">
            {{ 'missionsService::TotalItemCost' | abpLocalization }}
          </label>

          <input
            type="number"
            id="mission-financial-expense-total-item-cost"
            formControlName="totalItemCost"
            class="form-control"
            [readonly]="selected"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-expense-expense-date">
            {{ 'missionsService::ExpenseDate' | abpLocalization }}
          </label>

          <div class="input-group" validationTarget>
            <input
              readonly
              id="mission-financial-expense-expense-date"
              formControlName="expenseDate"
              class="form-control"
              ngbDatepicker
              #expenseDateInput
              #expenseDateDatepicker="ngbDatepicker"
              (click)="expenseDateDatepicker.open()"
              (keyup.space)="expenseDateDatepicker.open()"
            />
            <div *ngIf="expenseDateInput.value" class="input-group-append">
              <button
                class="btn btn-link px-2"
                type="button"
                [title]="'AbpUi::Clear' | abpLocalization"
                (click)="
                  expenseDateDatepicker.writeValue(null);
                  expenseDateDatepicker.manualDateChange(null, true)
                "
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="mission-financial-expense">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
<app-responsive-grid
  class="d-none"
  gridName="Expenses"
  (action)="setAction($event)"
></app-responsive-grid>
