<abp-page>
    <h1>
        Orders
    </h1>
    <abp-page-toolbar-container class="col">
        <div class="text-lg-end pt-2">
            <a [routerLink]="['/orders/new']" *abpPermission="'missionsService.Mission.Create'">
                <button class="btn btn-primary btn-sm" type="button">
                    <i class="fa fa-plus me-1" aria-hidden="true"></i>
                    New Order
                </button>
            </a>
        </div>
    </abp-page-toolbar-container>
    <mat-card class="mb-25 tagus-card" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
        <mat-card-content>
            <mat-tab-group>
                <mat-tab label="Missions"><br> <orders-missions></orders-missions></mat-tab>
                <mat-tab label="Projects"><br> <orders-projects></orders-projects></mat-tab>
                <mat-tab label="Portfolios"><br><orders-portfolio></orders-portfolio></mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>


</abp-page>