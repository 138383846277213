export enum Steps {
  Equipment = 0,
  ServiceAreas = 1,
  Questionnaire = 2,
  Certification = 3,
  DataValidation = 4,
}

export enum FileTypes {
  PDF = 'pdf',
  JPG = 'jpg',
  PNG = 'png',
  DOC = 'doc',
  DOCX = 'docx',
  RTF = 'rtf',
  TXT = 'txt',
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
  PPT = 'ppt',
  PPTX = 'pptx',
  JPEG = 'jpeg',
  MP4 = 'mp4',
  AVI = 'avi',
  MOV = 'mov',
  WMV = 'wmv',
}

export enum DataValidationValidators {
  FirstNameMaxLength = 100,
  LastNameMaxLength = 100,
  UsernameMaxLength = 256,
  AddressMaxLength = 500,
  PhoneMaxLength = 50,
  EmailMaxLength = 100,
  GenderMaxLength = 200,
  EthnicityMaxLength = 200,
  DescriptionUser = 200,
  FAAMaxLength = 7
}
