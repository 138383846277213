import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  CapturesDto,
  type GetMissionInput,
  type MissionPilotCaptureDetailDto,
  type MissionsCreateDto,
  type MissionsDto,
  type MissionsExcelDownloadDto,
  type MissionsUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { GetMissionPilotCaptureDetailInput } from 'projects/flyguys-pilot/src/app/models/missions/mission-pilot-apture-detail-request';
import { PilotCaptureDeliverables } from 'projects/flyguys-pilot/src/app/models/products-deliverables/capture-deliverables.model';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';

@Injectable({
  providedIn: 'root',
})
export class MissionsService {
  apiName = 'missionsService';

  create = (input: MissionsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/mission/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetMissionInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/mission',
        params: {
          filterText: input.filterText,
          name: input.name,
          customerId: input.customerId,
          startDataMin: input.startDataMin,
          startDataMax: input.startDataMax,
          finishDataMin: input.finishDataMin,
          finishDataMax: input.finishDataMax,
          industryId: input.industryId,
          projectId: input.projectId,
          state: input.state,
          locationId: input.locationId,
          additionalCharges: input.additionalCharges,
          orderId: input.orderId,
          thirdPartyId: input.thirdPartyId,
          priorityId: input.priorityId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: MissionsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/mission/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: MissionsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getMissionCapturePilotDetail = (
    input: GetMissionPilotCaptureDetailInput,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, MissionPilotCaptureDetailDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-pilot-portal/mission-capture-pilot-detail`,
        params: {
          pilotId: (input.pilotId = input.pilotId),
          missionId: input.missionId,
          captureId: input.captureId,
        },
      },
      { apiName: this.apiName, ...config }
    );
    
  getCurrentMcUserByMission = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/${id}/mission-coordinator`
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
