import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NoInternetComponent } from './no-internet/no-internet.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    InternalServerErrorComponent,
    ForbiddenComponent,
    NoInternetComponent,
  ],
  imports: [CommonModule],
})
export class CustomErrorPagesModule {}
