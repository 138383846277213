import { Injectable } from '@angular/core';
import { EnvironmentService } from '@abp/ng.core';

@Injectable({
    providedIn: 'root'
})
export class ClarityService {

    constructor(private environment: EnvironmentService) {
        
    }

    load() {
        const environment = this.environment.getEnvironment();
        if (environment?.clarity?.apiKey) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = this.clarityScript(environment?.clarity?.apiKey);
            document.getElementsByTagName('head')[0].appendChild(s);
        }

    }

    clarityScript(projectId: string): string {
        return `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${projectId}");`;
    }

}
