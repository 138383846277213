<mat-card class="card">
  <div class="card-body">
    <h3 class="fs-16 bold">{{ 'pilotsService::MissionHistory' | abpLocalization }}</h3>
    <ngx-datatable
      class="flg-grid-table flg-grid-table--borderless"
      [headerHeight]="45"
      [footerHeight]="55"
      [rowHeight]="45"
      [rows]="ordersHistory.items"
      [count]="ordersHistory.totalCount"
      [list]="list"
      default
    >
      <ngx-datatable-column name="{{ 'pilotsService::Type' | abpLocalization }}" prop="type">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.type }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'pilotsService::OrderName' | abpLocalization }}" prop="name">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::Customer' | abpLocalization }}"
        prop="customer"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.customer }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::Location' | abpLocalization }}"
        prop="location"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.location }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::NMissions' | abpLocalization }}"
        prop="numberMissions"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.numberMissions }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::LastCaptureDate' | abpLocalization }}"
        prop="lastCaptureDate"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.lastCaptureDate }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::CumulativeRating' | abpLocalization }}"
        prop="cumulativeRating"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <app-column-stylizer
            [columnValue]="row.cumulativeRating"
            [typeColumn]="'rating'"
            [columnRules]=""
          ></app-column-stylizer>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</mat-card>
