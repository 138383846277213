import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  alerts = {
    PilotAgreementAlert: false,
    WizardAlert: false,
  };

  eventEmitter = new EventEmitter<BannerEvent>();

  emitEvent(bannerEvent: BannerEvent) {
    this.eventEmitter.emit(bannerEvent);
  }

  //status = true: shows the alert | status = false: hides the alert 
  updateAlertStatus(alert: AlertTypes, status : boolean) {
    this.alerts[AlertTypes[alert]] = status;
  }
}

export interface BannerEvent {
  type: BannerEventType;
  data: any;
}

export enum BannerEventType {
  PilotAgreement,
  WizardComplete,
}

export enum AlertTypes{
    PilotAgreementAlert,
    WizardAlert
}
