import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule()
export class NotificationsServiceConfigModule {
  static forRoot(): ModuleWithProviders<NotificationsServiceConfigModule> {
    return {
      ngModule: NotificationsServiceConfigModule,
      providers: [ ],
    };
  }
}
