import { Component } from '@angular/core';

@Component({
  selector: 'app-files-data',
  templateUrl: './files-data.component.html',
  styleUrls: ['./files-data.component.scss']
})
export class FilesDataComponent {

}
