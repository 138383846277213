<abp-page>
  <div class="d-flex align-items-center" *ngIf="!titleKey; else showTitle">
    <app-fg-breadcrumb [breadcrumbs]="breadcrumbs"></app-fg-breadcrumb>
    <span class="material-symbols-rounded">info</span>
  </div>

  <ng-template #showTitle>
    <h1>
      {{ titleKey | abpLocalization }}
    </h1>
  </ng-template>

  <abp-advanced-entity-filters localizationSourceName="pilotsService" [list]="list">
    <abp-advanced-entity-filters-form>
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <!-- Text Filters -->
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="filterText">
                {{ 'pilotsService::FilterText' | abpLocalization }}
              </label>
              <input
                id="filterText"
                class="form-control"
                [(ngModel)]="filters.filterText"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="firstName">
                {{ 'pilotsService::FirstName' | abpLocalization }}
              </label>
              <input
                id="firstName"
                class="form-control"
                [(ngModel)]="filters.firstName"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="lastName">
                {{ 'pilotsService::LastName' | abpLocalization }}
              </label>
              <input
                id="lastName"
                class="form-control"
                [(ngModel)]="filters.lastName"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="address">
                {{ 'pilotsService::Address' | abpLocalization }}
              </label>
              <input
                id="address"
                class="form-control"
                [(ngModel)]="filters.address"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>

          <!-- Numeric Filters -->
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="stateMin">
                {{ 'pilotsService::StateMin' | abpLocalization }}
              </label>
              <input
                id="stateMin"
                type="number"
                class="form-control"
                [(ngModel)]="filters.stateMin"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="stateMax">
                {{ 'pilotsService::StateMax' | abpLocalization }}
              </label>
              <input
                id="stateMax"
                type="number"
                class="form-control"
                [(ngModel)]="filters.stateMax"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="ratingMin">
                {{ 'pilotsService::RatingMin' | abpLocalization }}
              </label>
              <input
                id="ratingMin"
                type="number"
                class="form-control"
                [(ngModel)]="filters.ratingMin"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="ratingMax">
                {{ 'pilotsService::RatingMax' | abpLocalization }}
              </label>
              <input
                id="ratingMax"
                type="number"
                class="form-control"
                [(ngModel)]="filters.ratingMax"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="upcomingMissionsMin">
                {{ 'pilotsService::UpcomingMissionsMin' | abpLocalization }}
              </label>
              <input
                id="upcomingMissionsMin"
                type="number"
                class="form-control"
                [(ngModel)]="filters.upcomingMissionsMin"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="upcomingMissionsMax">
                {{ 'pilotsService::UpcomingMissionsMax' | abpLocalization }}
              </label>
              <input
                id="upcomingMissionsMax"
                type="number"
                class="form-control"
                [(ngModel)]="filters.upcomingMissionsMax"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="missionCountMin">
                {{ 'pilotsService::MissionCountMin' | abpLocalization }}
              </label>
              <input
                id="missionCountMin"
                type="number"
                class="form-control"
                [(ngModel)]="filters.missionCountMin"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="missionCountMax">
                {{ 'pilotsService::MissionCountMax' | abpLocalization }}
              </label>
              <input
                id="missionCountMax"
                type="number"
                class="form-control"
                [(ngModel)]="filters.missionCountMax"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="perfectMissionPercentageMin">
                {{ 'pilotsService::PerfectMissionPercentageMin' | abpLocalization }}
              </label>
              <input
                id="perfectMissionPercentageMin"
                type="number"
                class="form-control"
                [(ngModel)]="filters.perfectMissionPercentageMin"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="perfectMissionPercentageMax">
                {{ 'pilotsService::PerfectMissionPercentageMax' | abpLocalization }}
              </label>
              <input
                id="perfectMissionPercentageMax"
                type="number"
                class="form-control"
                [(ngModel)]="filters.perfectMissionPercentageMax"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>

          <!-- Dropdown Filters -->

          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="pilotExperienceLevelId">
                {{ 'pilotsService::PilotExperienceLevelId' | abpLocalization }}
              </label>
              <select
                class="form-control"
                id="pilotExperienceLevelId"
                [(ngModel)]="filters.pilotExperienceLevelId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option *ngFor="let level of experienceLevels.items" [value]="level.id">
                  {{ level.description }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="pilotStateId">
                {{ 'pilotsService::PilotStateId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="pilotStateId"
                [(ngModel)]="filters.pilotStateId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]="state.value" *ngFor="let state of states">
                  {{ state.key | abpLocalization }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="requestStatusId">
                {{ 'pilotsService::RequestStatusId' | abpLocalization }}
              </label>
              <select
                class="form-control"
                id="requestStatusId"
                [(ngModel)]="filters.requestStatusId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option *ngFor="let status of requestStatuses.items" [value]="status.id">
                  {{ status.description }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="availabilityId">
                {{ 'pilotsService::AvailabilityId' | abpLocalization }}
              </label>
              <select
                class="form-control"
                id="availabilityId"
                [(ngModel)]="filters.availabilityId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option *ngFor="let availability of availabilities.items" [value]="availability.id">
                  {{ availability.description }}
                </option>
              </select>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>
</abp-page>

<div class="d-flex justify-content-end mt-3" *ngIf="selectedPilotIds.length > 0">
  <button class="btn btn-primary" (click)="assignPilots()">
    {{ selectedPilotIds.length === 1 ? 'Assign Pilot' : 'Assign Pilots' }}
  </button>
</div>

<ngx-datatable
  [rows]="pilotsForSourcingData.items"
  [offset]="pageNumber"
  [count]="pilotsForSourcingData.totalCount"
  [list]="list"
  [limit]="clientMaxResultCount"
  [externalPaging]="true"
  default
  #dataTable
>
  <ngx-datatable-column [maxWidth]="50" [width]="50" [sortable]="false">
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <div
        class="form-check"
        *ngIf="row.requestStatusDescription.toLowerCase().trim() !== 'assigned'"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [checked]="selectedPilotIds.includes(row.id)"
          (change)="toggleCheckbox(row)"
        />
        <label class="form-check-label" for="defaultCheck{{ i }}"></label>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{ 'pilotsService::Name' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.name }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="{{ 'pilotsService::RequestStatus' | abpLocalization }}"
    prop="requestStatus"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <app-fg-chip
        [type]="getRequestStatusChipType(row.requestStatusDescription)"
        [text]="row.requestStatusDescription"
      >
      </app-fg-chip>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{ 'pilotsService::Distance' | abpLocalization }}" prop="distance">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.distance }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="{{ 'pilotsService::UpcomingJobs' | abpLocalization }}"
    prop="upcomingJobs"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.upcomingJobs }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="{{ 'pilotsService::MissionCount' | abpLocalization }}"
    prop="missionCount"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.missionCount }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="{{ 'pilotsService::PerfectMissionPercentage' | abpLocalization }}"
    prop="perfectMissionPercentage"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.perfectMissionPercentage }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="{{ 'pilotsService::Badges' | abpLocalization }}"
    prop="badges"
    [width]="160"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="d-flex">
        <div *ngFor="let badge of row.badges">
          <button type="button" class="btn btn-link" (click)="openBadges(row)">
            <span class="material-symbols-rounded">{{ getBadgeString(badge) }}</span>
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{ 'pilotsService::Rating' | abpLocalization }}" prop="rating">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <ng-container *ngFor="let starType of getStars(row.rating)">
        <span *ngIf="starType === 'filled'" class="material-symbols-rounded filled">star</span>
        <span *ngIf="starType === 'half'" class="material-symbols-rounded filled">star_half</span>
        <span *ngIf="starType === 'empty'" class="material-symbols-rounded empty">star</span>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="{{ 'pilotsService::Availability' | abpLocalization }}"
    prop="availability"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.availabilityDescription }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Actions" [width]="240" [frozenRight]="true">
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <button type="button" class="btn btn-link" (click)="sendRequest(row)">
        <span class="material-symbols-rounded"> crisis_alert </span>
      </button>
      <button type="button" class="btn btn-link" (click)="sendEmail(row)">
        <span class="material-symbols-rounded"> mail </span>
      </button>
      <button type="button" class="btn btn-link" (click)="makeCall(row)">
        <span class="material-symbols-rounded"> call </span>
      </button>
      <button type="button" class="btn btn-link" (click)="openUpdateRequestStatusModal(row)">
        <span class="material-symbols-rounded"> account_tree </span>
      </button>
      <button type="button" class="btn btn-link" [routerLink]="['/pilot-sourcing/pilot-info', row.id]">
        <span class="material-symbols-rounded"> info </span>
      </button>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <div class="d-flex page-size align-items-center">
        <label for="pageSize" class="me-1">
          {{ 'CoreService::PageSize' | abpLocalization }}
        </label>
        <select
          id="pageSize"
          [(ngModel)]="clientMaxResultCount"
          [ngModelOptions]="{ standalone: true }"
          (change)="changePageSize()"
        >
          <option [ngValue]="value" *ngFor="let value of valuesMaxResultCount">
            {{ value }}
          </option>
        </select>
      </div>

      <datatable-pager
        class="d-flex justify-content-center"
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        (change)="dataTable.onFooterPage($event)"
      >
      </datatable-pager>

      <div class="d-flex page-count justify-content-end">
        {{ rowCount?.toLocaleString() }} total
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<abp-modal [busy]="isModalBusy" [options]="{ size: 'lg' }" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>Update Request Status</h3>
  </ng-template>

  <ng-template #abpBody>
    <form
      [formGroup]="form"
      id="update-status"
      (ngSubmit)="submitUpdateRequestStatus()"
      validateOnSubmit
    >
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="status"> Request Status </label>
          <select class="form-control" formControlName="status" id="status">
            <option *ngFor="let status of requestStatuses.items" [value]="status.id">
              {{ status.description }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="update-status">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
