import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetOrderDetailInput, OrderDetailsCreateDto, OrderDetailsDto, OrderDetailsExcelDownloadDto, OrderDetailsUpdateDto } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  apiName = 'missionsService';
  

  create = (input: OrderDetailsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderDetailsDto>({
      method: 'POST',
      url: '/api/missions-service/order-detail',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/order-detail/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderDetailsDto>({
      method: 'GET',
      url: `/api/missions-service/order-detail/${id}`,
    },
    { apiName: this.apiName,...config });
  
getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/order-detail/download-token',
    },
    { apiName: this.apiName,...config });

getListAsExcelFile = (input: OrderDetailsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/order-detail/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetOrderDetailInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<OrderDetailsDto>>({
      method: 'GET',
      url: '/api/missions-service/order-detail',
      params: { filterText: input.filterText, orderId: input.orderId, locationId: input.locationId, productId: input.productId, qtyMin: input.qtyMin, qtyMax: input.qtyMax, deliverableId: input.deliverableId, unitPriceMin: input.unitPriceMin, unitPriceMax: input.unitPriceMax, totalMin: input.totalMin, totalMax: input.totalMax, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: OrderDetailsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderDetailsDto>({
      method: 'PUT',
      url: `/api/missions-service/order-detail/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
