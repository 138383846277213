import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-mission-flow',
  templateUrl: './mission-flow.component.html',
  styleUrls: ['./mission-flow.component.scss'],
})
export class MissionFlowComponent implements OnInit {
  missionId: string;
  missionName: string;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  private isProgrammaticChange = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.missionId = this.route.snapshot.paramMap.get('missionId');
    // TODO there is no mission name in the model
    this.missionName = '4310 Lower Honoapiilani Rd, Unit 414';
  }

  async onTabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel === "Captures" && !this.isProgrammaticChange) {
      const path = 'captures';
      try {
        await this.router.navigate(['pilot-sourcing/missions', this.missionId, path]);
      } catch (error) {
        console.log('Navigation error:', error);
      }
    } else {
      this.isProgrammaticChange = false;
    }
  }

  async navigateToSourcing() {
    try {
      const path = 'pilot-sourcing';
      await this.router.navigate(['pilot-sourcing/missions', this.missionId, path]);
      this.isProgrammaticChange = true;
      this.tabGroup.selectedIndex = 3;
    } catch (error) {
      console.log('Navigation error:', error);
    }
  }
}
