import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { ColDef } from 'ag-grid-community';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { PrioritiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/priorities.service';
import { CustomersDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics/models';
import {
  GetMissionInput,
  LocationsDto,
  MissionsDto,
  PilotSourcingMissionsDto,
  PortafoliosDto,
  ProjectsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  LocationsService,
  MissionsService,
  PortafoliosService,
  ProjectsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

import { PrioritiesDto, StatesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { StatesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { GridComponent } from '../../../components/grid/grid.component';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pilot-sourcing-missions',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './pilot-sourcing-missions.component.html',
  styles: [],
})
export class PilotSourcingMissionsComponent implements OnInit, OnDestroy {
  data: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetMissionInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: MissionsDto;

  public columnDefs: ColDef[] = [
    { field: 'missionId' },
    { field: 'customer' },
    { field: 'priority' },
    { field: 'location' },
    { field: 'captureDate' },
    { field: 'status' },
    { field: 'project' },
    { field: 'portfolio' },
  ];

  public rowData: any[] = [];
  private subscriptions: Subscription[] = [];

  @ViewChild(GridComponent) private gridComponent!: GridComponent;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionsService,
    public readonly customerservice: CustomersService,
    public readonly priorityService: PrioritiesService,
    public readonly projectService: ProjectsService,
    public readonly locationService: LocationsService,
    public readonly stateService: StatesService,
    public readonly portFolioService: PortafoliosService,
    private router: Router,
    private confirmation: ConfirmationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.filters.PilotSourcing = true;
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<MissionsDto>) => (this.data = list);

    this.rowData = [];

    const subsPilotsouringMissions = this.list.hookToQuery(getData).subscribe({
      next: async (data: PagedResultDto<MissionsDto>) => {
        this.rowData = [];
        for (const item of data.items) {
          const dataItem: PilotSourcingMissionsDto = {
            missionId: item.id,
            customer: await this.getCustomerName(item.customerId).toPromise(),
            priority: await this.getPriority(item.priorityId).toPromise(),
            location: 'Austin Texas',
            captureDate: '07/20/2023',
            status: 'ENABLED',
            project: await this.getProject(item.projectId).toPromise(),
            portfolio: await this.getPortFolio(item.projectId).toPromise(),
          };
          this.rowData.push(dataItem);
        }
        this.gridComponent.updateGridData(this.rowData);
      },
      error: (error: any) => {
        console.error('Error fetching data:', error);
      },
    });
    this.subscriptions.push(subsPilotsouringMissions);
  }

  getCustomerName(customerId: string): Observable<string> {
    return this.customerservice.get(customerId).pipe(
      map((data: CustomersDto) => data.name),
      catchError(error => {
        console.error('Error fetching customer data:', error);
        return throwError(error);
      })
    );
  }

  getPriority(priorityId: string): Observable<string> {
    return this.priorityService.get(priorityId).pipe(
      map((datapriority: PrioritiesDto) => datapriority.description),
      catchError(error => {
        console.error('Error fetching priority data:', error);
        return throwError(error);
      })
    );
  }

  getLocation(locationId: string): Observable<string> {
    return this.locationService.get(locationId).pipe(
      map((datalocation: LocationsDto) => datalocation.gpsCoordinates),
      catchError(error => {
        console.error('Error fetching location data:', error);
        return throwError(error);
      })
    );
  }

  getStateService(stateId: string): Observable<string> {
    return this.stateService.get(stateId).pipe(
      map((datastate: StatesDto) => datastate.description),
      catchError(error => {
        console.error('Error fetching state data:', error);
        return throwError(error);
      })
    );
  }

  getProject(projectId: string): Observable<string> {
    return this.projectService.get(projectId).pipe(
      map((dataProject: ProjectsDto) => dataProject.name),
      catchError(error => {
        console.error('Error fetching project data:', error);
        return throwError(error);
      })
    );
  }

  getPortFolio(projectId: string): Observable<string> {
    return this.projectService.get(projectId).pipe(
      map((dataProj: ProjectsDto) => dataProj.portafolioId),
      catchError(error => {
        console.error('Error fetching potfolio data:', error);
        return throwError(error);
      }),
      switchMap(portafolioId => {
        return this.portFolioService.get(portafolioId).pipe(
          map((dataPortfolio: PortafoliosDto) => dataPortfolio.name),
          catchError(error => {
            console.error('Error fetching potfolio data:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  clearFilters() {
    this.filters = {} as GetMissionInput;
  }

  create() {
    this.router.navigate(['orders/new']);
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'Missions.xlsx');
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
