import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { EthnicityDto, GenderDto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class EthnicitiesService {
  apiName = 'CoreService';

  GetEthnicities = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<EthnicityDto>>({
      method: 'GET',
      url: `/api/core-service/ethnicities`
    },
      { apiName: this.apiName, ...config });

  constructor(private restService: RestService) { }
}
