import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CaptureAttributesDto,
  CapturesCreateDto,
  CapturesDto,
  CapturesExcelDownloadDto,
  CapturesUpdateDto,
  GetCaptureInput,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CapturesService {
  apiName = 'missionsService';

  create = (input: CapturesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CapturesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/capture',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/capture/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CapturesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/capture/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: CapturesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/capture/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetCaptureInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CapturesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/capture',
        params: {
          filterText: input.filterText,
          dateMin: input.dateMin,
          dateMax: input.dateMax,
          captureTypeId: input.captureTypeId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: CapturesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CapturesDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/capture/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getByOrderDetailsId = (
    missionId: string,
    deliverableId: string,
    orderDetailId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, CapturesDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/mission/${missionId}/deliverable/${deliverableId}/orderDetail/${orderDetailId}`,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  getByOrderDetailsAndPilotIdId = (
    missionId: string,
    deliverableId: string,
    orderDetailId: string,
    pilotId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, CapturesDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/mission/${missionId}/deliverable/${deliverableId}/orderDetail/${orderDetailId}/pilotId/${pilotId}`,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  getAttributeValues = (
    captureId: string,
    deliverableId: string,
    orderDetailId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, Array<CaptureAttributesDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/${captureId}/deliverable/${deliverableId}/detail/${orderDetailId}/attributes`,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
