import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'IsTriggered'
})

export class IsQuestionTriggeredPipe implements PipeTransform {
  transform(question: any, args?: any): any {

    if(args == null){
        console.error('IsQuestionTriggeredPipe requires the answers of the parent question');
        return false;
    }

    var splitAnswers = args.split(',');
    for (var answer of splitAnswers) {
      if (question.triggers.includes(answer)) {
        return true;
      }
    }

    return false;
  }
}