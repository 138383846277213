<div class="flg-grid-table-actions">
  <div class="secondary-actions secondary-actions--alone">
    <ng-container *ngFor="let columnAction of columnActions; trackBy: track">
      <button
        *abpPermission="columnAction.abpPermission"
        (click)="columnAction.action.callAction()"
        class="btn single-action"
      >
        <span class="material-symbols-rounded">
          {{ columnAction.actionIcon }}
        </span>
      </button>
    </ng-container>
  </div>
</div>
