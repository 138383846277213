import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { PortafoliosComponent } from './components/portafolios.component';
import { PortafoliosRoutingModule } from './portafolios-routing.module';
import { ColumnsModule } from 'projects/flyguys-pilot/src/app/components/columns/columns.module';
import { TablePageModule } from 'projects/flyguys-pilot/src/app/components/table-page/table-page.module';

@NgModule({
  declarations: [PortafoliosComponent],
  imports: [
    PortafoliosRoutingModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    PageModule,
    ColumnsModule,
    TablePageModule
  ],
  exports: [PortafoliosComponent],
})
export class PortafoliosModule {}
