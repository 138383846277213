<ngx-datatable [rows]="contacts" [columns]="columns" [scrollbarH]="true" [footerHeight]="false" [headerHeight]="55"
  [rowHeight]="50" class="material flg-simple-table" default>
  <ngx-datatable-column name="Contact From" prop="contactFromDescription" [resizeable]="true" [sortable]="false"
    [headerClass]="'flg-simple-table__header'" [cellClass]="'flg-simple-table__cell'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.contactFromDescription }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Name" prop="name" [resizeable]="true" [sortable]="false"
    [headerClass]="'flg-simple-table__header'" [cellClass]="'flg-simple-table__cell'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.name }} {{ row.lastname }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Email" prop="email" [resizeable]="true" [sortable]="false"
    [headerClass]="'flg-simple-table__header'" [cellClass]="'flg-simple-table__cell--email'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.email }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Phone" prop="number" [resizeable]="true" [sortable]="false"
    [headerClass]="'flg-simple-table__header'" [cellClass]="'flg-simple-table__cell'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.number }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Share Data" prop="shareData" [resizeable]="true" [sortable]="false"
    [headerClass]="'flg-simple-table__header'" [cellClass]="'flg-simple-table__cell'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.shareData ? 'Yes' : 'No' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Actions" [width]="130" [resizeable]="true" [frozenRight]="true" [sortable]="false"
    [headerClass]="'flg-simple-table__header'" [cellClass]="'flg-simple-table__cell'">
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <button type="button" class="btn btn-link" (click)="editContact(row)">
        <span class="material-symbols-rounded"> edit </span>
      </button>
      <button type="button" class="btn btn-link" (click)="removeContact(row)">
        <span class="material-symbols-rounded"> delete </span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>