import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { OrderFormContactModel } from '../model/order-form-contact.model';
import { PagedResultDto } from '@abp/ng.core';
import {
  CustomersDto,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { FormHelpers } from '../../../form-helpers';
import { contactTypeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-type.enum';

@Component({
  selector: 'app-order-contact-dialog',
  templateUrl: './order-contact.component.html',
  styleUrls: ['./order-contact.component.scss'],
})
export class OrderContactComponent {
  formOrderContact: FormGroup;
  ordercontactModel: OrderFormContactModel;
  dataContactFrom = Object.keys(contactTypeEnum)
    .filter(key => typeof contactTypeEnum[key] === 'number')
    .map(key => ({
      value: contactTypeEnum[key],
      description: key,
    }));
  constructor(
    public dialogRef: MatDialogRef<OrderContactComponent>,
    @Inject(MAT_DIALOG_DATA) public modalConfig: any
  ) {
    this.ordercontactModel = modalConfig.contact;
    this.formOrderContact = FormHelpers.buildValidatorsOrderContact();
  }

  setDescriptionFromId(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    const item = items.find(x => x.value === Number(id?.toString()));
    this.ordercontactModel[fieldtoSet] = item?.[fieldName];
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveContact(): void {
    this.dialogRef.close(this.ordercontactModel);
  }

  IsValid(): boolean {
    return this.formOrderContact.status != FormHelpers.ValidationStatus.INVALID;
  }
}
