<div class="container h-100 d-flex">
  <div class="row">
    <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
      <img src="assets/images/errors/Error404.png" class="mb-25" alt="not-found" />
    </div>
    <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
      <h2 class="giant-title text-center">404</h2>
      <h2 class="text-center">Oops!!</h2>
      <p class="description text-center">
        The page you are looking for doesn't exist.<br /><br />
        Or you don't have the permission to access to it!
      </p>
      <div class="text-center mt-3">
        <a href="/" class="btn common-button color-white" mat-stroked-button>Back to Home</a>
      </div>
    </div>
  </div>
</div>
