import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { ColDef } from 'ag-grid-community';
import { CustomersDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics/models';
import {
  GetMissionInput,
  GetPortafolioInput,
  LocationsDto,
  MissionsDto,
  PortafoliosDto,
  ProjectsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  MissionsService,
  PortafoliosService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { GridComponent } from '../../../components/grid/grid.component';
import { PrioritiesDto, StatesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { PilotSourcingPorfolioDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/basics/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pilot-sourcing-portafolio',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './pilot-sourcing-portafolio.component.html',
  styles: [],
})
export class PilotSourcingPortafolioComponent implements OnInit, OnDestroy {
  data: PagedResultDto<PortafoliosDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetPortafolioInput;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: MissionsDto;

  public columnDefs: ColDef[] = [
    { field: 'portfolioId' },
    { field: 'name' },
    { field: 'customer' },
    { field: 'priority' },
    { field: 'percentagecomplete', headerName: '% complete' },
  ];

  public rowData: any[] = [];
  private subscriptions: Subscription[] = [];

  @ViewChild(GridComponent) private gridComponent!: GridComponent;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly portFolioService: PortafoliosService
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.portFolioService.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<PortafoliosDto>) => (this.data = list);

    this.rowData = [];

    const subsPilotsouringPortFolio = this.list.hookToQuery(getData).subscribe({
      next: async (data: PagedResultDto<PortafoliosDto>) => {
        this.rowData = [];
        for (const item of data.items) {
          const dataItem: PilotSourcingPorfolioDto = {
            portfolioId: item.id,
            name: item.name,
            customer: 'test customer',
            priority: 'HIGH',
            percentagecomplete: '50%',
            portfolio: '',
          };
          this.rowData.push(dataItem);
        }
        this.gridComponent.updateGridData(this.rowData);
      },
      error: (error: any) => {
        console.error('Error fetching data:', error);
      },
    });
    this.subscriptions.push(subsPilotsouringPortFolio);
  }

  clearFilters() {
    this.filters = {} as GetMissionInput;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
