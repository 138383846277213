import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { QuestionViewModel } from '../../models/question-view-model';
import { QuestionnaireLogicManager } from '../../questionnaire-logic.service';

@Component({
  selector: 'lib-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
})
export class QuestionDetailsComponent implements OnInit, OnChanges {
  @Input() QuestionnaireLogic: QuestionnaireLogicManager;
  @Input() question: QuestionViewModel;
  @Input() showIndex: boolean = true;
  @Input() DisplayInList: boolean;
  @Input() EditMode: boolean;

  questionChilds: QuestionViewModel[];

  index: number = -1;

  ngOnInit(): void {
    this.questionChilds = this.QuestionnaireLogic.getQuestionChilds(this.question.questionId);
    this.index = this.QuestionnaireLogic.getQuestionIndex(this.question);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.questionChilds = this.QuestionnaireLogic.getQuestionChilds(this.question.questionId);
    this.index = this.QuestionnaireLogic.getQuestionIndex(this.question);
  }

  UpdateMultiSelectAnswer(event, option) {
    var selectedOptions =  this.question.answer != '' ? this.question.answer.split(',') : [];

    if (event) {
      selectedOptions.push(option);
    } else {
      selectedOptions = selectedOptions.filter(x => x != option);
    }

    this.question.answer = selectedOptions.toString();
  }
}
