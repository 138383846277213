import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type {
  GetMissionFinancialExpensesInput,
  MissionFinancialExpenseDto,
} from '../../../proxy/missions-service/relational/models';
import { MissionFinancialExpenseService } from '../../../proxy/missions-service/controllers/relational/mission-financial-expense.service';
import { ExpenseTypeService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/expense-type.service';
@Component({
  selector: 'lib-mission-financial-expense',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './mission-financial-expense.component.html',
  styles: [],
})
export class MissionFinancialExpenseComponent implements OnInit {
  data: PagedResultDto<MissionFinancialExpenseDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetMissionFinancialExpensesInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: MissionFinancialExpenseDto;
  
  expenseTypes: { [id: string]: string } = {};
  action:any;

  clientMaxResultCount = 10;
  pageNumber = 0;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionFinancialExpenseService,
    private confirmation: ConfirmationService,
    private expenseTypeService: ExpenseTypeService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<MissionFinancialExpenseDto>) => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);

    this.expenseTypeService.getList({maxResultCount: 100}).subscribe(r =>{
      this.expenseTypes = r.items?.reduce((result, dto) => {
        result[dto.id] = dto.description;
        return result; 
      }, {} as { [id: string]: string })
    });
  }
  
  setAction(act: any){
    this.action = act;
  }

  clearFilters() {
    this.filters = {} as GetMissionFinancialExpensesInput;
  }

  buildForm() {
    const { expenseTypeId, description, units, uomId, itemCost, totalItemCost, expenseDate } =
      this.selected || {};

    this.form = this.fb.group({
      expenseTypeId: [expenseTypeId ?? null, []],
      description: [description ?? null, [Validators.required, Validators.maxLength(100)]],
      units: [units ?? '0', []],
      uomId: [uomId ?? null, []],
      itemCost: [itemCost ?? '0', []],
      totalItemCost: [totalItemCost ?? '0', []],
      expenseDate: [expenseDate ? new Date(expenseDate) : null, []],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.id, {
          ...this.form.value,
          concurrencyStamp: this.selected.concurrencyStamp,
        })
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: MissionFinancialExpenseDto) {
    this.selected = record;
    this.showForm();
  }

  delete(record: MissionFinancialExpenseDto) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'MissionFinancialExpense.xlsx');
      });
  }
}
