import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { ProjectsComponent } from './components/projects.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { MaterialSharedModule } from 'projects/flyguys-pilot/src/app/material-shared/material-shared.module';
import { ColumnsModule } from 'projects/flyguys-pilot/src/app/components/columns/columns.module';
import { TablePageModule } from 'projects/flyguys-pilot/src/app/components/table-page/table-page.module';

@NgModule({
  declarations: [ProjectsComponent],
  imports: [
    ProjectsRoutingModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    MaterialSharedModule,
    PageModule,
    ColumnsModule,
    TablePageModule
  ],
  exports: [ProjectsComponent],
})
export class ProjectsModule {}
