import { Component, Input, OnInit } from '@angular/core';

interface ChipType {
  backgroundColor: string;
  color: string;
}

const CHIP_TYPES: { [key: string]: ChipType } = {
  grayChip: {
    backgroundColor: '#EBEBEB',
    color: '#0000008E',
  },
  orangeChip: {
    backgroundColor: 'rgba(237, 108, 2, 0.04)',
    color: '#ED6C02',
  },
  pinkChip: {
    backgroundColor: '#FCE4EC',
    color: '#E91E63',
  },
  purpleChip: {
    backgroundColor: '#FBF7FC',
    color: 'var(--mainColor)',
  },
  blueChip: {
    backgroundColor: '#bc5a2e',
    color: '#FFFFFF',
  },
  greenChip: {
    backgroundColor: 'rgba(46, 125, 50, 0.08)',
    color: '#2E7D32',
  },
  ligthBlueChip: {
    backgroundColor: 'rgba(0, 160, 223, 0.1)',
    color: '#00A0DF',
  }
};

@Component({
  selector: 'app-fg-chip',
  templateUrl: './fg-chip.component.html',
  styleUrls: ['./fg-chip.component.scss'],
})
export class FgChipComponent implements OnInit {
  @Input() type: string;
  @Input() text: string;
  @Input() fontWeight: "normal" | "bold" | "lighter" | "bolder" | number = "normal";

  chipType: ChipType;

  ngOnInit() {
    this.chipType = CHIP_TYPES[this.type] || CHIP_TYPES.grayChip;
  }
}
