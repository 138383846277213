import {
  Component,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  NgZone,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderFormDelirableModel } from '../../model/order-form-delirable.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormHelpers } from 'projects/flyguys-pilot/src/app/form-helpers';
import { Helpers } from 'projects/flyguys-pilot/src/app/helpers';

@Component({
  selector: 'app-new-deliverable',
  templateUrl: './new-deliverable.component.html',
  styleUrls: ['./new-deliverable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewDeliverableComponent {
  newdeliverable: OrderFormDelirableModel;
  formNewDelirable: FormGroup;

  dataDeliverable = [
    { id: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733335', name: 'Orthomosaic' },
    { id: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733336', name: 'Marketing Package B' },
    { id: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733145', name: 'Photo Orbit' },
    { id: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733165', name: 'Multispectral' },
  ];

  constructor(
    public dialogRef: MatDialogRef<NewDeliverableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderFormDelirableModel
  ) {
    this.newdeliverable = data;
    this.formNewDelirable = FormHelpers.buildValidatorsDeliverables(true);
  }

  setDescriptionFromId(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    const item = items.find(x => x.id === id);
    this.newdeliverable[fieldtoSet] = item?.[fieldName];
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveDeliverable(): void {
    //save deliverable
    this.dialogRef.close(this.newdeliverable);
  }

  IsValid(): boolean {
    return this.formNewDelirable.status !== FormHelpers.ValidationStatus.INVALID;
  }
}
