<div class="header">
  <h3 class="title">Captures</h3>
</div>

<ngx-datatable [rows]="captures" [columns]="captureColumns" [scrollbarH]="true" default>
  <ngx-datatable-column name="Capture #" prop="captureNumber">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.captureNumber }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Capture Date" prop="captureDate">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.captureDate }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Capture Time" prop="captureTime">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.captureTime }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Capture Must be" prop="captureMustBe">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.captureMustBe">{{ row.captureMustBe }}</span>
      <span *ngIf="!row.captureMustBe">--</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Pilots" prop="pilots">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.pilots.length > 0">
        <span *ngFor="let pilot of row.pilots; let last = last"
          >{{ pilot.name }}{{ last ? '' : ', ' }}</span
        >
      </span>
      <span *ngIf="row.pilots.length === 0">--</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Flexible Capture Time" prop="flexibleCaptureTime">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.flexibleCaptureTime }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Deliverables Included" prop="deliverables">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.deliverables.length > 0">
        <span *ngFor="let deliverable of row.deliverables; let last = last"
          >{{ deliverable.name }}{{ last ? '' : ', ' }}</span
        >
      </span>
      <span *ngIf="row.deliverables.length === 0">--</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Actions">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button
        *ngIf="!row.isFullyAssigned"
        type="button"
        class="btn btn-link"
        (click)="navigateToSourcing(row.captureId)"
      >
        <span class="material-symbols-rounded">record_voice_over</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
