import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { FormsModule } from '@angular/forms';
import { MaterialSharedModule } from 'projects/flyguys-pilot/src/app/material-shared/material-shared.module';
import { QuestionDetailsComponent } from './components/question-details/question-details.component';
import { IsQuestionTriggeredPipe } from './is-question-triggered-pipe';

@NgModule({
  declarations: [QuestionnaireComponent, QuestionDetailsComponent, IsQuestionTriggeredPipe],
  imports: [CommonModule, FormsModule, MaterialSharedModule],
  exports: [QuestionnaireComponent],
})
export class QuestionnaireModule {}
