<div class="d-flex flex-column align-items-center justify-content-center">
  <div class="loading d-flex flex-column align-items-center text-center shadow rounded p-3">
    <mat-spinner></mat-spinner>
    <ng-container *ngIf="message.length > 0">
      <p class="mt-3 mb-0">
        {{ message }}
      </p>
    </ng-container>
  </div>
</div>
