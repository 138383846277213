import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import {
  PilotPreferredLocationsCreateDto,
  PilotPreferredLocationsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { PilotPreferredLocationsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/pilotPreferredLocations.service';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';

import { FlyguysGeofenceComponent, FlyguysMapGeofence } from '@flyguys/geofence';

import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-pilot-profile-overview',
  templateUrl: './pilot-profile-overview.component.html',
  styleUrls: ['./pilot-profile-overview.component.scss'],
})
export class PilotProfileOverviewComponent implements AfterViewInit {
  @ViewChild(FlyguysGeofenceComponent) map: FlyguysGeofenceComponent;
  @Input() pilotId: string;
  constructor(
    private readonly _pilotService: PilotService,
    private readonly _pilotPreferredLocationsService: PilotPreferredLocationsService,
    private readonly toaster: ToasterService
  ) {}

  ngAfterViewInit(): void {
    this.refreshMap(this.pilotId);
  }

  /**
   * Handles how to respond when a geofence is added, or it fails while doing so
   * @param geofence FlyguysMapGeofence | null
   */
  handleGeofenceAdd(geofence: FlyguysMapGeofence | null) {
    if (geofence) {
      this.toaster.info(`Service area for "${geofence.location}" added.`);
    }
  }

  /**
   * Converts geofences to a DTO
   * @param geofences
   * @returns PilotPreferredLocationsDto
   */
  private convertToPreferredLocationsDto(
    geofences: FlyguysMapGeofence[]
  ): PilotPreferredLocationsDto[] {
    const newLocations: PilotPreferredLocationsDto[] = geofences.map((g: FlyguysMapGeofence) => ({
      id: g.id ? g.id : null,
      center: JSON.stringify(g.circle.getCenter()),
      bounds: JSON.stringify(g.circle.getBounds()),
      radius: String((g.circle.getRadius() * 0.000621371192).toFixed(3)),
      pilotId: this.pilotId,
    }));

    return newLocations;
  }

  /**
   * Creates new geofences in the API
   * @param list PilotPreferredLocationsCreateDto[]
   */
  private createList(list: PilotPreferredLocationsCreateDto[]) {
    this._pilotPreferredLocationsService.createList(list).subscribe({
      next: response => {
        console.log(`createList	${response}`);
        this.refreshMap(this.pilotId);
        this.toaster.success('Changes saved');
      },
      error: err => {
        console.error(`Unable to save preferred locations list ${err}`);
        this.toaster.error('Error saving changes');
      },
    });
  }

  /**
   * Updates geofences in the API
   * @param list PilotPreferredLocationsDto[]
   */
  private updateList(list: PilotPreferredLocationsDto[]) {
    this._pilotPreferredLocationsService.updateList(list).subscribe({
      next: response => {
        console.log(`updateList	${response}`);
        this.toaster.success('Changes saved');
      },
      error: err => {
        console.error(`Unable to update preferred locations list ${err}`);
        this.toaster.error('Error saving changes');
      },
    });
  }

  /**
   * Updates the map with the current geofences stored in the API
   * @param pilotId string
   * @returns void
   */
  private refreshMap(pilotId: string): void {
    this.map.clearGeofences();
    this._pilotService.getPilotPreferredLocations(pilotId).subscribe({
      next: locations => {
        if (locations.length > 0) {
          const centerCoords = JSON.parse(locations[0].center);
          this.map.centerAt(centerCoords);
        }

        locations.forEach(preferredLocation => {
          this.addGeofence(preferredLocation);
        });

        this.map.centerOnGeofences();
      },
      error: err => {
        console.error(`Unable to get pilot preferred locations:\n${err}`);
      },
    });
  }

  private addGeofence(preferredLocation: PilotPreferredLocationsDto) {
    const locationLatLng = JSON.parse(preferredLocation.center);
    // Radii get stored in miles, Google Maps parses them in meters
    const locationRadius = Number(preferredLocation.radius) * 1609;

    this.map.addGeofence(locationLatLng, preferredLocation.id, {
      radius: locationRadius,
    });
  }

  /**
   * Handles the removal of a geofence
   * @param geofence FlyguysMapGeofence
   */
  handleRemoveGeofence(geofence: FlyguysMapGeofence) {
    if (this.map?.geofences?.length == 0) {
      const latLng = new google.maps.LatLng(
        geofence.circle.getCenter().lat(),
        geofence.circle.getCenter().lng()
      );
      this.map.addGeofence(latLng, geofence.id, {
        radius: geofence.circle.getRadius(),
      });
      this.toaster.error('Geofence cannot be removed; at least one must exist.');
    } else {
      if (geofence.id) {
        this._pilotPreferredLocationsService.delete(geofence.id).subscribe({
          next: _ => {
            this.toaster.success('Geofence removed');
          },
          error: err => {
            this.toaster.error('Error while removing Geofence');
            console.error(`Unable to get remove geofence:\n${err}`);
          },
        });
      }
    }
  }

  /**
   * Handles the "save changes" interaction
   */
  handleSaveChanges(): void {
    const updatedGeofences = this.map.geofences.filter(g => Boolean(g.id));

    if (updatedGeofences.length > 0) {
      this.updateList(this.convertToPreferredLocationsDto(updatedGeofences));
    }

    const newGeofences = this.map.geofences.filter(g => !Boolean(g.id));

    if (newGeofences.length > 0) {
      this.createList(
        this.convertToPreferredLocationsDto(newGeofences) as PilotPreferredLocationsCreateDto[]
      );
    }
  }
}
