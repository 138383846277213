import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mission-info-badge',
  templateUrl: './mission-info-badge.component.html',
  styleUrls: ['./mission-info-badge.component.scss'],
})
export class MissionInfoBadgeComponent {
  @Input() icon: string;
  @Input() subtitle: string;
  @Input() description: string;
}
