import { mapEnumToOptions } from '@abp/ng.core';

export enum recurrenceEnum {
  NoRepeat = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Custom = 4,
}
export const EnumRecurreceOptions = mapEnumToOptions(recurrenceEnum);
