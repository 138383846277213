import { Component, Input, OnInit } from '@angular/core';
import { MissionPilotsPortalDto } from '../../../models/missions/missions-pilot-portal-dto';
import { Router } from '@angular/router';
import { Helpers } from '../../../helpers';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { MatDialog } from '@angular/material/dialog';
import { DistanceModalComponent } from '../../dialog/distance-modal/distance-modal.component';

@Component({
  selector: 'app-mission-card',
  templateUrl: './mission-card.component.html',
  styleUrls: ['./mission-card.component.scss'],
})
export class MissionCardComponent implements OnInit {
  private readonly DEFAULT_URI = '00000000-0000-0000-0000-000000000000';
  @Input() mission: MissionPilotsPortalDto;
  @Input() status: string;
  @Input() statusColor: string;
  @Input() calculatedTime: string;
  @Input() isMissionFinder: boolean = false;
  @Input() pilot: PilotDto;

  constructor(
    private router: Router,
    private readonly dialog: MatDialog
  ) { }

  dateTime: Date;

  ngOnInit(): void {
    var date = this.mission.captureDate.toString().split('T')[0];
    var captureDate = date + 'T' + this.mission.captureTime + ':00Z'
    this.dateTime = new Date(captureDate);
  }

  navigateToDetail() {
    let missionCoordinatorId = this.mission.missionCoordinatorId ?? this.DEFAULT_URI;
    if (this.isMissionFinder) {
      this.router.navigate([
        '/mission-details',
        this.mission.missionId,
        this.mission.captureId,
        missionCoordinatorId,
        1
      ]);
    } else {
      this.router.navigate([
        '/mission-details',
        this.mission.missionId,
        this.mission.captureId,
        missionCoordinatorId,
      ]);
    }

  }

  public getCaptureDate(selectedCapture: MissionPilotsPortalDto) {
    const date = Helpers.getLocalTime(selectedCapture.captureTime, selectedCapture.captureDate.toString());
    return date ? date : selectedCapture.captureDate;
  }

  public getCaptureTime(selectedCapture: MissionPilotsPortalDto) {
    if (selectedCapture?.captureTime && selectedCapture?.captureDate)
      return Helpers.getLocalTime(selectedCapture.captureTime, selectedCapture.captureDate.toString());

    return null;
  }

  showDrivingDistance() {
    var missionCoords = this.mission.gpsCoordinates.split(",");
    if (missionCoords.length != 2) {
      console.log("Mission has no correct value for coordinates");
      return;
    }

    var destLat = Number.parseFloat(missionCoords[0]);
    var destLng = Number.parseFloat(missionCoords[1]);
    var originLat = Number.parseFloat(this.pilot.addressLat);
    var originLng = Number.parseFloat(this.pilot.addressLng);
    
    var destination = new google.maps.LatLng(destLat, destLng);
    var origin = new google.maps.LatLng(originLat, originLng);

    var matrix = new google.maps.DistanceMatrixService();
    matrix.getDistanceMatrix({
      destinations: [destination],
      origins: [origin],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      language: 'en-US',
    }, (res, status) => this.showDistanceDialog(res, status))
  }

  showDistanceDialog(response: google.maps.DistanceMatrixResponse, status:google.maps.DistanceMatrixStatus) {
    const firstResult = response.rows[0].elements[0];

    this.dialog.open(DistanceModalComponent, {
      data: {
        distance: firstResult.distance,
        duration: firstResult.duration,
        jobId: this.mission.jobId,
      },
      width: '400px',
    });
  }
}