<mat-card class="p-4">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <app-fg-breadcrumb
      [breadcrumbs]="[
        { text: 'Orders', link: '/orders/list' },
        { text: 'Mission', link: '/orders/list' },
        { text: missionName }
      ]"
    ></app-fg-breadcrumb>
    <div class="d-flex align-items-center">
      <span class="no-shrink">Status:</span>
      <app-fg-chip class="ml-4 no-shrink" type="purpleChip" text="Pilot Sourcing"></app-fg-chip>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="d-flex align-items-center">
      <app-mission-info-badge
        [icon]="'person_raised_hand'"
        [subtitle]="'Requesting Customer:'"
        [description]="'Drone Deploy'"
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'calendar_today'"
        [subtitle]="'Capture Date:'"
        [description]="'05/13/2023'"
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'schedule'"
        [subtitle]="'Capture Time:'"
        [description]="'09:00 AM'"
      ></app-mission-info-badge>
    </div>

    <div class="d-flex align-items-center justify-content-end">
      <button type="button" class="btn btn-link" (click)="navigateToSourcing()">
        <span class="material-symbols-rounded mr-8px">record_voice_over</span>
      </button>

      <span class="material-symbols-rounded mr-8px">add_notes</span>
      <span class="material-symbols-rounded mr-8px">event_repeat</span>
    </div>
  </div>

  <div>
    <mat-tab-group
      #tabGroup
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="Order Request">
        <app-order-request></app-order-request>
      </mat-tab>
      <mat-tab label="Products / Deliverables">
        <app-deliverables></app-deliverables>
      </mat-tab>
      <mat-tab label="Financials">
        <app-financials></app-financials>
      </mat-tab>
      <mat-tab label="Captures">
        <router-outlet></router-outlet>
      </mat-tab>
      <mat-tab label="Client Information">
        <app-client-information></app-client-information>
      </mat-tab>
      <mat-tab label="Files / Data">
        <app-files-data></app-files-data>
      </mat-tab>
      <mat-tab label="Notes">
        <app-notes></app-notes>
      </mat-tab>
      <mat-tab label="Timeline">
        <app-timeline></app-timeline>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>
