<mat-card>
  <div class="card-body">
    <h3 class="open-sans fs-16 bold">Overall Rating</h3>

    <div class="grid-wrapper">
      <div>
        <label class="fs-12 open-sans">Communication</label>
        <app-column-stylizer
          [columnValue]="4"
          [typeColumn]="'rating'"
          [columnRules]=""
        ></app-column-stylizer>
      </div>
      <div>
        <label class="fs-12 open-sans">Timeliness</label>
        <app-column-stylizer
          [columnValue]="3.7"
          [typeColumn]="'rating'"
          [columnRules]=""
        ></app-column-stylizer>
      </div>
      <div>
        <label class="fs-12 open-sans">Scope of work</label>
        <app-column-stylizer
          [columnValue]="5"
          [typeColumn]="'rating'"
          [columnRules]=""
        ></app-column-stylizer>
      </div>
      <div>
        <label class="fs-12 open-sans">Quality of data</label>
        <app-column-stylizer
          [columnValue]="3"
          [typeColumn]="'rating'"
          [columnRules]=""
        ></app-column-stylizer>
      </div>
    </div>
  </div>
</mat-card>
