import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-subtitle-description',
  templateUrl: './subtitle-description.component.html',
  styleUrls: ['./subtitle-description.component.scss'],
})
export class SubtitleDescriptionComponent {
  @Input() subtitle: string;
  @Input() description: string;
}
