<div class="mt-4">
  <div class="row mx-0">
    <div class="col-12">
      <app-info-card-with-action
        [icon]="'receipt_long'"
        [title]="'missionsService::OrderDetails' | abpLocalization"
        [actionIcon]="'edit'"
        (actionClicked)="onEditClick()"
      >
        <div class="subtitle t-mb-8">{{ 'missionsService::MissionSummary' | abpLocalization }}</div>
        <div class="description t-mb-8">{{ orderRequest.orderDetails.description }}</div>
        <div>
          <app-subtitle-description
            [subtitle]="'missionsService::OrderPriority' | abpLocalization"
            [description]="orderRequest.orderDetails.orderPriority"
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::OrderIndustry' | abpLocalization"
            [description]="orderRequest.orderDetails.orderIndustry"
          ></app-subtitle-description>
        </div>
      </app-info-card-with-action>
    </div>

    <div class="col-6">
      <app-info-card-with-action
        [icon]="'distance'"
        [title]="'missionsService::Location' | abpLocalization"
        [actionIcon]="'edit'"
        (actionClicked)="onEditClick()"
      >
        <app-subtitle-description
          [subtitle]="'missionsService::City' | abpLocalization"
          [description]="orderRequest.location.city"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::State' | abpLocalization"
          [description]="orderRequest.location.state"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Country' | abpLocalization"
          [description]="orderRequest.location.country"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Address' | abpLocalization"
          [description]="orderRequest.location.address"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::ZipCode' | abpLocalization"
          [description]="orderRequest.location.zipCode"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Longitude' | abpLocalization"
          [description]="orderRequest.location.longitude"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Latitude' | abpLocalization"
          [description]="orderRequest.location.latitude"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::AirspaceClassification' | abpLocalization"
          [description]="orderRequest.location.airspaceClassification"
        ></app-subtitle-description>
        <div class="btn btn-outline-primary">
          {{ 'missionsService::SiteContactInformation' | abpLocalization }}
        </div>
      </app-info-card-with-action>
      <app-info-card-with-action
        [icon]="'markunread_mailbox'"
        [title]="'missionsService::DeliverableAspects' | abpLocalization"
        [actionIcon]="'edit'"
        (actionClicked)="onEditClick()"
      >
        <div *ngFor="let aspect of orderRequest.deliverableAspects">
          <app-subtitle-description
            [subtitle]="'missionsService::DeliverableDueDate' | abpLocalization"
            [description]="aspect.deliverableDueDate"
          ></app-subtitle-description>
          <div class="subtitle t-mb-8">
            {{ 'missionsService::UploadingDataInstructions' | abpLocalization }}
          </div>
          <div class="description t-mb-8">
            {{ aspect.uploadingDataInstructions }}
          </div>
        </div>
      </app-info-card-with-action>
    </div>
    <div class="col-6">
      <div class="w-100">
        <flg-map
          #map
          [markers]="mapMarkers"
          [options]="{
            center: { lat: 4.626137636370072, lng: -74.06811384764599 }
          }"
          [dimensions]="mapDimensions"
          (markerData)="handleMarkerClick($event)"
        ></flg-map>
      </div>
    </div>
    <div class="col-12 mt-3">
      <app-info-card-with-action
              [icon]="'add_notes'"
              [title]="'missionsService::AttachmentsAndNotes' | abpLocalization"
              [actionIcon]="'edit'"
              (actionClicked)="onEditClick()"
      >
        <div class="subtitle t-mb-8">
          {{ 'missionsService::AdditionalNotes' | abpLocalization }}
        </div>
        <div class="description t-mb-12">
          {{ orderRequest.attachmentsAndNotes.additionalNotes }}
        </div>
        <div class="d-flex">
          <div *ngFor="let doc of orderRequest.attachmentsAndNotes.referenceDocumentation; let i = index">
            <div class="btn btn-gray" [ngClass]="{'t-ml-8': i > 0}">
              {{ doc }}
            </div>
          </div>
        </div>
      </app-info-card-with-action>
    </div>
  </div>
</div>
