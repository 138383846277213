import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { OrderFormModel } from '../model/order-form.model';
import {
  ContactsService,
  MissionsService,
  OrderDetailsService,
  OrdersService,
  PortafoliosService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import {
  CustomerContactsService,
  CustomersService,
} from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { ListService, PagedResultDto } from '@abp/ng.core';
import {
  CountriesDto,
  GetPriorityInput,
  GetProductTypeInput,
  GetStateInput,
  IndustriesDto,
  PrioritiesDto,
  StatesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups/models';
import {
  AirSpaceClassificationsService,
  CountriesService,
  IndustriesService,
  PrioritiesService,
  StatesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  GetCountryInput,
  GetIndustryInput,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups/models';
import {
  CustomerContactsCreateDto,
  CustomersCreateDto,
  CustomersDto,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { ProductDto, ProductService } from 'projects/product-service/src/lib/proxy/products';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { FormGroup } from '@angular/forms';
import { Helpers } from '../../../helpers';
import {
  ContactsCreateDto,
  ContactsDto,
  GetContactInput,
  MissionsDto,
  OrderDetailsCreateDto,
  OrdersCreateDto,
  PortafoliosCreateDto,
  ProjectsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';

import { ProjectsService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics/projects.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, catchError, map, of, startWith, throwError } from 'rxjs';
import { OrderContactComponent } from '../order-contact/order-contact.component';
import { enumState } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-state.enum';
import { OrderFormContactModel } from '../model/order-form-contact.model';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm.dialog.component';
import { NewDeliverableComponent } from './new-deliverable/new-deliverable.component';
import { FormHelpers } from '../../../form-helpers';
import { contactTypeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-type.enum';
import { levelCoordinationEnum } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared/level-coordination.enum';
import { contactMethodEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-method.enum';
import { OrderFormSiteModel } from '../model/order-form-site.model';
import { OrderFormDelirableModel } from '../model/order-form-delirable.model';
import { OrderRequestDTO } from '../dto/order-request.dto';
import { OrderSiteComponent } from './order-site/order-site.component';
import { OrderRequestMissionDTO } from '../dto/order-request-mission.dto';
import {
  OrdersDto,
  PortafolioContactsCreateDto,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/basics/models';
import { OrderRequestDeliverableDTO } from '../dto/order-request-deliverable.dto';
import { recurrenceEnum } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
import { JsonHubProtocol } from '@microsoft/signalr';

@Component({
  selector: 'app-new-order-form',
  templateUrl: './new-order-form.component.html',
  styleUrls: ['./new-order-form.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class NewOrderFormComponent implements OnInit, OnDestroy {
  orderModel: OrderFormModel;
  formStepOne: FormGroup;
  formStepTwo: FormGroup;
  formStepThree: FormGroup;
  formStepFour: FormGroup;
  messageHash: string;

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterCountries = {} as GetCountryInput;
  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };
  filterStates = {} as GetStateInput;
  dataCustomers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };
  filterCustomers = {} as GetCustomerInput;
  dataIndustries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterIndustries = {} as GetIndustryInput;

  dataProducts: PagedResultDto<ProductDto> = {
    items: [],
    totalCount: 0,
  };
  filterProductTypes = {} as GetProductTypeInput;

  dataPriorities: PagedResultDto<PrioritiesDto> = {
    items: [],
    totalCount: 0,
  };
  filterPriorities = {} as GetPriorityInput;

  private subscriptions = new Subscription();

  filteredCustomers: Observable<string[]>;
  filteredContacts: Observable<string[]> = of([]);

  filteredSubcustomers: Observable<string[]>;
  filteredBillingcustomer: Observable<string[]>;

  customerselected: CustomersDto;
  contactselected: ContactsDto;
  subcustomerselected: CustomersDto;
  billingCustomerselected: CustomersDto;

  /*dataContacts: ContactsDto[] = [
    {
      id: '17A88833-7172-E44D-C933-3A0C779F2D42',
      firstName: 'John',
      lastName: 'Doe',
      email: 'john@example.com',
      phone: '+5491162152763',
      state: enumState.Enabled,
      contactMethod: 'Email',
      contactMethodId: '1',
      levelCommunicationDescription: '',
      levelCommunicationId: '1',
    },
    {
      id: '17A88833-7172-E44D-C933-3A0C779F2D32',
      firstName: 'Jane',
      lastName: 'Smith',
      email: 'janesmith@example.com',
      phone: '+5491162152761',
      state: enumState.Enabled,
      contactMethod: 'Email',
      contactMethodId: '1',
      levelCommunicationDescription: '',
      levelCommunicationId: '1',
    },
  ];*/
  filterContacts = {} as GetContactInput;
  datasubCostumers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };

  databillingCostumers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };

  columns = [
    { prop: 'customerDescription', name: 'Contact from' },
    { prop: 'name', name: 'Name' },
    { prop: 'lastname', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'number', name: 'Number' },
    { prop: 'shareData', name: 'Share Data' },
    { name: 'Actions' },
  ];

  refreshContacts: OrderFormContactModel[];
  refreshSites: OrderFormSiteModel[];
  refreshDeliverables: OrderFormDelirableModel[];
  numberOfSitesToAdd = 0;
  customerContact: ContactsCreateDto;
  @ViewChildren('orderMission') orderMissions: QueryList<OrderSiteComponent>;

  constructor(
    private router: Router,
    public readonly list: ListService,
    public dialogSuccess: MatDialog,
    public dialogConfirm: MatDialog,
    public readonly missionService: MissionsService,
    public readonly customerService: CustomersService,
    public readonly customerContactService: CustomerContactsService,
    public readonly airspaceService: AirSpaceClassificationsService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly industriesService: IndustriesService,
    public readonly priorityService: PrioritiesService,
    public readonly contactsService: ContactsService,
    public readonly productsService: ProductService,
    public readonly portfolioService: PortafoliosService,
    public readonly projectsService: ProjectsService,
    public readonly orderService: OrdersService,
    public readonly orderDetailService: OrderDetailsService,
    public orderContactDialog: MatDialog,
    public orderDeliverableDialog: MatDialog
  ) {
    this.orderModel = new OrderFormModel();

    this.orderModel.additionalContacts = [];

    this.orderModel.products = [
      {
        productId: '1',
        productName: 'Package A',
        deliverables: [
          {
            deliverableId: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733309',
            deliverableName: 'Orthomosaic',
            altitude: '90°',
            grimbalangle: '90°',
            overlap: '20°',
          },
          {
            deliverableId: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733310',
            deliverableName: 'Marketing Package A',
            altitude: '90°',
            grimbalangle: '90°',
            overlap: '20°',
          },
          {
            deliverableId: '9ECD9AC3-FE8F-330E-1D27-3A0D5A733335',
            deliverableName: 'Photo Orbit',
            altitude: '90°',
            grimbalangle: '90°',
            overlap: '20°',
          },
        ],
        selected: false,
      },
      {
        productId: '2',
        productName: 'Package B',
        deliverables: [
          {
            deliverableId: '1',
            deliverableName: 'Orthomosaic',
            altitude: '90°',
            grimbalangle: '90°',
            overlap: '20°',
          },
          {
            deliverableId: '2',
            deliverableName: 'Multispectral Images',
            altitude: '90°',
            grimbalangle: '90°',
            overlap: '20°',
          },
          {
            deliverableId: '3',
            deliverableName: 'Terranis Repot',
            altitude: '90°',
            grimbalangle: '90°',
            overlap: '20°',
          },
        ],
        selected: false,
      },
    ];
    this.formStepOne = FormHelpers.buildValidatorsOrderStep1();
    this.formStepTwo = FormHelpers.buildValidatorsOrderStep2();
    this.formStepThree = FormHelpers.buildValidators(this.orderModel);
  }

  ngOnInit() {
    this.getPriorities();
    this.getIndustries();

    this.AddNewSite();
    this.filteredCustomers = this.formStepOne.controls.customerDescription.valueChanges.pipe(
      startWith(''),
      map(val => (val.length >= 3 ? this.generateSuggestionsCustomers(val) : []))
    );

    this.filteredContacts = this.formStepOne.controls.customerContactDescription.valueChanges.pipe(
      startWith(''),
      map(val => (val.length >= 3 ? this.generateSuggestionsContacts(val) : []))
    );

    this.filteredSubcustomers = this.formStepOne.controls.subclientName.valueChanges.pipe(
      startWith(''),
      map(val => (val.length >= 3 ? this.generateSuggestionsSubCustomers(val) : []))
    );

    this.filteredBillingcustomer = this.formStepOne.controls.billingCustomerName.valueChanges.pipe(
      startWith(''),
      map(val => (val.length >= 3 ? this.generateSuggestionsBillingCustomers(val) : []))
    );
  }

  getPriorities() {
    const subspriorities = this.priorityService.getList(this.filterPriorities).subscribe(
      (data: PagedResultDto<PrioritiesDto>) => {
        if (data?.totalCount != 0) {
          this.dataPriorities = data;
        }
      },
      error => {
        console.log('Error on getting priorities: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(subspriorities);
  }

  getIndustries() {
    const subsindustries = this.industriesService.getList(this.filterIndustries).subscribe(
      (data: PagedResultDto<IndustriesDto>) => {
        if (data?.totalCount != 0) {
          this.dataIndustries = data;
        }
      },
      error => {
        console.log('Error on getting customers: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(subsindustries);
  }

  getProducts() {
    const subsproducts = this.productsService.getList(this.filterProductTypes).subscribe(
      (data: PagedResultDto<ProductDto>) => {
        if (data?.totalCount != 0) {
          this.dataProducts = data;
        }
      },
      error => {
        console.log('Error on getting products: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(subsproducts);
  }

  setDescriptionFromId(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    const item = items.find(x => x.id === id?.toString());
    this.orderModel[fieldtoSet] = item?.[fieldName];
  }

  async saveAll() {
    var orderIdCreated = '';
    this.orderModel.customerId = await this.saveCustomers(
      contactTypeEnum.Company,
      'customerId'
    ).toPromise();
    await this.saveContact(contactTypeEnum.Company, 'customerId', 'customerContactId');
    this.orderModel.subcustomerId = await this.saveCustomers(
      contactTypeEnum.Subclient,
      'subcustomerId'
    ).toPromise();
    this.orderModel.billingCustomerId = await this.saveCustomers(
      contactTypeEnum.Company,
      'billingCustomerId'
    ).toPromise();
    await this.saveContact(
      contactTypeEnum.Company,
      'billingCustomerId',
      'billingCustomerContactId'
    );
    console.log('customer added', this.orderModel.customerId);
    console.log('customer added', this.orderModel.billingCustomerId);
    console.log('customer added', this.orderModel.billingCustomerId);

    this.createOrderRequest();
  }

  saveCustomers(conacttype: contactTypeEnum, fieldCustomerId: string): Observable<string> {
    if (this.orderModel[fieldCustomerId]?.trim() == '') {
      return this.customerService.create(this.buildCustomerDto(conacttype)).pipe(
        map((data: CustomersDto) => data.id),
        catchError(error => {
          console.error('Error fetching customer data:', error);
          return throwError(error);
        })
      );
    } else {
      return this.orderModel[fieldCustomerId];
    }
  }

  async saveContact(conacttype: contactTypeEnum, fieldCustomerId: string, field: string) {
    if (this.orderModel[field]?.trim() == '') {
      let newContact = this.buildContactDto(conacttype);
      const subssavecontact = this.contactsService.create(newContact).subscribe(
        data => {
          if (data?.id != '') {
            this.orderModel[field] = data.id;
            this.orderModel[fieldCustomerId] = data.id;
            this.saveCustomerContact(this.orderModel[fieldCustomerId], this.orderModel[field]);
          }
        },
        error => {
          console.log('Error on saving contact: ' + JSON.stringify(error));
        }
      );
      this.subscriptions.add(subssavecontact);
    }
  }

  saveCustomerContact(newcustomerId: string, newcontactId: string) {
    let newCustomerContact: CustomerContactsCreateDto = {
      customerId: newcustomerId,
      contactId: newcontactId,
      state: enumState.Enabled,
    };
    console.log('customer contact to save' + JSON.stringify(newCustomerContact));
    const subssavecustomercontact = this.customerContactService
      .create(newCustomerContact)
      .subscribe(
        data => {
          if (data?.id != '') {
            console.log('customer contact success' + JSON.stringify(newCustomerContact));
          }
        },
        error => {
          console.log('Error on saving contact: ' + JSON.stringify(error));
        }
      );
    this.subscriptions.add(subssavecustomercontact);
  }

  buildCustomerDto(contactType: contactTypeEnum): CustomersCreateDto {
    switch (contactType) {
      case contactTypeEnum.Company:
        return {
          name: this.orderModel.customerDescription,
          parentId: null,
          levelId: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          customerStatusId: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          language: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          state: enumState.Enabled,
        };
      case contactTypeEnum.Subclient:
        return {
          name: this.orderModel.subclientName,
          parentId: this.orderModel.customerId,
          levelId: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          customerStatusId: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          language: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          state: enumState.Enabled,
        };
      case contactTypeEnum.External:
        return {
          name: this.orderModel.billingCustomerName,
          parentId: null,
          levelId: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          customerStatusId: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          language: '0B74B287-2A52-0FCC-FEBF-3A0B42E229E9',
          state: enumState.Enabled,
        };
    }
  }

  buildContactDto(conacttype: contactTypeEnum): ContactsCreateDto {
    let contact: ContactsCreateDto;
    switch (conacttype) {
      case contactTypeEnum.Company:
        return {
          contactTypeId: contactTypeEnum.Company,
          firstName: this.orderModel.customerContactDescription,
          lastName: '',
          shareData: false,
          phone: this.orderModel.customerContactNumber,
          email: this.orderModel.customerContactEmail,
          levelCommunicationId: levelCoordinationEnum.Notify,
          contactMethodId: contactMethodEnum.Email,
          state: enumState.Enabled,
        };
      case contactTypeEnum.External:
        return {
          contactTypeId: contactTypeEnum.External,
          firstName: this.orderModel.billingCustomerName,
          lastName: '',
          shareData: false,
          phone: this.orderModel.billingCustomerConctactNumber,
          email: this.orderModel.billingCustomerContactEmail,
          levelCommunicationId: levelCoordinationEnum.Notify,
          contactMethodId: contactMethodEnum.Email,
          state: enumState.Enabled,
        };
    }
    return contact;
  }

  buildOrderRequestDto(): OrderRequestDTO {
    return {
      portfolio: this.buildPortfolioDto(),
      portfoliocontacts: this.buildPortfolioContactsDto(),
      missions: this.getMissionsDto(),
      priorityId: this.orderModel.priorityId,
      industryId: this.orderModel.industryid,
      deliverables: this.buildDeliverablesDto(),
    };
  }

  buildPortfolioDto(): PortafoliosCreateDto {
    return {
      name: this.getPorfolioName(),
      customerId: this.orderModel.customerId,
      billingCustomerId: this.orderModel.billingCustomerId,
      subCustomerId: this.orderModel.subcustomerId,
      state: enumState.Enabled,
    };
  }

  getPorfolioName(): string | undefined {
    if (
      this.getRecurrenceFromFromById(this.orderModel?.orderSites?.[0]?.frequencyId) ==
      recurrenceEnum.NoRepeat
    ) {
      return this.orderModel.orderSites[0].missionName;
    } else {
      return this.orderModel.portfolioName;
    }
  }

  getRecurrenceFromFromById(id: string) {
    for (const key of Object.keys(recurrenceEnum)) {
      if (recurrenceEnum[key] === id) {
        return recurrenceEnum[key];
      }
    }
  }

  buildPortfolioContactsDto(): ContactsCreateDto[] {
    const contactList: ContactsCreateDto[] = [];
    for (const contact of this.orderModel.additionalContacts) {
      const newContact: ContactsCreateDto = {
        contactTypeId: this.getContactFromFromById(contact.contactFromId),
        firstName: contact.name,
        lastName: contact.lastname,
        phone: contact.number,
        email: contact.email,
        levelCommunicationId: levelCoordinationEnum.Coordinate,
        contactMethodId: contactMethodEnum.Email,
        shareData: contact.shareData,
        state: enumState.Enabled,
      };
      contactList.push(newContact);
    }
    return contactList;
  }

  getContactFromFromById(id: string) {
    for (const key of Object.keys(contactTypeEnum)) {
      if (contactTypeEnum[key] === id) {
        return contactTypeEnum[key];
      }
    }
  }

  buildDeliverablesDto(): OrderRequestDeliverableDTO[] {
    // const deliverableList: OrderRequestDeliverableDTO[] = [];
    // for (const product of this.orderModel.products) {
    //   if (product.selected) {
    //     for (const deliverable of product.deliverables) {
    //       const newDeliverable: OrderRequestDeliverableDTO = {
    //         deliverableId: deliverable.deliverableId,
    //         altitude: deliverable.altitude,
    //         grimbalangle: deliverable.grimbalangle,
    //         overlap: deliverable.overlap,
    //       };
    //       deliverableList.push(newDeliverable);
    //     }
    //   }
    // }
    // for (const adddeliverable of this.orderModel.additionalDeliverables) {
    //   const newDeliverable: OrderRequestDeliverableDTO = {
    //     deliverableId: adddeliverable.deliverableId,
    //     altitude: adddeliverable.altitude,
    //     grimbalangle: adddeliverable.grimbalangle,
    //     overlap: adddeliverable.overlap,
    //   };
    //   deliverableList.push(newDeliverable);
    // }
    // return deliverableList;
    return undefined;
  }

  getMissionsDto(): OrderRequestMissionDTO[] {
    const orderMissionsDto: OrderRequestMissionDTO[] = [];
    this.orderMissions.forEach((orderMission: OrderSiteComponent) => {
      orderMissionsDto.push(orderMission.buildOrderRequestMissionDto());
    });
    return orderMissionsDto;
  }

  createOrderRequest() {
    console.log('order to save ' + JSON.stringify(this.buildOrderRequestDto()));
    const subsOrder = this.orderService.createOrderRequest(this.buildOrderRequestDto()).subscribe(
      data => {
        if (data?.id != '') {
          console.log('order created ' + data?.id);
          Helpers.openDialog(
            this.dialogSuccess,
            'Order Created',
            'Order Created :  Mission Name' + this.orderModel.projectName,
            this.router,
            'orders'
          );
        }
      },
      error => {
        console.error('Error createing order request:', error);
      }
    );
    this.subscriptions.add(subsOrder);
  }

  onPhoneNumberChange(event: any) {
    // Handle phone number change if needed
  }

  AddNewSite(): void {
    this.refreshSites = [];
    const newSite: OrderFormSiteModel = new OrderFormSiteModel();
    this.numberOfSitesToAdd = this.numberOfSitesToAdd + 1;
    newSite.number = this.numberOfSitesToAdd.toString();
    this.orderModel.orderSites.push(newSite);
    this.refreshSites = this.orderModel.orderSites;
  }

  AddNewDeliverable(): void {
    const newDeliverable: OrderFormDelirableModel = new OrderFormDelirableModel();
    const dialogRef = this.orderDeliverableDialog.open(NewDeliverableComponent, {
      data: newDeliverable,
      disableClose: true,
      panelClass: 'modal-base',
      width: '709px',
      height: '408px',
      maxWidth: '709px',
    });
    dialogRef.afterClosed().subscribe((data: OrderFormDelirableModel) => {
      if (data) {
        this.refreshDeliverables = [];
        this.orderModel.additionalDeliverables.push(data);
        this.refreshDeliverables = this.orderModel.additionalDeliverables;
      }
    });
  }

  removeDeliverable(row: OrderFormDelirableModel) {
    const dialogref = this.dialogConfirm.open(ConfirmDialogComponent, {
      data: { title: 'Delete this Deliverable?', message: '' },
      disableClose: true,
      panelClass: 'modal-confirm',
      width: '401px',
      height: '207px',
      maxWidth: '475px',
    });

    dialogref.afterClosed().subscribe((confirm: Boolean) => {
      if (confirm) {
        this.refreshDeliverables = [];
        const indexToRemove = this.orderModel.additionalDeliverables.indexOf(row);
        if (indexToRemove !== -1) {
          this.orderModel.additionalContacts.splice(indexToRemove, 1);
        }
        this.refreshContacts = this.orderModel.additionalContacts;
      }
    });
  }

  private generateSuggestionsCustomers(val: string) {
    let suggestions = [];
    this.orderModel.customerDescription = '';
    this.filterCustomers.name = val;
    const customer = this.customerService.getList(this.filterCustomers).subscribe(
      (data: PagedResultDto<CustomersDto>) => {
        if (data?.totalCount != 0) {
          this.dataCustomers = data;
          for (let s of this.dataCustomers.items) {
            suggestions.push(s.name.trim());
          }
        }
      },
      error => {
        console.log('Error on getting customers: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(customer);
    return suggestions;
  }

  private generateSuggestionsContacts(val: string) {
    let suggestions = [];
    this.orderModel.customerContactDescription = '';

    let searchTerm = val.toLowerCase();
    /*suggestions = this.dataContacts
      .filter(
        contact =>
          contact.firstName.toLowerCase().includes(searchTerm) ||
          contact.lastName.toLowerCase().includes(searchTerm)
      )
      .map(contact => `${contact.firstName} ${contact.lastName}`);*/
    return suggestions;
  }

  private generateSuggestionsSubCustomers(val: string) {
    let suggestions = [];
    this.orderModel.subclientName = '';
    this.filterCustomers.name = val;
    const subscustomer = this.customerService.getList(this.filterCustomers).subscribe(
      (data: PagedResultDto<CustomersDto>) => {
        if (data?.totalCount != 0) {
          this.datasubCostumers = data;
          for (let s of this.datasubCostumers.items) {
            suggestions.push(s.name.trim());
          }
        }
      },
      error => {
        console.log('Error on getting subscostumers: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(subscustomer);
    return suggestions;
  }

  private generateSuggestionsBillingCustomers(val: string) {
    let suggestions = [];
    this.orderModel.billingCustomerName = '';
    this.filterCustomers.name = val;
    const billingcustomer = this.customerService.getList(this.filterCustomers).subscribe(
      (data: PagedResultDto<CustomersDto>) => {
        if (data?.totalCount != 0) {
          this.databillingCostumers = data;
          for (let s of this.databillingCostumers.items) {
            suggestions.push(s.name.trim());
          }
        }
      },
      error => {
        console.log('Error on getting subscostumers: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(billingcustomer);
    return suggestions;
  }

  getCustomer() {
    if (this.orderModel.customerDescription.length >= 3) {
      if (this.dataCustomers?.items.length > 0) {
        for (let s of this.dataCustomers.items) {
          if (s.name.trim() == this.orderModel.customerDescription.trim()) {
            console.log(s.name.trim());
            console.log(this.orderModel.customerDescription.trim());
            this.customerselected = s;
            this.orderModel.customerId = this.customerselected.id;
            break;
          }
        }
      }
    }
  }

  getContact() {
    if (this.orderModel.customerContactDescription.length >= 3) {
      /*if (this.dataContacts?.length > 0) {
        for (let s of this.dataContacts) {
          let contactfound = `${s.firstName} ${s.lastName}`;
          if (contactfound.trim() == this.orderModel.customerContactDescription.trim()) {
            this.contactselected = s;
            this.orderModel.customerContactId = this.contactselected.id;
            this.orderModel.customerContactEmail = this.contactselected.email;
            this.orderModel.customerContactNumber = this.contactselected.phone;
            break;
          }
        }
      }*/
    }
  }

  getSubcustomer() {
    this.orderModel.subclientName = '';
    if (this.orderModel.subclientName.length >= 3) {
      if (this.datasubCostumers?.items.length > 0) {
        for (let s of this.datasubCostumers.items) {
          if (s.name.trim() == this.orderModel.subclientName.trim()) {
            this.subcustomerselected = s;
            this.orderModel.subcustomerId = this.subcustomerselected.id;
            break;
          }
        }
      }
    }
  }

  getBillingCustomer() {
    if (this.orderModel.billingCustomerName.length >= 3) {
      if (this.databillingCostumers?.items.length > 0) {
        for (let s of this.databillingCostumers.items) {
          if (s.name.trim() == this.orderModel.billingCustomerName.trim()) {
            this.billingCustomerselected = s;
            this.orderModel.billingCustomerId = this.billingCustomerselected.id;
            break;
          }
        }
      }
    }
  }

  // Contact Modal Interactions
  handleAddContact(): void {
    let contact = new OrderFormContactModel();

    const dialogRef = this.orderContactDialog.open(OrderContactComponent, {
      panelClass: 'modal-base',
      width: '900px',
      height: '530px',
      maxWidth: '900px',
      data: {
        contact,
        title: 'Add Contact',
        actions: {
          confirm: 'Add',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          const contacts = [...this.orderModel.additionalContacts, ...[data]];
          this.orderModel.additionalContacts = contacts;
        }
      })
    );
  }

  handleRemoveContact(row: OrderFormContactModel) {
    const dialogRef = this.dialogConfirm.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this contact?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      panelClass: 'modal-confirm',
      width: '400px',
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((removeContact: Boolean) => {
        if (removeContact) {
          const indexToRemove = this.orderModel.additionalContacts.indexOf(row);

          if (indexToRemove !== -1) {
            this.orderModel.additionalContacts.splice(indexToRemove, 1);
          }

          this.orderModel.additionalContacts = [...this.orderModel.additionalContacts];
        }
      })
    );
  }

  handleEditContact(contact: OrderFormContactModel) {
    const dialogRef = this.orderContactDialog.open(OrderContactComponent, {
      panelClass: 'modal-base',
      width: '900px',
      height: '530px',
      maxWidth: '900px',
      data: {
        contact,
        title: 'Edit Contact',
        actions: {
          confirm: 'Edit',
          cancel: 'Cancel',
        },
      },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
        if (data) {
          const indexToEdit = this.orderModel.additionalContacts.indexOf(data);
          if (indexToEdit !== -1) {
            this.orderModel.additionalContacts[indexToEdit] = {
              ...this.orderModel.additionalContacts[indexToEdit],
              contactFromId: data.contactFromId,
              contactFromDescription: data.contactFromDescription,
              email: data.email,
              name: data.name,
              lastname: data.lastname,
              number: data.number,
              shareData: data.shareData,
            };
          }

          this.orderModel.additionalContacts = [...this.orderModel.additionalContacts];
        }
      })
    );
  }

  isStepValid(step: number): boolean {
    switch (step) {
      case 1:
        return this.formStepOne.status !== FormHelpers.ValidationStatus.INVALID;
      case 2:
        return this.formStepTwo.status !== FormHelpers.ValidationStatus.INVALID;
      case 3:
        return this.formStepThree.status !== FormHelpers.ValidationStatus.INVALID;
      default:
        return false;
    }
  }

  discardOrder() {
    const dialogref = this.dialogConfirm.open(ConfirmDialogComponent, {
      data: { title: 'Discard job creation?', message: '' },
      disableClose: true,
      panelClass: 'modal-confirm',
    });

    dialogref.afterClosed().subscribe((confirm: Boolean) => {
      if (confirm) {
        this.router.navigate(['orders']);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
