<div class="container h-100 d-flex">
  <div class="row">
    <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
      <img src="assets/images/errors/ErrorInternetLost.png" class="mb-25" alt="no-internet" />
    </div>
    <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
      <h2 class="giant-title text-center">Sorry</h2>
      <h2 class="text-center">Oops!</h2>
      <p class="description text-center">Internet Lost</p>
      <div class="text-center mt-3">
        <a mat-raised-button class="btn common-button color-white" routerLink="/">Back to Home</a>
      </div>
    </div>
  </div>
</div>
