import { v4 as uuidv4 } from 'uuid';

export class ChunkModel {

    start: number;
    end: number;
    chunkNumber: number;
    identifier: string;
    success: boolean;
    webAppChunkId: string;

    constructor() {
        this.start = 0;
        this.end = 0;
        this.chunkNumber = 0;
        this.webAppChunkId = uuidv4();

    }
}
