import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FlyguysMapDimension, FlyguysMapMarker } from '@flyguys/map';
import { OrderRequestModel } from '../../models/order-request-model';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.scss'],
})
export class OrderRequestComponent implements AfterViewInit {
  public mapMarkers: FlyguysMapMarker[] = [];
  public mapDimensions: FlyguysMapDimension = {
    height: '500px', // default size in case the calculation fails
    width: '500px',
  };
  @ViewChild('map') mapElement: ElementRef;
  public orderRequest: OrderRequestModel;
  missionId: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private missionFlowService: MissionFlowService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.missionId = params.get('missionId');
      this.fetchOrderRequestData();
    });
  }

  ngAfterViewInit(): void {
    this.calculateMapDimensions();
    window.addEventListener('resize', () => this.calculateMapDimensions());
  }

  calculateMapDimensions() {
    const siblingCol6 = this.el.nativeElement.querySelector('.col-6');

    if (siblingCol6) {
      const calculatedWidth = siblingCol6.clientWidth - 30;
      const calculatedHeight = siblingCol6.clientHeight - 15;
      this.mapDimensions.width = calculatedWidth + 'px';
      this.mapDimensions.height = calculatedHeight + 'px';
    }
  }

  onEditClick(): void {
    console.log('Edit icon clicked');
  }

  handleMarkerClick(marker: FlyguysMapMarker): void {
    console.log(marker.lat.toString());
    console.log(marker.lng.toString());
  }

  fetchOrderRequestData(): void {    
    this.missionFlowService.getOrderRequestData(this.missionId).subscribe({
      next: data => {
        this.orderRequest = data;
      },
      error: error => {
        console.error('Error fetching orderRequest data:', error);
      },
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', () => this.calculateMapDimensions());
  }
}
