import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetQuestionnaireV2Input, QuestionnaireV2Dto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireV2Service {
  apiName = 'CoreService';

  get = (input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, QuestionnaireV2Dto>({
      method: 'GET',
      url: '/api/core-service/questionary-v2',
      params: { id: input },
    },
      { apiName: this.apiName, ...config });

  getByName = (input: GetQuestionnaireV2Input, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<QuestionnaireV2Dto>>({
      method: 'GET',
      url: '/api/core-service/questionary-v2',
      params: { filterText: input.filterText },
    },
      { apiName: this.apiName, ...config });

  constructor(private restService: RestService) { }
}
