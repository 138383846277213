import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  DeliverableServicesCreateDto,
  DeliverableServicesDto,
  DeliverableServicesExcelDownloadDto,
  DeliverableServicesUpdateDto,
  GetDeliverableServiceInput,
} from '../../relationals/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { NotesDto } from '../../basics/models';

@Injectable({
  providedIn: 'root',
})
export class DeliverableServicesService {
  apiName = 'missionsService';

  create = (input: DeliverableServicesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableServicesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/deliverable-service',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/deliverable-service/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableServicesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/deliverable-service/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/deliverable-service/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetDeliverableServiceInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<DeliverableServicesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/deliverable-service',
        params: {
          filterText: input.filterText,
          deliveribleId: input.deliveribleId,
          serviceId: input.serviceId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (
    input: DeliverableServicesExcelDownloadDto,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/deliverable-service/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: DeliverableServicesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableServicesDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/deliverable-service/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getNotesByCaptureDeliverable = (
    input: { captureId: string; deliverableId: string; orderDetailsId: string },
    config?: Partial<Rest.Config>
  ) => {
    return this.restService.request<any, PagedResultDto<NotesDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/deliverable-service/notes`,
        params: {
          captureId: input.captureId,
          deliverableId: input.deliverableId,
          orderDetailsId: input.orderDetailsId,
        },
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );
  };

  constructor(private restService: RestService) {}
}
