<div class="card d-flex p-3 align-items-start gap-3 align-self-stretch rounded custom-card">
  <div class="icon-container d-flex justify-content-center align-items-center">
    <div class="rounded-circle">
      <span class="material-symbols-outlined">{{ icon }}</span>
    </div>
  </div>
  <div class="gap"></div>
  <div class="d-flex w-100">
    <div class="right-content">
      <div class="top-row t-mb-8">
        <div class="title">{{ title }}</div>
        <span class="material-symbols-outlined cursor-pointer" (click)="onActionClick()">{{
          actionIcon
        }}</span>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
