<div class="columns-group" *abpPermission="showColumnPermission">
  <button (click)="toggleShowList()" type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" #toggleMenu>
    Hide/Show columns
  </button>
  <div *ngIf="showList" class="columns-menu col" #menu>
    <div class="row" (click)="checkClicked()">
      <div *ngFor="let column of columnsStatus">
        <mat-checkbox *ngIf="checkOptions[column.columnName]" (change)="executeToggler(column, $event.checked)" [checked]="column.defaultChecked">
          Show column {{column.softColumnName}}
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>