<h6 class="mt-3 mb-3">Site {{ this.site.number }}</h6>

<form [formGroup]="formOrderSite" skipValidation>
  <mat-form-field class="w-100">
    <mat-label>Mission Name</mat-label>
    <input
      matInput
      placeholder="Mission Name"
      [(ngModel)]="this.site.missionName"
      formControlName="missionName"
    />
    <mat-error *ngIf="formOrderSite.get('missionName').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-100">
    <mat-label>Mission Summary</mat-label>
    <textarea
      matInput
      placeholder="What is the data being used for?"
      [(ngModel)]="this.site.missionSummary"
      formControlName="missionSummary"
      class="textarea"
    ></textarea>
    <mat-error *ngIf="formOrderSite.get('missionSummary').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <h6 class="mt-3 mb-3">Capture Date & Time</h6>

  <div class="input-group justify-content-between">
    <mat-form-field class="pe-2 flex-grow-1" [floatLabel]="'always'">
      <mat-label>Capture Date</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="this.site.captureDate"
        formControlName="captureDate"
      />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

      <mat-error *ngIf="formOrderSite.get('captureDate').hasError('required')">
        Please enter a value
      </mat-error>
    </mat-form-field>

    <mat-form-field class="pe-2 flex-grow-1" [floatLabel]="'always'">
      <mat-label>Capture Time</mat-label>
      <input
        matInput
        type="time"
        placeholder="00:00PM"
        [(ngModel)]="this.site.captureTime"
        formControlName="captureTime"
      />
    </mat-form-field>

    <mat-form-field class="flex-grow-1" [floatLabel]="'always'">
      <mat-label>Repeats</mat-label>
      <mat-select
        name="frequencyId"
        [(ngModel)]="this.site.frequencyId"
        (ngModelChange)="
          setDescriptionFromId(
            this.site.frequencyId.toString(),
            datafrequency,
            'frequencyDescription',
            'description',
            true
          )
        "
        formControlName="frequencyId"
      >
        <mat-option *ngFor="let data of datafrequency" [value]="data.value">
          {{ data.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-50 pe-2">
      <mat-label>Capture Date must be</mat-label>
      <mat-select
        name="captureDateMustId"
        [(ngModel)]="this.site.captureDateMustId"
        (ngModelChange)="
          setDescriptionFromId(
            this.site.captureDateMustId,
            dataCaptureMust,
            'captureDateMustDescription',
            'name'
          )
        "
        formControlName="captureDateMustId"
      >
        <mat-option *ngFor="let data of dataCaptureMust" [value]="data.id" title="{{ data.name }}">
          {{ data.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-50">
      <mat-label>Flexible Capture Time</mat-label>
      <mat-select
        name="flexibleCaptureId"
        [(ngModel)]="this.site.flexibleCaptureId"
        (ngModelChange)="
          setDescriptionFromId(
            this.site.flexibleCaptureId,
            dataflexible,
            'flexibleCaptureDescription',
            'name'
          )
        "
        formControlName="flexibleCaptureId"
      >
        <mat-option *ngFor="let dataf of dataflexible" [value]="dataf.id" title="{{ dataf.name }}">
          {{ dataf.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <h6 class="mt-3 mb-3">Deliverables</h6>

  <div class="input-group justify-content-between">
    <mat-form-field class="w-100" [floatLabel]="'always'">
      <mat-label>Deliverable Due Date</mat-label>
      <input
        matInput
        [matDatepicker]="pickerDelivery"
        [(ngModel)]="this.site.deliveryDate"
        formControlName="deliveryDate"
      />
      <mat-datepicker-toggle matIconSuffix [for]="pickerDelivery"></mat-datepicker-toggle>
      <mat-datepicker #pickerDelivery></mat-datepicker>
    </mat-form-field>

    <label for="captureDelirables" class="label-order w-100">Capture Deliverables</label>

    <mat-checkbox
      class="mt-3 mb-3"
      [(ngModel)]="this.site.deliveryAllSameTime"
      formControlName="deliveryAllSameTime"
      ><span class="checkbox-label">All at the same time</span></mat-checkbox
    >

    <mat-form-field class="w-100">
      <mat-label>Deliverables Notes</mat-label>
      <textarea
        matInput
        [(ngModel)]="this.site.deliveryNotes"
        formControlName="deliveryNotes"
        class="textarea"
      ></textarea>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Uploading Data Instructions</mat-label>
      <textarea
        matInput
        [(ngModel)]="this.site.uploadingDataInstruction"
        formControlName="uploadingDataInstruction"
        class="textarea"
      ></textarea>

      <mat-error *ngIf="formOrderSite.get('uploadingDataInstruction').hasError('required')">
        Please enter a value
      </mat-error>
    </mat-form-field>
  </div>

  <h6 class="mt-3 mb-3">Site Contact Information</h6>

  <mat-form-field class="w-50 pe-2">
    <mat-label>Name</mat-label>
    <input
      matInput
      placeholder="Name"
      [(ngModel)]="this.site.siteContactName"
      formControlName="siteContactName"
    />
    <mat-error *ngIf="formOrderSite.get('siteContactName').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-50">
    <mat-label>Last Name</mat-label>
    <input
      matInput
      placeholder="Last Name"
      [(ngModel)]="this.site.siteContactLastName"
      formControlName="siteContactLastName"
    />
    <mat-error *ngIf="formOrderSite.get('siteContactLastName').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-50 pe-2">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="Email"
      [(ngModel)]="this.site.siteContactEmail"
      formControlName="siteContactEmail"
    />
    <mat-error
      *ngIf="
        formOrderSite.get('siteContactEmail').hasError('email') &&
        !formOrderSite.get('siteContactEmail').hasError('required')
      "
    >
      Please enter a valid email
    </mat-error>
    <mat-error *ngIf="formOrderSite.get('siteContactEmail').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-50">
    <mat-label>Phone Number</mat-label>
    <input
      matInput
      placeholder="Phone Number"
      [(ngModel)]="this.site.siteContactPhone"
      formControlName="siteContactPhone"
      minlength="10"
      pattern="[0-9]*"
      digitOnly
      placeholder="0000000000"
    />
    <mat-error *ngIf="formOrderSite.controls.siteContactPhone.hasError('minlength')">
      The phone number must contain at least 10 digits
    </mat-error>
  </mat-form-field>

  <label for="preferedcontactMethod" class="label-order w-100">Preferred contact method</label>

  <mat-checkbox
    class="mt-3 mb-3 w-15 pe-2"
    [(ngModel)]="this.site.preferredContactEmail"
    formControlName="preferredContactEmail"
  >
    <span class="checkbox-label">Email</span></mat-checkbox
  >

  <mat-checkbox
    class="mt-3 mb-3 w-15"
    [(ngModel)]="this.site.preferredContactPhone"
    formControlName="preferredContactPhone"
    ><span class="checkbox-label">Phone</span></mat-checkbox
  >

  <label for="levelcoordination" class="label-order w-100">Level of coordination</label>

  <mat-checkbox
    class="mt-3 mb-3 w-15 pe-2"
    [(ngModel)]="this.site.levelCoordinationNotify"
    formControlName="levelCoordinationNotify"
    ><span class="checkbox-label">Notify</span></mat-checkbox
  >

  <mat-checkbox
    class="mt-3 mb-3 w-15"
    [(ngModel)]="this.site.levelCoordinationMeet"
    formControlName="levelCoordinationMeet"
    ><span class="checkbox-label">Meet</span></mat-checkbox
  >

  <mat-checkbox
    class="mt-3 mb-3 w-15"
    [(ngModel)]="this.site.levelCoordinationCoordinate"
    formControlName="levelCoordinationCoordinate"
    ><span class="checkbox-label">Coordinate</span></mat-checkbox
  >

  <mat-form-field class="w-25 pe-2">
    <mat-label>Country</mat-label>
    <mat-select
      name="contries"
      [(ngModel)]="this.site.locCountryId"
      (ngModelChange)="
        setDescriptionFromId(
          this.site.locCountryId,
          dataCountries.items,
          'locCountryDescription',
          'description'
        )
      "
      formControlName="locCountryId"
    >
      <mat-option *ngFor="let c of dataCountries.items" [value]="c.id" title="{{ c.description }}">
        {{ c.description }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="formOrderSite.get('locCountryId').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-25 pe-2">
    <mat-label>State</mat-label>
    <mat-select
      name="state"
      [(ngModel)]="this.site.locStateId"
      (ngModelChange)="
        setDescriptionFromId(
          this.site.locStateId,
          dataStates.items,
          'locStateDescription',
          'description'
        )
      "
      formControlName="locStateId"
    >
      <mat-option *ngFor="let st of dataStates.items" [value]="st.id" title="{{ st.description }}">
        {{ st.description }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="formOrderSite.get('locStateId').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-25 pe-2">
    <mat-label>City</mat-label>
    <input matInput placeholder="City" [(ngModel)]="this.site.locCity" formControlName="locCity" />

    <mat-error *ngIf="formOrderSite.get('locCity').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-25">
    <mat-label>Zip Code</mat-label>
    <input
      matInput
      placeholder="Zip Code"
      [(ngModel)]="this.site.locZipCode"
      formControlName="locZipCode"
    />

    <mat-error *ngIf="formOrderSite.get('locZipCode').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-50 pe-2">
    <mat-label>Latitude</mat-label>
    <input
      matInput
      placeholder="Latitude"
      [(ngModel)]="this.site.loclatitude"
      formControlName="loclatitude"
    />
  </mat-form-field>

  <mat-form-field class="w-50">
    <mat-label>Longitude</mat-label>
    <input
      matInput
      placeholder="Longitude"
      [(ngModel)]="this.site.loclongitude"
      formControlName="loclongitude"
    />
  </mat-form-field>

  <mat-form-field class="w-100">
    <mat-label>Airspace Classification</mat-label>
    <mat-select
      name="airspaceclassification"
      [(ngModel)]="this.site.locAirSpaceClasfId"
      (ngModelChange)="
        setDescriptionFromId(
          this.site.locAirSpaceClasfId,
          dataAirspace.items,
          'locAirSpaceClasfDescription',
          'description'
        )
      "
      formControlName="locAirSpaceClasfId"
    >
      <mat-option
        *ngFor="let air of dataAirspace.items"
        [value]="air.id"
        title="{{ air.description }}"
      >
        {{ air.description }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="formOrderSite.get('locAirSpaceClasfId').hasError('required')">
      Please enter a value
    </mat-error>
  </mat-form-field>

  <label for="attachDocumentation" class="label-order"
    >Attach reference documentation (KML, Sample Images, Authorization forms, other)</label
  >

  <div class="mt-3 mb-3 w-100">
    <button mat-flat-button class="button-addcontact w-15 mt-3 mb-3 me-3" type="button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <mask
          id="mask0_3588_177"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3588_177)">
          <path
            d="M9.99984 12.6923C9.88124 12.6923 9.78215 12.6525 9.70257 12.5729C9.62297 12.4933 9.58317 12.3942 9.58317 12.2756V5.48398L7.94055 7.1266C7.85935 7.2078 7.76373 7.25107 7.65367 7.25642C7.54363 7.26176 7.44107 7.21689 7.34598 7.12179C7.25411 7.02992 7.20897 6.93083 7.21057 6.82452C7.21216 6.71821 7.2573 6.62073 7.34598 6.53206L9.52869 4.34935C9.60133 4.2767 9.67558 4.22569 9.75144 4.19631C9.82729 4.16694 9.91009 4.15225 9.99984 4.15225C10.0896 4.15225 10.1724 4.16694 10.2482 4.19631C10.3241 4.22569 10.3983 4.2767 10.471 4.34935L12.6537 6.53206C12.7349 6.61326 12.7774 6.70967 12.7811 6.82131C12.7848 6.93296 12.7424 7.03312 12.6537 7.12179C12.5618 7.21368 12.4627 7.26042 12.3564 7.26202C12.2501 7.26363 12.151 7.21849 12.0591 7.1266L10.4165 5.48398V12.2756C10.4165 12.3942 10.3767 12.4933 10.2971 12.5729C10.2175 12.6525 10.1184 12.6923 9.99984 12.6923ZM5.51265 15.8333C5.12911 15.8333 4.80887 15.7049 4.55192 15.4479C4.29498 15.191 4.1665 14.8707 4.1665 14.4872V12.9167C4.1665 12.7981 4.2063 12.699 4.2859 12.6194C4.36548 12.5398 4.46457 12.5 4.58317 12.5C4.70175 12.5 4.80084 12.5398 4.88044 12.6194C4.96004 12.699 4.99984 12.7981 4.99984 12.9167V14.4872C4.99984 14.6154 5.05325 14.7329 5.16009 14.8397C5.26693 14.9466 5.38446 15 5.51265 15H14.487C14.6152 15 14.7327 14.9466 14.8396 14.8397C14.9464 14.7329 14.9998 14.6154 14.9998 14.4872V12.9167C14.9998 12.7981 15.0396 12.699 15.1192 12.6194C15.1988 12.5398 15.2979 12.5 15.4165 12.5C15.5351 12.5 15.6342 12.5398 15.7138 12.6194C15.7934 12.699 15.8332 12.7981 15.8332 12.9167V14.4872C15.8332 14.8707 15.7047 15.191 15.4478 15.4479C15.1908 15.7049 14.8706 15.8333 14.487 15.8333H5.51265Z"
            fill="#bc5a2e"
          />
        </g>
      </svg>
      <span class="button-text" (click)="uploadFile()">Upload file(s)</span>
    </button>
  </div>

  <div class="mt-3 mb-3 w-100">
    <flg-map
      [markers]="mapMarkers"
      [options]="{
        center: { lat: 4.626137636370072, lng: -74.06811384764599 }
      }"
      (markerData)="handleMarkerClick($event)"
    ></flg-map>
  </div>

  <mat-form-field class="w-100">
    <mat-label>Aditional Notes</mat-label>
    <textarea
      matInput
      placeholder="Gate Codes, Access, Issues, etc"
      [(ngModel)]="this.site.aditionalNotes"
      formControlName="aditionalNotes"
      class="textarea"
    ></textarea>
  </mat-form-field>
</form>
