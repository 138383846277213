import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ABPInterceptor } from './abp-interceptor';
import { ChangePasswordInterceptor } from './change-password.interceptor';
import { ProfilePictureInterceptor } from './profile-picture.interceptor';
import { TokenInterceptor } from './token-intercept';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ABPInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ChangePasswordInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProfilePictureInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];