import { ModuleWithProviders, NgModule } from '@angular/core';
import { FILE_MANAGEMENT_ROUTE_PROVIDERS } from './providers/route.provider';
import { FILE_MANAGEMENT_FEATURES_PROVIDERS, UPPY_OPTIONS } from '@volo/abp.ng.file-management/common';
import { FileManagementConfigModuleOptions } from './models';

@NgModule()
export class FileManagementConfigModule {
  static forRoot(options = {} as FileManagementConfigModuleOptions): ModuleWithProviders<FileManagementConfigModule> {
    return {
      ngModule: FileManagementConfigModule,
      providers: [
        FILE_MANAGEMENT_ROUTE_PROVIDERS,
        FILE_MANAGEMENT_FEATURES_PROVIDERS,
        {
          provide: UPPY_OPTIONS,
          useValue: options.uppyOptions
        }
      ],
    };
  }

  static forChild(options = {} as FileManagementConfigModuleOptions): ModuleWithProviders<FileManagementConfigModule> {
    return {
      ngModule: FileManagementConfigModule,
      providers: [{
        provide: UPPY_OPTIONS,
        useValue: options.uppyOptions
      }],
    };
  }
}
