<div class="header d-flex justify-content-between align-items-center">
  <h3 class="title mb-0">Deliverables</h3>
  <button class="btn btn-primary button-text" (click)="addOrModify()">
    <span class="material-symbols-rounded">add</span> Add / Modify
  </button>
</div>

<ngx-datatable [rows]="deliverables" [columns]="deliverableColumns" [scrollbarH]="true" default [sorts]="[{prop: 'deliverableName', dir: 'asc'}]">
  <ngx-datatable-column name="Deliverable" prop="deliverableName">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.deliverableName }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Product" prop="product">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.productName }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Package" prop="package">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.packageName }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Actions">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button type="button" class="btn btn-link" (click)="navigateView(row)">
        <span class="material-symbols-rounded">folder</span>
      </button>
      <button type="button" class="btn btn-link" (click)="openInfo(row)">
        <span class="material-symbols-rounded">info</span>
      </button>
      <button type="button" class="btn btn-link" (click)="delete(row)">
        <span class="material-symbols-rounded">delete</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<abp-modal
  [busy]="isAddModifyModalBusy"
  [options]="{ size: 'lg' }"
  [(visible)]="isAddModifyModalOpen"
>
  <ng-template #abpHeader>
    <h3>Add / Modify</h3>
  </ng-template>

  <ng-template #abpBody>
    <mat-radio-group [(ngModel)]="selectedOption">
      <mat-radio-button class="deliverable-radio-button" value="package"
        >Add/Modify Package</mat-radio-button
      >
      <mat-radio-button class="deliverable-radio-button" value="deliverable"
        >Add Deliverables</mat-radio-button
      >
    </mat-radio-group>

    <div *ngIf="selectedOption === 'package'">
      <div class="subtitle">Modify Packages</div>

      <mat-expansion-panel *ngFor="let package of packages">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-checkbox
              class="label"
              [(ngModel)]="package.isChecked"
              (click)="$event.stopPropagation()"
              >{{ package.packageName }}</mat-checkbox
            >
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="!package.isChecked">
          <div class="deliverable-name my-16px" *ngFor="let deliverable of package.deliverables">
            {{ deliverable.deliverableName }}
          </div>
        </div>

        <div *ngIf="package.isChecked">
          <div *ngFor="let deliverable of package.deliverables">
            <div class="deliverable-name my-16px">{{ deliverable.deliverableName }}</div>
            <div class="input-container">
              <ng-container *ngFor="let field of deliverable.fields; let index = index">
                <mat-form-field class="input-field">
                  <mat-label>{{ field.placeholder }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="field.numericValue"
                    [placeholder]="field.placeholder"
                  />
                </mat-form-field>
                <div *ngIf="(index + 1) % 3 === 0 && index !== 0" class="break"></div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>

    <div *ngIf="selectedOption === 'deliverable'">
      <div class="add-new-deliverable-label mt-24px">Add new deliverable</div>

      <mat-form-field class="input-field my-16px w-100">
        <mat-label>Deliverable</mat-label>
        <mat-select [(ngModel)]="selectedDeliverableType">
          <mat-option
            *ngFor="let type of deliverableTypes"
            [value]="type.id"
            title="{{ type.description }}"
          >
            {{ type.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="input-container">
        <mat-form-field class="input-field">
          <mat-label>Altitude</mat-label>
          <input matInput [placeholder]="'Altitude'" />
        </mat-form-field>

        <mat-form-field class="input-field">
          <mat-label>Gimbal Angle</mat-label>
          <input matInput [placeholder]="'Gimbal Angle'" />
        </mat-form-field>

        <mat-form-field class="input-field">
          <mat-label>Overlap</mat-label>
          <input matInput [placeholder]="'Overlap'" />
        </mat-form-field>
      </div>
    </div>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="update-status">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
