import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { EmailNotificationContentsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { EmailNotificationContentsCreateDto } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics';

/**
 * AppInsights eats up all the errors, this class exposes them before sending them to
 * their service.
 */
@Injectable()
export class FLGErrorHandler extends ApplicationinsightsAngularpluginErrorService {
  url = 'https://dev.azure.com/newtonvision/FLYGUYS/_apis/wit/workitems/$Bug?api-version=6.0';

  existingWorkItems = [];

  constructor(
    private http: HttpClient,
    public readonly emailNotificationService: EmailNotificationContentsService
    ) {
    super();
  }

  handleError(error) {
    if (!environment.production) {
      console.error(error);
    }else{
      // this.devOpsIntegration(error);
      // this.sendEmailException(error);
    }
    
    super.handleError(error);
  }

  sendEmailException(error: any){
    const errorData = {
      error: (error.error ?? "N/A"),
      name: (error.name ?? "N/A"),
      message: (error.message ?? "N/A"),
      status: (error.status ?? "N/A"),  
      url: (error.url ?? "N/A")
    };
    var errorObject = {
      sender: "Gabriel.Soria@newtonvisionco.com",
      recipients: [ "david.cuervo@newtonvisionco.com", "bayron.lara@newtonvisionco.com", "juvenal.lopez@newtonvisionco.com" ],
      recipientName: "EmailNotification",
      subject: "Operations Portal Error - " + error.name,
      body: this.parseError(errorData),
      isHtmlContent: false
    } as EmailNotificationContentsCreateDto;
    this.emailNotificationService.send(errorObject).subscribe();

  }

  devOpsIntegration(error){
    const errorData = {
      error: (error.error ?? "N/A"),
      name: (error.name ?? "N/A"),
      message: (error.message ?? "N/A"),
      status: (error.status ?? "N/A"),  
      url: (error.url ?? "N/A")
    };
    var itemExists = false;
    const observables = [];
    this.getWorkItemsByTag().subscribe(
      response => {
        for(var workItem of (response as any).workItems){
          const observable = this.getWorkItem((workItem as any).url);
          observables.push(observable);
        }  
        if(observables.length == 0){
          this.createWorkItem(errorData).subscribe(
            response => {
              console.log('Work item created successfully:', response);
            }
          );
        }else{
          forkJoin(observables).subscribe(
            workItems => {
              this.existingWorkItems = workItems;
              for (const item of this.existingWorkItems) {
                itemExists = this.decodeHtmlEntities((item as any).fields['Microsoft.VSTS.TCM.SystemInfo']) == errorData.url;
                if(itemExists) break;
              }
              if(!itemExists && errorData.url!="N/A"){
                this.createWorkItem(errorData).subscribe(
                  response => {
                    console.log('Work item created successfully:', response);
                  }
                );
              }
          });
        }  
      }
    )
  }

  createWorkItem(errorData: any) {
    const body = [
      {
        'op': 'add',
        'path': '/fields/System.Title',
        'value': errorData.message.Length <= 120 ? errorData.message : "Unhandled angular exception"
      },
      {
        'op': 'add',
        'path': '/fields/Microsoft.VSTS.TCM.ReproSteps',
        'value': this.parseError(errorData)
      },
      {
        'op': 'add',
        'path': '/fields/System.Tags',
        'value': 'AutomaticBugTracker'
      },
      {
        'op': 'add',
        'path': '/fields/Microsoft.VSTS.TCM.SystemInfo',
        'value': errorData.url
      },
      {
        'op': 'add',
        'path': '/fields/Microsoft.VSTS.Common.Priority',
        'value': '3'
      },
      {
        'op': 'add',
        'path': '/fields/Microsoft.VSTS.Common.Severity',
        'value': '3 - Medium'
      }
    ];

    return this.http.post(this.url, body, this.getHttpOptions(true));
  }

  getWorkItemsByTag() {
    const query = {
      query: `SELECT [System.Id], [System.Title], [Microsoft.VSTS.TCM.SystemInfo] FROM workitems WHERE [System.Tags] CONTAINS 'AutomaticBugTracker' AND [System.WorkItemType] = 'Bug'`
    };
    var url = 'https://dev.azure.com/newtonvision/FLYGUYS/_apis/wit/wiql?api-version=6.0';
    return this.http.post(url, query, this.getHttpOptions(false));
  }

  getWorkItem(url: string){
    return this.http.get(url, this.getHttpOptions(false));
  }

  getHttpOptions(option: boolean) {
    const personalAccessToken = 'r5i6srl4pqr5km2qcd6q7bw2rwu32cumvv73lywk6fl2vkuvucrq';
    const headers = new HttpHeaders({
      'Content-Type': option ? 'application/json-patch+json' : 'application/json',
      'Authorization': `Basic ${btoa(`:${personalAccessToken}`)}`
    });
    return { headers: headers };
  }

  decodeHtmlEntities(input: string): string {
    return input.replace(/&amp;/g, '&');
  }

  parseError(error){
    return 'Exception error: ' + error.error 
        + '<br>\nName: ' + error.name
        + '<br>\nMessage: ' + error.message
        + '<br>\nStatus: ' + error.status
        + '<br>\nUrl: ' + error.url;
  }
}