<div class="d-flex align-items-center">
  <div class="d-flex align-items-center">
    <span class="material-symbols-rounded icon-background me-1">{{ icon }}</span>
    <span class="text-darkard-subtitle fg-subtitle"
      ><b>{{ title }}</b
      >:</span
    >
    <span class="fg-description p-1"> {{ description }} </span>
  </div>
</div>
