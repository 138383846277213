<form class="tagus-search-box" (submit)="onSubmit($event)">
  <input
    [(ngModel)]="searchText"
    name="searchText"
    type="text"
    class="input-search"
    placeholder="Search"
  />
  <button type="submit" class="bg-transparent">
    <span class="material-symbols-rounded"> search </span>
  </button>
</form>
