import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';

import { GridComponent } from '../components/grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';

import { PilotSourcingMissionsComponent } from './components/misssions/pilot-sourcing-missions.component';
import { PilotSourcingProjectsComponent } from './components/projects/pilot-sourcing-projects.component';
import { PilotSourcingPortafolioComponent } from './components/portafolio/pilot-sourcing-portafolio.component';
import { PilotSourcingSourcingComponent } from './components/sourcing/pilot-sourcing-sourcing.component';
import { MissionFlowComponent } from './components/mission-flow/mission-flow.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../shared/shared.module';
import { PilotFinderComponent } from './components/pilot-finder/pilot-finder.component';
import { MissionInfoBadgeComponent } from './components/mission-flow/mission-info-badge/mission-info-badge.component';
import { CapturesComponent } from './components/captures/captures.component';
import { DeliverablesComponent } from './components/deliverables/deliverables.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { environment } from '../../environments/environment';
import { PilotInfoComponent } from './components/pilot-info/pilot-info.component';
import { PilotInfoCardComponent } from './components/pilot-info/pilot-info-card/pilot-info-card.component';
import { PilotProfileOverviewComponent } from './components/pilot-profile-overview/pilot-profile-overview.component';
import { EquipmentsComponent } from './components/pilot-profile-overview/equipments/equipments.component';
import { OverallRatingComponent } from './components/pilot-profile-overview/overall-rating/overall-rating.component';
import { RecordsComponent } from './components/pilot-info/records/records.component';
import { OrderRequestComponent } from './components/order-request/order-request.component';
import { ClientInformationComponent } from './components/client-information/client-information.component';
import { FilesDataComponent } from './components/files-data/files-data.component';
import { NotesComponent } from './components/notes/notes.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { MaterialSharedModule } from '../material-shared/material-shared.module';
import { FinancialsComponent } from './components/financials/financials.component';
import { PilotProfileNotesComponent } from './components/pilot-profile-notes/pilot-profile-notes.component';
import { MissionFinancialRevenueModule } from 'projects/missions-service/src/lib/relationals/mission-financial-revenue/mission-financial-revenue.module';
import { MissionsServiceModule } from 'missions-service';
import { MissionFinancialExpenseModule } from 'projects/missions-service/src/lib/relational/mission-financial-expense/mission-financial-expense.module';
import { ColumnsModule } from 'projects/flyguys-pilot/src/app/components/columns/columns.module';
import { PilotProfileQuestionnaireComponent } from './components/pilot-profile-questionnaire/pilot-profile-questionnaire.component';
import { PilotProfileDocumentsComponent } from './components/pilot-profile-documents/pilot-profile-documents.component';
import { PilotProfileHistoryComponent } from './components/pilot-profile-history/pilot-profile-history.component';
import { PipesModule } from '../pipes/pipes.module'

@NgModule({
  declarations: [
    PilotSourcingMissionsComponent,
    PilotSourcingProjectsComponent,
    PilotSourcingPortafolioComponent,
    GridComponent,
    PilotSourcingSourcingComponent,
    MissionFlowComponent,
    PilotFinderComponent,
    MissionInfoBadgeComponent,
    CapturesComponent,
    DeliverablesComponent,
    PilotInfoComponent,
    PilotInfoCardComponent,
    PilotProfileOverviewComponent,
    EquipmentsComponent,
    OverallRatingComponent,
    RecordsComponent,
    OrderRequestComponent,
    ClientInformationComponent,
    FilesDataComponent,
    NotesComponent,
    TimelineComponent,
    PilotProfileNotesComponent,
    FinancialsComponent,
    PilotProfileQuestionnaireComponent,
    PilotProfileDocumentsComponent,
    PilotProfileHistoryComponent,
  ],
  imports: [
    AgGridModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    MaterialSharedModule,
    PageModule,
    MatCardModule,
    MatTabsModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    SharedModule,
    FormsModule,
    MissionFinancialRevenueModule,
    MissionFinancialExpenseModule,
    MissionsServiceModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    ColumnsModule,
    PipesModule,
  ],
  exports: [
    PilotSourcingMissionsComponent,
    PilotSourcingProjectsComponent,
    PilotSourcingPortafolioComponent,
    GridComponent,
  ],
})
export class PilotSourcingModule { }
