import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleService {
  private isToggled = new BehaviorSubject<boolean>(false);

  get isToggled$() {
    return this.isToggled.asObservable();
  }

  get state() {
    return this.isToggled.value;
  }

  toggle() {
    // Let the rest of the UI that the window has changed size because of the
    // menu collapsing/expanding. This helps the tables to take all the width.
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    this.isToggled.next(!this.isToggled.value);
  }

  show() {
    this.isToggled.next(false);
  }

  hide() {
    this.isToggled.next(true);
  }
}
