<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="site-address"> {{ mission.missionName }} </span>
      <span class="site-number">Site {{ mission.number }}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="site-information container p-0">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="site-info-group">
          <h3>Mission Name</h3>
          <p>{{ mission.missionName }}</p>
        </div>

        <div class="site-info-group">
          <h3>Mission Summary</h3>
          <p>{{ mission.missionSummary }}</p>
        </div>

        <h2>Capture Date & Time</h2>

        <div class="site-info-group d-inline-block mtext-break w-33">
          <h3>Capture Date</h3>
          <p>{{ mission.captureDate | date }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Capture Time</h3>
          <p>{{ mission.captureTime || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block w-33">
          <h3>Repeats</h3>
          <p>{{ mission.frequencyDescription || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <!-- This should be an enum. Hardcoding value from order-site.component -->
          <h3>Capture Date Must Be</h3>
          <p>{{ mission.captureDateMustId === '1' ? 'On Selected Date' : 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block w-33">
          <h3>Flexible Capture Time</h3>
          <p>{{ mission.flexibleCaptureId === '1' ? 'Yes' : 'No' }}</p>
        </div>

        <h2>Deliverables</h2>

        <div class="site-info-group">
          <h3>Deliverable Due Date</h3>
          <p>{{ mission.deliveryDate | date }}</p>
        </div>

        <div class="site-info-group">
          <h3>Capture Deliverables</h3>
          <p>{{ mission.deliveryAllSameTime ? 'Yes' : 'No' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Deliverable Notes</h3>
          <p>{{ mission.deliveryNotes || 'Not provided' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Uploading Data Instructions</h3>
          <p>{{ mission.uploadingDataInstruction || 'Not provided' }}</p>
        </div>

        <h2>Site Contact Information</h2>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Name</h3>
          <p>{{ mission.siteContactName || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Last Name</h3>
          <p>{{ mission.siteContactLastName || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Email</h3>
          <p>{{ mission.siteContactEmail || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Phone Number</h3>
          <p>{{ mission.siteContactPhone || 'Not provided' }}</p>
        </div>

        <div class="site-info-group" *ngIf="hasPreferredContactMethod()">
          <h3>Preferred Contact Method</h3>
          <p *ngIf="mission.preferredContactEmail">Email</p>
          <p *ngIf="mission.preferredContactPhone">Phone</p>
        </div>

        <div class="site-info-group" *ngIf="hasLevelOfCoordination()">
          <h3>Level Of Coordination</h3>
          <p *ngIf="mission.levelCoordinationNotify">Notify</p>
          <p *ngIf="mission.levelCoordinationMeet">Meet</p>
          <p *ngIf="mission.levelCoordinationCoordinate">Coordinate</p>
        </div>

        <h2>Location</h2>

        <div class="site-info-group">
          <h3>Address</h3>
          <p>{{ mission.locAddress || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>Country</h3>
          <p>{{ mission.locCountryDescription || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>State</h3>
          <p>{{ mission.locStateDescription || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>City</h3>
          <p>{{ mission.locCity || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>Zip Code</h3>
          <p>{{ mission.locZipCode || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Latitude</h3>
          <p>{{ mission.loclatitude || 'Not provided' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Longitude</h3>
          <p>{{ mission.loclongitude || 'Not provided' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Airspace Classification</h3>
          <p>{{ mission.locAirSpaceClasfDescription || 'Not provided' }}</p>
        </div>

        <!-- @TODO enable this part when we have attachments
        <div class="site-info-group">
          <h3>Attach reference documentation (KML, Sample Images, Authorization forms, other)</h3>
        </div> 
        -->

        <div class="site-info-group">
          <h3>Additional Notes</h3>
          <p>{{ mission.aditionalNotes || 'Not provided' }}</p>
        </div>

        <flg-map></flg-map>
      </div>
    </div>
  </div>
</mat-expansion-panel>
