import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetMissionPilotRequestStateInput, MissionPilotRequestStatusCreateDto, MissionPilotRequestStatusDto, MissionPilotRequestStatusExcelDownloadDto, MissionPilotRequestStatusUpdateDto } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MissionPilotRequestStatusService {
  apiName = 'CoreService';
  

  create = (input: MissionPilotRequestStatusCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotRequestStatusDto>({
      method: 'POST',
      url: '/api/core-service/mission-pilot-request-state',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/mission-pilot-request-state/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotRequestStatusDto>({
      method: 'GET',
      url: `/api/core-service/mission-pilot-request-state/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/mission-pilot-request-state/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetMissionPilotRequestStateInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionPilotRequestStatusDto>>({
      method: 'GET',
      url: '/api/core-service/mission-pilot-request-state',
      params: { filterText: input.filterText, description: input.description, state: input.state, migrated: input.migrated, idOld: input.idOld, code: input.code, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: MissionPilotRequestStatusExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/mission-pilot-request-state/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: MissionPilotRequestStatusUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotRequestStatusDto>({
      method: 'PUT',
      url: `/api/core-service/mission-pilot-request-state/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
