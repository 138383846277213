<div class="grid-container themeClass">
  <div class="checkbox-container themeClass" [hidden]="!showColumnOptions">
    <h3>Column Visibility</h3>
    <div class="checkbox-options">
      <div *ngFor="let column of originalcolumnDefs">
        <label>
          <input type="checkbox" [(ngModel)]="this.columnVisibility[column.field]"
            (change)="updateColumnVisibility(column.field, this.columnVisibility[column.field])">
          {{ column.field }}
        </label>
      </div>
    </div>
    <button class="hide-button" (click)="toggleColumnOptions()">Hide</button>
  </div>
  <div class="toggle-button" [hidden]="showColumnOptions" (click)="toggleColumnOptions()">
    <i class="arrow-icon"></i>
  </div>
  <div class="grid-wrapper">

    <ag-grid-angular style="width: 950px; height: 350px" [ngClass]="themeClass" [rowData]="rowData"
      [columnDefs]="columnDefs" [getRowId]="getRowId" [rowSelection]="'multiple'" (columnMoved)="onColumnMoved($event)"
      [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>