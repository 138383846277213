<ol class="breadcrumb mb-0 ps-0">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let isLast = last">
    <li class="breadcrumb-item" [class.fw-semibold]="isLast">
      <span style="font-weight: normal;" *ngIf="breadcrumb.subText;" >
        {{ breadcrumb.subText }} 
      </span>
      <a *ngIf="breadcrumb.link; else noLinkTemplate" [routerLink]="breadcrumb.link">
        <i *ngIf="breadcrumb.text === 'Orders'" class="ri-home-8-line"></i>
        {{ breadcrumb.text }}
      </a>
      
      <ng-template #noLinkTemplate>
        {{ breadcrumb.text }}
      </ng-template>
      
    </li>
  </ng-container>
</ol>
