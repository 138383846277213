import { ABP } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigurationAttributeTypesService, ConfigurationTypesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { GetConfigurationAttributeTypeInput, GetConfigurationTypeInput, enumState } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss']
})
export class TablePaginatorComponent implements OnInit {
  @Input() maxResultCount:number;
  @Output() maxResultCountChange = new EventEmitter<number>();
  @Input() clientMaxResultCount:number;
  @Output() clientMaxResultCountChange = new EventEmitter<number>();
  @Input() rowCount:number;
  @Input() pageSize:number;
  @Input() pageNumber:number;
  @Output() pageNumberChange = new EventEmitter<any>();
  valuesMaxResultCount:number[];
  @Input() curPage:number;
  @Input() dataTable:any;

  private readonly PAGE_SIZE_ATTR = "PageSize";

  constructor(
    public readonly configurationTypesService:ConfigurationTypesService,
    public readonly configurationAttributeTypesService:ConfigurationAttributeTypesService
  ) {}

  ngOnInit(): void {
    this.getPageAttributeValues();
  }

  private getPageAttributeValues(): void {

    const query = { } as ABP.PageQueryParams;
    const configurationTypeFilter = { state : enumState.Enabled, code : this.PAGE_SIZE_ATTR } as GetConfigurationTypeInput;

    this.configurationTypesService.getList({
      ...query,
      ...configurationTypeFilter,
      filterText: query.filter,
    })
    .pipe(
      switchMap(result => {

        let configuration = result.items.find( _ => true);
        let configurationAttributeTypeFilter = {configurationTypeId: configuration.id } as GetConfigurationAttributeTypeInput
        return this.configurationAttributeTypesService.getList({
          ...query,
          ...configurationAttributeTypeFilter,
          filterText: query.filter,
        })
      }))
    .subscribe((res) => {

      let attribute = res.items.find( _ => true );
      this.valuesMaxResultCount =  attribute.description.split(',').map(val => parseInt(val, 10));
    });
  }

  pageSizeSelected() {
    this.clientMaxResultCountChange.emit(this.clientMaxResultCount);
    this.maxResultCountChange.emit(this.clientMaxResultCount);
    this.pageNumberChange.emit(0);
  }
}
