import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ABP, ListService, PagedResultDto, EnvironmentService } from '@abp/ng.core';
import { eFileManagementPolicyNames } from '@volo/abp.ng.file-management/config';
import { finalize, tap } from 'rxjs/operators';
import {
  CreateDirectoryInput,
  CreateFileInputWithStream,
  DirectoryContentRequestInput,
  DirectoryDescriptorService,
  FileDescriptorService,
} from 'projects/file-management/proxy/src/lib';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { ColumnAction } from '../../../components/columns/components/column-actions/column-actions.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilePreviewModel } from 'projects/file-management/src/lib/models/file-preview.model';

import { UploadService } from 'projects/file-management/src/lib/services/upload.service';
import { PilotFilesService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import {
  GetPilotFileInput,
  PilotFilesCreateDto,
} from 'projects/pilots-service/src/lib/proxy/pilots-service/relationals';
import { enumState } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../components/common/confirm-dialog/confirm.dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileTypes } from '../../../enums/wizard';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { BannerEventType, BannerService } from '../../../components/banner/banner.service';
import { ChunkUploaderService } from 'projects/file-management/src/lib/services/chunk-uploader.service';
import { PilotSourcingService } from '../../../services/pilot-sourcing.service';
import { PilotDocument } from '../../models/pilot-sourcing-dto';

@Component({
  selector: 'app-pilot-profile-documents',
  templateUrl: './pilot-profile-documents.component.html',
  providers: [ListService, UploadService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  styleUrls: ['./pilot-profile-documents.component.scss'],
})
export class PilotProfileDocumentsComponent implements OnInit {
  @ViewChild('fileModal') modal: TemplateRef<any>;
  @ViewChild('editFileModal') editFileModal: TemplateRef<any>;
  @ViewChild('removeFileModal') removeFileModal: TemplateRef<any>;
  @Input() pilotId: string;
  @Input() pilot: PilotDto;

  files: PagedResultDto<PilotDocument> = {
    items: [],
    totalCount: 0,
  };

  public filePreviewItems: Array<FilePreviewModel> = [];

  filePreview: boolean = true;

  uploadFile: File;

  userDict: { [id: string]: string } = {};

  isModalCreateBusy = false;

  isModalCreateOpen = false;

  isModalEditBusy = false;

  isModalEditOpen = false;

  form: FormGroup;

  directoryId: string;

  fileCreatePermission = eFileManagementPolicyNames.FileDescriptorCreate;
  buttonId = 'upload-files-btn';
  apiName = 'pilotsService';

  currentProgress = 0;

  constructor(
    public readonly list: ListService,
    public readonly directoryService: DirectoryDescriptorService,
    public readonly fileDescriptorService: FileDescriptorService,
    public readonly identityService: IdentityUserService,
    public readonly pilotFilesService: PilotFilesService,
    private fb: FormBuilder,
    private environment: EnvironmentService,
    private dialog: MatDialog,
    private readonly _snackBar: MatSnackBar,
    public readonly bannerService: BannerService,
    public readonly _chunkUploadService: ChunkUploaderService,
    private readonly _pilotSourcingService: PilotSourcingService,
  ) {}

  ngOnInit() {
    this.bannerService.eventEmitter.subscribe(res => {
      if (res.type == BannerEventType.PilotAgreement) {
        this.pilot = res.data as PilotDto;
      }
    });

    this.createFolderIfNoExists();

    this.identityService.getList({ maxResultCount: 10 }).subscribe(res => {
      for (let user of res.items) {
        this.userDict[user.id] = user.name;
      }
    });

    this.buildForm();
  }

  private createFolderIfNoExists(): void {
    let exists = false;
    let directory = { name: this.pilotId } as CreateDirectoryInput;

    this.directoryService.checkExistence(this.pilotId).subscribe({
      next: response => {
        exists = true;
        this.directoryId = response.id;
        this.setDirectoryRootValues();
      },
      error: error => console.error('Unable to check if folder exists:\n', error),
      complete: () => {
        if (!exists) {
          this.directoryService.create(directory).subscribe({
            next: response => {
              this.directoryId = response.id;
              this.setDirectoryRootValues();
            },
            error: error => console.error('Unable to create folder:\n', error),
          });
        }
      },
    });
  }

  private setDirectoryRootValues(): void {
    var filters = {
      isPaginated: true,
      pilotId: this.pilotId,
      state: enumState.Enabled,
    } as GetPilotFileInput;

    const getData = (query: ABP.PageQueryParams) => {
      return this._pilotSourcingService.getFiles({
        ...query,
        ...filters,
      });
    };

    const setData = (list: PagedResultDto<PilotDocument>) => {
      this.files.items = list.items;
      this.files.totalCount = list.totalCount;
      this.filePreviewItems = [];

      const filterDirectory = { id: this.directoryId } as DirectoryContentRequestInput;
      this.directoryService.getContent(filterDirectory).subscribe(res => {
        this.filePreviewItems = [];
        res.items.forEach(element => {
          let file = new FilePreviewModel(
            element.name,
            element.imagePreviewUrl,
            element.videoPreviewUrl,
            element.fileContentPreviewUrl,
            element.thumbnailUrl,
            element,
          );
          if (file.previewSupported) {
            this.filePreviewItems.push(file);
          }
        });
      });
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  columnActions(record: PilotDocument) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'edit',
        abpPermission: eFileManagementPolicyNames.DirectoryDescriptorUpdate,
        action: { callAction: () => this.update(record) },
      },
      {
        actionIcon: 'delete',
        abpPermission: eFileManagementPolicyNames.DirectoryDescriptorDelete,
        action: { callAction: () => this.delete(record) },
      },
      {
        actionIcon: 'download',
        abpPermission: eFileManagementPolicyNames.DirectoryDescriptor,
        action: { callAction: () => this.download(record) },
      },
    ];

    return columnActions;
  }

  update(record: PilotDocument) {
    this.showEditForm(record);
  }

  delete(record: PilotDocument) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this file?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    ref.afterClosed().subscribe({
      next: (shouldDelete: boolean) => {
        if (shouldDelete) {
          this.fileDescriptorService.delete(record.fileId).subscribe(() => {
            const filters = {
              isPaginated: true,
              pilotId: this.pilotId,
              fileId: record.fileId,
            } as GetPilotFileInput;

            this.pilotFilesService.getList(filters).subscribe(res => {
              for (let item of res.items) {
                this.pilotFilesService.delete(item.id).subscribe(this.list.get);
              }
            });
          });
        }
      },
    });
  }

  download(record: PilotDocument) {
    console.log(record);
    return this.fileDescriptorService.getDownloadToken(record.fileId).subscribe(res => {
      console.log(res);
      window.open(
        `${this.environment.getApiUrl(
          'FileManagement',
        )}/api/file-management/file-descriptor/download/${record.fileId}?token=${res.token}`,
        '_self',
      );
    });
  }

  hideForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showForm() {
    this.dialog.open(this.modal, {
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });

    this.isModalCreateOpen = true;
  }

  buildForm() {
    this.form = this.fb.group({
      file: [null, [Validators.required]],
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let uploadedFile = event.target.files[0];
      let fileParts = uploadedFile.name.split('.');
      let mimeType: any = fileParts[fileParts.length - 1].toLowerCase();
      if (!Object.values(FileTypes).includes(mimeType)) {
        this._snackBar.open(`The file type ${mimeType} is not valid`, '', { duration: 2000 });
        return false;
      }
      this.uploadFile = uploadedFile;
    }
  }

  private generateFormData(file: File | null) {
    if (!file) {
      return null;
    }
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  submitCreateForm() {
    if (this.form.invalid || !this.directoryId) return;
    this.currentProgress = 0;

    const request = this._chunkUploadService.uploadFile(this.uploadFile, this.directoryId);

    this.isModalCreateBusy = true;

    request.subscribe(res => {
      this.currentProgress = res.currentProgress;
      if (res.currentProgress == 100 && res.fileId != undefined) {
        var createPilotFile = {
          pilotId: this.pilotId,
          fileId: res.fileId,
          state: enumState.Enabled,
        } as PilotFilesCreateDto;
        this.pilotFilesService
          .create(createPilotFile)
          .pipe(
            finalize(() => (this.isModalCreateBusy = false)),
            tap(() => this.hideForm()),
          )
          .subscribe(this.list.get);
      }
    });
  }

  hideEditForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showEditForm(record: PilotDocument) {
    this.buildEditForm(record);
    this.dialog.open(this.editFileModal, {
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });
  }

  buildEditForm(record: PilotDocument) {
    this.form = this.fb.group({
      name: [record.name, [Validators.required]],
      id: [record.fileId, [Validators.required]],
      concurrencyStamp: [record.concurrencyStamp, [Validators.required]],
    });
  }

  submitEditForm() {
    if (this.form.invalid) return;
    const request = this.fileDescriptorService.rename(this.form.value.id, this.form.value);

    this.isModalEditBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalEditBusy = false)),
        tap(() => this.hideEditForm()),
      )
      .subscribe(this.list.get);
  }

  openPilotAgreement() {
    window.open(this.pilot.lastAcceptedAgreementUrl);
  }
}
