import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetMissionPilotInput, MissionPilotsCreateDto, MissionPilotsDto, MissionPilotsExcelDownloadDto, MissionPilotsUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MissionPilotsService {
  apiName = 'missionsService';
  

  create = (input: MissionPilotsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotsDto>({
      method: 'POST',
      url: '/api/missions-service/mission-pilot',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/mission-pilot/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotsDto>({
      method: 'GET',
      url: `/api/missions-service/mission-pilot/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/mission-pilot/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetMissionPilotInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionPilotsDto>>({
      method: 'GET',
      url: '/api/missions-service/mission-pilot',
      params: { filterText: input.filterText, pilotId: input.pilotId, missionId: input.missionId, assigned: input.assigned, assignedDateMin: input.assignedDateMin, assignedDateMax: input.assignedDateMax, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: MissionPilotsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/mission-pilot/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  
    getMissionLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/missions-service/mission-pilot/mission-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });    

  update = (id: string, input: MissionPilotsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionPilotsDto>({
      method: 'PUT',
      url: `/api/missions-service/mission-pilot/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });


  constructor(private restService: RestService) {}
}
