<div class="new-order">
  <!-- @TODO we need a breadcumb component -->
  <p>
    <a routerLink="/orders">Orders</a> /
    <a routerLink="/orders/new" routerLinkActive="active">New Order</a>
  </p>

  <h2>New Order</h2>

  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-4">
        <mat-stepper>
          <mat-step>
            <ng-template matStepLabel
              ><span class="stepTitle">Client Information</span></ng-template
            >
            <ng-template matStepContent>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-11 col-md-11">
                    <form [formGroup]="formStepOne" skipValidation>
                      <h4 class="mt-4 mb-4">Client Information</h4>

                      <h6 class="mt-3 mb-3">Requesting Customer</h6>
                      <mat-form-field class="w-100">
                        <mat-label>Requesting Company Name</mat-label>
                        <input
                          #inputCompanyName
                          type="text"
                          placeholder="Requesting Company Name*"
                          matInput
                          formControlName="customerDescription"
                          [(ngModel)]="this.orderModel.customerDescription"
                          [matAutocomplete]="autCustomer"
                        />
                        <mat-autocomplete
                          #autCustomer="matAutocomplete"
                          (optionSelected)="getCustomer()"
                        >
                          <mat-option
                            *ngFor="let option of filteredCustomers | async"
                            [value]="option"
                            title="{{ option }}"
                            class="autocomplete-dropdown"
                          >
                            {{ option }}
                          </mat-option>
                        </mat-autocomplete>

                        <mat-error
                          *ngIf="formStepOne.get('customerDescription').hasError('required')"
                        >
                          Please enter a value
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-100">
                        <mat-label>Company Project Manager</mat-label>
                        <input
                          #inputCompanyprojectManager
                          type="text"
                          placeholder="Company Project Manager*"
                          matInput
                          formControlName="customerContactDescription"
                          [(ngModel)]="this.orderModel.customerContactDescription"
                          [matAutocomplete]="autContact"
                        />
                        <mat-autocomplete
                          #autContact="matAutocomplete"
                          (optionSelected)="getContact()"
                        >
                          <mat-option
                            *ngFor="let option of filteredContacts | async"
                            [value]="option"
                            title="{{ option }}"
                            class="autocomplete-dropdown"
                          >
                            {{ option }}
                          </mat-option>
                        </mat-autocomplete>

                        <mat-error
                          *ngIf="formStepOne.get('customerContactDescription').hasError('required')"
                        >
                          Please enter a value
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-50 pe-2">
                        <mat-label>Project Manager Email</mat-label>
                        <input
                          type="email"
                          matInput
                          placeholder="Ex. email@domain.com"
                          [(ngModel)]="this.orderModel.customerContactEmail"
                          formControlName="customerContactEmail"
                        />

                        <mat-error
                          *ngIf="
                            formStepOne.get('customerContactEmail').hasError('email') &&
                            !formStepOne.get('customerContactEmail').hasError('required')
                          "
                        >
                          Please enter a valid email
                        </mat-error>
                        <mat-error
                          *ngIf="formStepOne.get('customerContactEmail').hasError('required')"
                        >
                          Please enter a value
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-50">
                        <mat-label>Phone</mat-label>
                        <input
                          matInput
                          placeholder="Phone Number"
                          type="tel"
                          [(ngModel)]="this.orderModel.customerContactNumber"
                          formControlName="customerContactNumber"
                          minlength="10"
                          pattern="[0-9]*"
                          digitOnly
                          placeholder="0000000000"
                        />
                        <mat-error
                          *ngIf="formStepOne.controls.customerContactNumber.hasError('minlength')"
                        >
                          The phone number must contain at least 10 digits
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-100">
                        <mat-label>Sub Client Name</mat-label>
                        <input
                          #inputCompanyprojectManager
                          type="text"
                          placeholder="SubClient Name"
                          matInput
                          formControlName="subclientName"
                          [(ngModel)]="this.orderModel.subclientName"
                          [matAutocomplete]="autSubscostumer"
                        />
                        <mat-autocomplete
                          #autSubscostumer="matAutocomplete"
                          (optionSelected)="getSubcustomer()"
                        >
                          <mat-option
                            *ngFor="let option of filteredSubcustomers | async"
                            [value]="option"
                            title="{{ option }}"
                            class="autocomplete-dropdown"
                          >
                            {{ option }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <h6 class="mt-3 mb-3">Billing Customer</h6>

                      <mat-form-field class="w-100">
                        <mat-label>Billing Customer Name</mat-label>
                        <input
                          #inputCompanyprojectManager
                          type="text"
                          placeholder="Billing Customer Name*"
                          matInput
                          formControlName="billingCustomerName"
                          [(ngModel)]="this.orderModel.billingCustomerName"
                          [matAutocomplete]="autBilling"
                        />
                        <mat-autocomplete
                          #autBilling="matAutocomplete"
                          (optionSelected)="getBillingCustomer()"
                        >
                          <mat-option
                            *ngFor="let option of filteredBillingcustomer | async"
                            [value]="option"
                            title="{{ option }}"
                            class="autocomplete-dropdown"
                          >
                            {{ option }}
                          </mat-option>
                        </mat-autocomplete>

                        <mat-error
                          *ngIf="formStepOne.get('billingCustomerName').hasError('required')"
                        >
                          Please enter a value
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-50 pe-2">
                        <mat-label>Billing Customer Email</mat-label>
                        <input
                          matInput
                          placeholder="Email"
                          type="email"
                          placeholder="Ex. email@domain.com"
                          [(ngModel)]="this.orderModel.billingCustomerContactEmail"
                          formControlName="billingCustomerContactEmail"
                        />
                        <mat-error
                          *ngIf="
                            formStepOne.get('billingCustomerContactEmail').hasError('email') &&
                            !formStepOne.get('billingCustomerContactEmail').hasError('required')
                          "
                        >
                          Please enter a valid email
                        </mat-error>
                        <mat-error
                          *ngIf="
                            formStepOne.get('billingCustomerContactEmail').hasError('required')
                          "
                        >
                          Please enter a value
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-50">
                        <mat-label>Billing Customer Phone</mat-label>
                        <input
                          matInput
                          placeholder="Phone Number"
                          type="tel"
                          [(ngModel)]="this.orderModel.billingCustomerConctactNumber"
                          formControlName="billingCustomerConctactNumber"
                          minlength="10"
                          pattern="[0-9]*"
                          digitOnly
                          placeholder="0000000000"
                        />
                        <mat-error
                          *ngIf="
                            formStepOne.controls.billingCustomerConctactNumber.hasError('minlength')
                          "
                        >
                          The phone number must contain at least 10 digits
                        </mat-error>
                      </mat-form-field>

                      <mat-checkbox
                        ><span class="checkbox-label"
                          >Same as requesting customer</span
                        ></mat-checkbox
                      >

                      <div class="d-flex justify-content-end mt-3">
                        <h6 class="mb-3 me-auto">Additional Contacts</h6>
                        <button
                          mat-flat-button
                          class="button-addcontact"
                          type="button"
                          (click)="handleAddContact()"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <mask
                              id="mask0_3550_15827"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="20"
                              height="20"
                            >
                              <rect width="20" height="20" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3550_15827)">
                              <path
                                d="M9.5835 15.4166V10.4166H4.5835V9.58325H9.5835V4.58325H10.4168V9.58325H15.4168V10.4166H10.4168V15.4166H9.5835Z"
                                fill="#bc5a2e"
                              />
                            </g>
                          </svg>
                          <span class="button-text">Add Contact</span>
                        </button>
                      </div>

                      <app-order-contact-table
                        [contacts]="orderModel.additionalContacts"
                        [columns]="columns"
                        (removecontact)="handleRemoveContact($event)"
                        (editcontact)="handleEditContact($event)"
                      ></app-order-contact-table>

                      <div class="d-flex justify-content-end mt-3">
                        <button mat-flat-button class="me-2" type="button" (click)="discardOrder()">
                          Discard
                        </button>
                        <button
                          mat-flat-button
                          color="primary"
                          class="next-button"
                          matStepperNext
                          type="button"
                          [disabled]="!isStepValid(1)"
                          [ngClass]="{ 'step-disabled-button': !isStepValid(1) }"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel><span class="stepTitle">Order Request</span></ng-template>
            <ng-template matStepContent>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-11 col-md-11">
                    <h3 class="mt-4 mb-4">Order Request</h3>
                    <form [formGroup]="formStepTwo" skipValidation>
                      <div *ngFor="let site of refreshSites">
                        <app-order-site [site]="site" #orderMission></app-order-site>
                      </div>

                      <div class="mt-3 mb-3 w-100 me-2">
                        <button mat-flat-button class="button-addsite" type="button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <mask
                              id="mask0_3588_23314"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3588_23314)">
                              <path
                                d="M11.5 18.5V12.5H5.5V11.5H11.5V5.5H12.5V11.5H18.5V12.5H12.5V18.5H11.5Z"
                                fill="#bc5a2e"
                              />
                            </g>
                          </svg>
                          <span class="button-text me-2" (click)="AddNewSite()">Add New Site</span>
                        </button>
                        <span class="flex-spacer"></span>
                        <mat-divider horizontal></mat-divider>
                      </div>
                      <h6 class="mt-3 mb-3">Additional Details</h6>
                      <div class="d-flex justify-content-end mt-3">
                        <mat-form-field class="w-50 pe-2">
                          <mat-label>Order Priority</mat-label>
                          <mat-select
                            name="orderPriority"
                            [(ngModel)]="this.orderModel.priorityId"
                            (ngModelChange)="
                              setDescriptionFromId(
                                this.orderModel.priorityId,
                                dataPriorities.items,
                                'priorityDescription',
                                'description'
                              )
                            "
                            formControlName="priorityId"
                          >
                            <mat-option
                              *ngFor="let dp of dataPriorities.items"
                              [value]="dp.id"
                              title="{{ dp.description }}"
                            >
                              {{ dp.description }}
                            </mat-option>
                          </mat-select>

                          <mat-error *ngIf="formStepTwo.get('priorityId').hasError('required')">
                            Please enter a value
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-50">
                          <mat-label>Order Industry</mat-label>
                          <mat-select
                            name="orderIndustry"
                            [(ngModel)]="this.orderModel.industryid"
                            (ngModelChange)="
                              setDescriptionFromId(
                                this.orderModel.industryid,
                                dataIndustries.items,
                                'industryDescription',
                                'description'
                              )
                            "
                            formControlName="industryid"
                          >
                            <mat-option
                              *ngFor="let di of dataIndustries.items"
                              [value]="di.id"
                              title="{{ di.description }}"
                            >
                              {{ di.name }}
                            </mat-option>
                          </mat-select>

                          <mat-error *ngIf="formStepTwo.get('industryid').hasError('required')">
                            Please enter a value
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="d-flex justify-content-end mt-3">
                        <button
                          mat-flat-button
                          class="button-addcontact mb-3 me-auto"
                          matStepperPrevious
                          type="button"
                        >
                          <span class="button-text">Back</span>
                        </button>
                        <button mat-flat-button class="me-2" type="button" (click)="discardOrder()">
                          Discard
                        </button>
                        <button
                          mat-flat-button
                          color="primary"
                          class="next-button"
                          matStepperNext
                          type="button"
                          [disabled]="!isStepValid(2)"
                          [ngClass]="{ 'step-disabled-button': !isStepValid(2) }"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel><span class="stepTitle">Deliverables</span></ng-template>
            <ng-template matStepContent>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-11 col-md-11">
                    <form [formGroup]="formStepFour" skipValidation>
                      <h3 class="mt-4 mb-4">Deliverables</h3>

                      <div *ngFor="let pr of this.orderModel.products">
                        <app-product [product]="pr"></app-product>
                      </div>

                      <h6 class="mt-3 mb-3">Additional Deliverables</h6>

                      <div *ngFor="let ad of refreshDeliverables" class="col-lg-12 col-md-12">
                        <div class="col-lg-10 col-md-10">
                          <app-deliverable [deliverable]="ad"></app-deliverable>
                        </div>
                        <div class="col-lg-2 col-md-2">
                          <button
                            type="button"
                            class="btn btn-link me-2"
                            (click)="removeDeliverable(row)"
                          >
                            <span class="material-symbols-rounded"> delete </span>
                          </button>
                        </div>
                      </div>

                      <div class="mt-3 mb-3 w-100 me-2">
                        <button mat-flat-button class="button-addsite" type="button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <mask
                              id="mask0_3799_5337"
                              style="mask-type: alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3799_5337)">
                              <path
                                d="M11.5 18.5V12.5H5.5V11.5H11.5V5.5H12.5V11.5H18.5V12.5H12.5V18.5H11.5Z"
                                fill="black"
                                fill-opacity="0.38"
                              />
                            </g>
                          </svg>
                          <span class="button-text me-2" (click)="AddNewDeliverable()"
                            >Add New Delirable</span
                          >
                        </button>
                        <span class="flex-spacer"></span>
                        <mat-divider horizontal></mat-divider>
                      </div>

                      <div class="d-flex justify-content-end mt-3">
                        <button
                          mat-flat-button
                          class="button-addcontact mb-3 me-auto"
                          matStepperPrevious
                          type="button"
                        >
                          <span class="button-text">Back</span>
                        </button>
                        <button mat-flat-button class="me-2" type="button" (click)="discardOrder()">
                          Discard
                        </button>
                        <button
                          mat-flat-button
                          color="primary"
                          class="next-button"
                          matStepperNext
                          type="button"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel><span class="stepTitle">Confirmation</span></ng-template>
            <ng-template matStepContent>
              <div class="container">
                <div class="row">
                  <div class="col-lg-11 col-md-11">
                    <h3 class="mt-4 mb-4">Confirmation</h3>
                    <h6 class="mt-4 mb-4">Client Details</h6>

                    <h4 class="mt-3 mb-3">Requesting Customer</h4>
                    <hgroup>
                      <h5>Company Name</h5>
                      <p>
                        {{ this.orderModel.customerDescription }}
                      </p>
                    </hgroup>

                    <hgroup>
                      <h5>Project Manager</h5>
                      <p>
                        {{ this.orderModel.customerContactDescription }}
                      </p>
                    </hgroup>

                    <div class="input-group justify-content-between">
                      <hgroup class="pe-2 flex-grow-1">
                        <h5>Project Manager Email</h5>
                        <p>
                          {{ this.orderModel.customerContactEmail }}
                        </p>
                      </hgroup>

                      <hgroup class="pe-2 flex-grow-1">
                        <h5>Project Manager Phone</h5>
                        <p>{{ this.orderModel.customerContactNumber }}</p>
                      </hgroup>
                    </div>

                    <hgroup>
                      <h5>Sub Client Name</h5>
                      <p>{{ this.orderModel.subclientName }}</p>
                    </hgroup>

                    <h4 class="mt-3 mb-3">Billing Customer</h4>

                    <hgroup>
                      <h5>Billing Customer Name</h5>
                      <p>{{ this.orderModel.billingCustomerName }}</p>
                    </hgroup>

                    <div class="input-group justify-content-between">
                      <hgroup class="pe-2 flex-grow-1">
                        <h5>Billing Customer Email</h5>
                        <p>{{ this.orderModel.billingCustomerContactEmail }}</p>
                      </hgroup>

                      <hgroup class="pe-2 flex-grow-1">
                        <h5>Billing Customer Phone</h5>
                        <p>{{ this.orderModel.billingCustomerConctactNumber }}</p>
                      </hgroup>
                    </div>

                    <h4 class="mt-3 mb-3">Contacts</h4>

                    <app-order-contact-table
                      [contacts]="this.orderModel.additionalContacts"
                      [columns]="columns"
                      (removecontact)="handleRemoveContact($event)"
                      (editcontact)="handleEditContact($event)"
                    ></app-order-contact-table>

                    <h4 class="mt-3 mb-3">Order Request</h4>

                    <div class="input-group justify-content-between">
                      <hgroup class="pe-2 flex-grow-1">
                        <h5>Order Priority</h5>
                        <p>{{ this.orderModel.priorityDescription }}</p>
                      </hgroup>

                      <hgroup class="pe-2 flex-grow-1">
                        <h5>Order Industry</h5>
                        <p>{{ this.orderModel.industryDescription }}</p>
                      </hgroup>
                    </div>

                    <h4 class="mt-3 mb-3">Mission list</h4>

                    <app-mission-overview
                      *ngFor="let st of this.orderModel.orderSites"
                      [mission]="st"
                    ></app-mission-overview>

                    <h4 class="mt-3 mb-3">Deliverables</h4>
                    <div *ngFor="let pr1 of this.orderModel.products">
                      <app-product [product]="pr1"></app-product>
                    </div>

                    <h4 class="mt-3 mb-3">Additional Deliverables</h4>
                    <div
                      *ngFor="let ad of this.orderModel.additionalDeliverables"
                      class="col-lg-12 col-md-12"
                    >
                      <app-deliverable [deliverable]="ad"></app-deliverable>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                      <button
                        mat-flat-button
                        class="button-addcontact mb-3 me-auto"
                        matStepperPrevious
                        type="button"
                      >
                        <span class="button-text">Back</span>
                      </button>
                      <button mat-flat-button class="me-2" type="button" (click)="discardOrder()">
                        Discard
                      </button>
                      <button
                        mat-flat-button
                        color="primary"
                        class="next-button"
                        matStepperNext
                        type="button"
                        (click)="saveAll()"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>
