<form [formGroup]="formDelirable">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-11 col-md-11">
                <h3 class="mt-4 mb-4">{{this.deliverable.deliverableName}}</h3>
                <div class="d-flex justify-content-end mt-3">
                    <mat-form-field class="w-50 pe-2">
                        <mat-label>Altitude*</mat-label>
                        <input matInput placeholder="Altitude" [(ngModel)]="this.deliverable.altitude"
                            formControlName="altitude" />
                    </mat-form-field>
                    <mat-form-field class="w-50 pe-2">
                        <mat-label>Gimbal angle</mat-label>
                        <input matInput placeholder="Gimbal angle" [(ngModel)]="this.deliverable.grimbalangle"
                            formControlName="grimbalangle" />
                    </mat-form-field>
                    <mat-form-field class="w-50">
                        <mat-label>Overlap</mat-label>
                        <input matInput placeholder="Overlap" [(ngModel)]="this.deliverable.overlap"
                            formControlName="overlap" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

</form>