import { recurrenceEnum } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
export class OrderFormSiteModel {
  number: string = '';
  missionName: string = '';
  missionSummary: string = '';
  captureDate: Date = null;
  captureTime: string = null;
  frequencyId: string = "";
  frequencyDescription: string = '';
  captureDateMustId: string = '';
  captureDateMustDescription: string = '';
  flexibleCaptureId: string = '';
  flexibleCaptureDescription: string = '';
  deliveryDate: string = '';
  deliveryAllSameTime: Boolean = false;
  deliveryNotes: string = '';
  uploadingDataInstruction: string = '';
  siteContactName: string = '';
  siteContactLastName: string = '';
  siteContactEmail: string = '';
  siteContactPhone: string = '';
  preferredContactEmail: Boolean = false;
  preferredContactPhone: Boolean = false;
  levelCoordinationNotify: Boolean = false;
  levelCoordinationMeet: Boolean = false;
  levelCoordinationCoordinate: Boolean = false;
  locAddress: string = '';
  locCountryId: string = '';
  locCountryDescription: string = '';
  locStateId: string = '';
  locStateDescription: string = '';
  locCity: string = '';
  locZipCode: string = '';
  loclatitude: string = '';
  loclongitude: string = '';
  geoFenceRadius: string = '';
  projectName: string = '';
  locAirSpaceClasfId: string = '';
  locAirSpaceClasfDescription: string = '';
  aditionalNotes: string = '';
}
