import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let phoneNumbers = value.match(/\d/g);
    if(phoneNumbers.length < 10) {
      return value;
    }
    let phoneNumber= "";
    let auxCount = 0;
    for(let i = phoneNumbers.length - 1; i >= 0; i--) {
      if(auxCount === 4){
        phoneNumber = `-${phoneNumber}`;
      }
      if(auxCount === 7){
        phoneNumber = `-${phoneNumber}`;
      }
      if(auxCount === 10){
        phoneNumber = `) ${phoneNumber}`;
      }
      phoneNumber = phoneNumbers[i] + phoneNumber;
      auxCount++;
    }
    phoneNumber = `(+${phoneNumber}`

    return phoneNumber.length >= 13 ? phoneNumber : value;
  }

}
