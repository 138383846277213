import { NgModule } from '@angular/core';
import { DynamicLayoutComponent } from '@abp/ng.core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DynamicLayoutComponent,
    children: [],
  },
  {
    path: 'note',
    loadChildren: () => import('./basics/notes/notes.module').then(m => m.NotesModule),
  },
  {
    path: 'location',
    loadChildren: () => import('./basics/locations/locations.module').then(m => m.LocationsModule),
  },
  {
    path: 'address',
    loadChildren: () => import('./basics/addresses/addresses.module').then(m => m.AddressesModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./basics/contacts/contacts.module').then(m => m.ContactsModule),
  },
  {
    path: 'badge',
    loadChildren: () => import('./basics/badges/badges.module').then(m => m.BadgesModule),
  },
  {
    path: 'credential',
    loadChildren: () =>
      import('./basics/credentials/credentials.module').then(m => m.CredentialsModule),
  },
  {
    path: 'deliverable',
    loadChildren: () =>
      import('./basics/deliverables/deliverables.module').then(m => m.DeliverablesModule),
  },
  {
    path: 'equipment',
    loadChildren: () =>
      import('./basics/equipments/equipments.module').then(m => m.EquipmentsModule),
  },
  {
    path: 'service',
    loadChildren: () => import('./basics/services/services.module').then(m => m.ServicesModule),
  },
  {
    path: 'mission-quality-questionnarie',
    loadChildren: () =>
      import('./basics/mission-quality-questionnaries/mission-quality-questionnaries.module').then(
        m => m.MissionQualityQuestionnariesModule
      ),
  },
  {
    path: 'question',
    loadChildren: () => import('./basics/questions/questions.module').then(m => m.QuestionsModule),
  },
  {
    path: 'response',
    loadChildren: () => import('./basics/responses/responses.module').then(m => m.ResponsesModule),
  },
  {
    path: 'location-contact',
    loadChildren: () =>
      import('./basics/location-contacts/location-contacts.module').then(
        m => m.LocationContactsModule
      ),
  },
  {
    path: 'mission',
    loadChildren: () => import('./basics/missions/missions.module').then(m => m.MissionsModule),
  },
  {
    path: 'portafolio-contact',
    loadChildren: () =>
      import('./basics/portafolio-contacts/portafolio-contacts.module').then(
        m => m.PortafolioContactsModule
      ),
  },
  {
    path: 'portafolio',
    loadChildren: () =>
      import('./basics/portafolios/portafolios.module').then(m => m.PortafoliosModule),
  },
  {
    path: 'project',
    loadChildren: () => import('./basics/projects/projects.module').then(m => m.ProjectsModule),
  },
  {
    path: 'capture',
    loadChildren: () => import('./basics/captures/captures.module').then(m => m.CapturesModule),
  },
  {
    path: 'flight-deliverable',
    loadChildren: () =>
      import('./basics/flight-deliverables/flight-deliverables.module').then(
        m => m.FlightDeliverablesModule
      ),
  },
  {
    path: 'mission-deliverable',
    loadChildren: () =>
      import('./basics/mission-deliverables/mission-deliverables.module').then(
        m => m.MissionDeliverablesModule
      ),
  },
  {
    path: 'order-detail',
    loadChildren: () =>
      import('./basics/order-details/order-details.module').then(m => m.OrderDetailsModule),
  },
  {
    path: 'mission-pilot-notification',
    loadChildren: () =>
      import('./basics/mission-pilot-notifications/mission-pilot-notifications.module').then(
        m => m.MissionPilotNotificationsModule
      ),
  },
  {
    path: 'mission-pilot-rating-note',
    loadChildren: () =>
      import('./basics/mission-pilot-rating-notes/mission-pilot-rating-notes.module').then(
        m => m.MissionPilotRatingNotesModule
      ),
  },
  {
    path: 'mission-pilot-rating',
    loadChildren: () =>
      import('./basics/mission-pilot-ratings/mission-pilot-ratings.module').then(
        m => m.MissionPilotRatingsModule
      ),
  },
  {
    path: 'deliverable-service',
    loadChildren: () =>
      import('./relationals/deliverable-services/deliverable-services.module').then(
        m => m.DeliverableServicesModule
      ),
  },
  {
    path: 'product-deliverable',
    loadChildren: () =>
      import('./relationals/product-deliverables/product-deliverables.module').then(
        m => m.ProductDeliverablesModule
      ),
  },
  {
    path: 'service-badge',
    loadChildren: () =>
      import('./relationals/service-badges/service-badges.module').then(m => m.ServiceBadgesModule),
  },
  {
    path: 'service-credential',
    loadChildren: () =>
      import('./relationals/service-credentials/service-credentials.module').then(
        m => m.ServiceCredentialsModule
      ),
  },
  {
    path: 'service-equipment',
    loadChildren: () =>
      import('./relationals/service-equipments/service-equipments.module').then(
        m => m.ServiceEquipmentsModule
      ),
  },
  {
    path: 'mission-pilot',
    loadChildren: () =>
      import('./relationals/mission-pilots/mission-pilots.module').then(m => m.MissionPilotsModule),
  },
  {
    path: 'mission-notes',
    loadChildren: () =>
      import('./relationals/mission-note/mission-note.module').then(m => m.MissionNoteModule),
  },
  {
    path: 'templates',
    loadChildren: () => import('./basics/template/template.module').then(m => m.TemplateModule),
  },
  {
    path: 'mission-financial-revenues',
    loadChildren: () =>
      import('./relationals/mission-financial-revenue/mission-financial-revenue.module').then(
        m => m.MissionFinancialRevenueModule
      ),
  },
  {
    path: 'mission-financial-expenses',
    loadChildren: () =>
      import('./relational/mission-financial-expense/mission-financial-expense.module').then(
        m => m.MissionFinancialExpenseModule
      ),
  },
  {
    path: 'packages',
    loadChildren: () => import('./basics/package/package.module').then(m => m.PackageModule),
  },
  {
    path: 'package-details',
    loadChildren: () =>
      import('./relationals/package-detail/package-detail.module').then(m => m.PackageDetailModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MissionsServiceRoutingModule {}
