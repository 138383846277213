<h1 class="fs-16 bold m-0">
  {{ showIndex ? index + 1 + '. ' : '' }}{{ question.title }}
  <span class="required" *ngIf="!question.isOptional">*</span>
</h1>

<ng-container *ngIf="!DisplayInList || (DisplayInList && EditMode)">
  <p *ngIf="question.questionType != 'text' && question.questionType != 'number'">
    {{ question.description }}
  </p>
  <div
    class="mb-2"
    *ngIf="question.questionType == 'text' || question.questionType == 'number'"
  ></div>

  <div *ngIf="question.questionType == 'select'">
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="questionnaire-radio-group"
      [(ngModel)]="question.answer"
    >
      <mat-radio-button *ngFor="let option of question.options" [value]="option">
        {{ option }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="questionnaire-checkbox-group" *ngIf="question.questionType == 'multi-select'">
    <mat-checkbox
      *ngFor="let option of question.options"
      [checked]="question.answer.includes(option)"
      (change)="UpdateMultiSelectAnswer($event.checked, option)"
    >
      {{ option }}
    </mat-checkbox>
  </div>

  <div class="w-100" *ngIf="question.questionType == 'text'">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{ question.description }}</mat-label>
      <textarea matInput [(ngModel)]="question.answer" type="text" style="height: 80px"></textarea>
    </mat-form-field>
  </div>

  <div class="w-100" *ngIf="question.questionType == 'number'">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{ question.description }}</mat-label>
      <input matInput [(ngModel)]="question.answer" type="number" />
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="DisplayInList && !EditMode">
  <p>{{ question.answer == '' ? QuestionnaireLogic.noAnsweredText :  question.answer}}</p>
</ng-container>

<!-- <input type="text" [(ngModel)]="question.answer" /> -->

<ng-container *ngFor="let childQuestion of questionChilds">
  <lib-question-details
    *ngIf="(childQuestion | IsTriggered: question.answer)"
    [QuestionnaireLogic]="QuestionnaireLogic"
    [question]="childQuestion"
    [showIndex]="false"
    [DisplayInList]="DisplayInList"
    [EditMode]="EditMode"
  ></lib-question-details>
</ng-container>
