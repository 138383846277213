<div class="container">
    <div class="row justify-content-center">
        <h5 class="mt-4 mb-4">Add Deliverable</h5>
        <mat-dialog-content class="contact-content">
            <form [formGroup]="formNewDelirable">
                <mat-form-field class="w-100">
                    <mat-label>Deliverable*</mat-label>
                    <mat-select name="deliverableId" [(ngModel)]="this.newdeliverable.deliverableId"
                        (ngModelChange)="setDescriptionFromId(this.newdeliverable.deliverableId,dataDeliverable,'deliverableName','name')"
                        formControlName="deliverableId">
                        <mat-option *ngFor="let data of dataDeliverable" [value]="data.id" title={{data.name}}>
                            {{data.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="d-flex justify-content-end mt-3">
                    <mat-form-field class="w-50 pe-2">
                        <mat-label>Altitude*</mat-label>
                        <input matInput placeholder="Altitude" [(ngModel)]="this.newdeliverable.altitude"
                            formControlName="altitude" pattern="[0-9]*" digitOnly />
                    </mat-form-field>
                    <mat-form-field class="w-50 pe-2">
                        <mat-label>Gimbal angle</mat-label>
                        <input matInput placeholder="Gimbal angle" [(ngModel)]="this.newdeliverable.grimbalangle"
                            formControlName="grimbalangle" pattern="[0-9]*" digitOnly />
                    </mat-form-field>
                    <mat-form-field class="w-50">
                        <mat-label>Overlap</mat-label>
                        <input matInput placeholder="Overlap" [(ngModel)]="this.newdeliverable.overlap"
                            formControlName="overlap" pattern="[0-9]*" digitOnly />
                    </mat-form-field>
                </div>
            </form>
        </mat-dialog-content>
    </div>
    <div class="d-flex justify-content-end mt-3">
        <button mat-flat-button class="button-cancel me-2" type="button" (click)="onClickClose()">
            <span class="button-cancel-text">Cancel</span>
        </button>
        <button mat-flat-button color="primary" class="add-button" type="button" (click)="saveDeliverable()"
            [disabled]="!IsValid()" [class.disabled-button]="!IsValid()">
            Add
        </button>
    </div>
</div>