import { Injectable } from '@angular/core';
import { Rest, RestService } from '@abp/ng.core';
import { DeliverableDto } from '../models/products-deliverables/deliverable-dto';
import { PackageDto } from '../models/products-deliverables/package-dto';
import { DeliverableTypeModel } from '../models/products-deliverables/deliverable-type.model';
import { DeliverableTableDto } from '../models/products-deliverables/deliverableTableDto';

@Injectable({
  providedIn: 'root',
})
export class ProductsDeliverablesService {
  apiName = 'missionsService';
  constructor(private restService: RestService) { }

  getDeliverablesByMission = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/get-deliverables-by-mission-id/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDeliverablesByMission2 = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableTableDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/mission/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAllDeliverablesByMission2 = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableTableDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/all_mission/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getPackagesByMission = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PackageDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/get-packages-by-mission-id/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDeliverableTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableTypeModel[]>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/get-deliverable-types`,
      },
      { apiName: this.apiName, ...config }
    );

  getDeliverableTableByMission = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/mission/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );
}
