import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetPilotsInput,
  PilotAllowedOnPortalDto,
  PilotCreateDto,
  PilotDto,
  PilotExcelDownloadDto,
  PilotUpdateDto,
  TermsAndConditionsDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { PilotPreferredLocationsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Injectable({
  providedIn: 'root',
})
export class PilotService {
  apiName = 'pilotsService';

  create = (input: PilotCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilots',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/pilots-service/pilots/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getProfile = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/profile`,
      },
      { apiName: this.apiName, ...config }
    );

  getIsPilotAllowedOnPortal = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotAllowedOnPortalDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/is-allowed-on-portal`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilots/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetPilotsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotDto>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilots',
        params: {
          filterText: input.filterText,
          firstName: input.firstName,
          lastName: input.lastName,
          pilotExperienceLevelId: input.pilotExperienceLevelId,
          pilotStateId: input.pilotStateId,
          state: input.state,
          rating: input.rating,
          availabilityId: input.availabilityId,
          upcomingMissions: input.upcomingMissions,
          missionCountMin: input.missionCount,
          perfectMissionPercentage: input.perfectMissionPercentage,
          address: input.address,
          phone: input.phone,
          secondaryPhone: input.secondaryPhone,
          email: input.email,
          part107Number: input.part107Number,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: PilotExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/pilots-service/pilots/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: PilotUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'PUT',
        url: `/api/pilots-service/pilots/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getPilotByUserName = (userName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/pilot-username`,
        params: { userName: userName },
      },
      { apiName: this.apiName, ...config }
    );
  constructor(private restService: RestService) { }

  getPilotPreferredLocations = (pilotId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto[]>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/${pilotId}/preferredLocations`,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  activatePilot = (userId?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilots/activate-pilot',
        body: userId,
      },
      { apiName: this.apiName, ...config }
    );

  getTermsAndConditionsUrl = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, TermsAndConditionsDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/get-pilot-terms-and-conditions-url`,
      },
      { apiName: this.apiName, ...config }
    );

  acceptAgreement = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'POST',
        url: `/api/pilots-service/pilots/accept-agreement`,
      },
      { apiName: this.apiName, ...config }
    );

  validateExistingFAA = (faa: string, pilotId?: string, config?: Partial<Rest.Config>) => {
    const pilotParam = pilotId != null ? `&id=${pilotId}` : "";
    return this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/validate-faa?faa=${faa}${pilotParam}`,
      },
      { apiName: this.apiName, ...config }
    );
  }

  partialUpdate = (id: string, input: {}, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>(
      {
        method: 'PUT',
        url: `/api/pilots-service/pilots/partial/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

}
