import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetMissionDeliverableInput, MissionDeliverablesCreateDto, MissionDeliverablesDto, MissionDeliverablesExcelDownloadDto, MissionDeliverablesUpdateDto } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MissionDeliverablesService {
  apiName = 'missionsService';
  

  create = (input: MissionDeliverablesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionDeliverablesDto>({
      method: 'POST',
      url: '/api/missions-service/mission-deliverable',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/mission-deliverable/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionDeliverablesDto>({
      method: 'GET',
      url: `/api/missions-service/mission-deliverable/${id}`,
    },
    { apiName: this.apiName,...config });
  
getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/mission-deliverable/download-token',
    },
    { apiName: this.apiName,...config });

getListAsExcelFile = (input: MissionDeliverablesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/mission-deliverable/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetMissionDeliverableInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionDeliverablesDto>>({
      method: 'GET',
      url: '/api/missions-service/mission-deliverable',
      params: { filterText: input.filterText, byDefault: input.byDefault, actualPriceMin: input.actualPriceMin, actualPriceMax: input.actualPriceMax, poductId: input.poductId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: MissionDeliverablesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionDeliverablesDto>({
      method: 'PUT',
      url: `/api/missions-service/mission-deliverable/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
