import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizePipe } from './file-size.pipe';
import { PhonePipe } from './phone.pipe';



@NgModule({
  declarations: [
    FileSizePipe,
    PhonePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileSizePipe,
    PhonePipe,
  ]
})
export class PipesModule { }
