import { NgModule } from '@angular/core';

import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';

import { NgxValidateCoreModule } from '@ngx-validate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { MaterialSharedModule } from '../material-shared/material-shared.module';

import { AgGridModule } from 'ag-grid-angular';
import { FgChipComponent } from './fg-chip/fg-chip.component';
import { FgBreadcrumbComponent } from './fg-breadcrumb/fg-breadcrumb.component';
import { FgFilterChipComponent } from './fg-filter-chip/fg-filter-chip.component';
import { FgSearchBarComponent } from './fg-search-bar/fg-search-bar.component';
import { FormsModule } from '@angular/forms';

import { FlyguysFormsModule } from '@flyguys/forms';
import { FlyguysMapModule } from '@flyguys/map';
import { FlyguysModalsModule } from '@flyguys/modals';
import { FlyguysServicesModule } from '@flyguys/services';
import { FlyguysComponentsModule } from '@flyguys/components';
import { FlyguysGeofenceModule } from '@flyguys/geofence';

import { InfoCardWithActionComponent } from './info-card-with-action/info-card-with-action.component';
import { SubtitleDescriptionComponent } from './subtitle-description/subtitle-description.component';
import { LoadingComponent } from '../components/common/loading/loading.component';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { FileUploadComponent } from '../components/common/file-upload/file-upload.component';
import { QuestionnaireModule } from 'projects/core-service/src/lib/lookups/questionnaire/questionnaire.module';

import { NgxSpinnerModule } from 'ngx-spinner';
import { RoundedCardComponent } from '../components/common/rounded-card/rounded-card.component';
import { IconAsideComponent } from '../components/common/icon-aside/icon-aside.component';
import { MissionCardComponent } from '../components/common/mission-card/mission-card.component';
import { GridFiltersModule } from './grid-filters/grid-filters.module';
import { CustomErrorPagesModule } from './custom-error-pages/custom-error-pages.module';

import { environment } from '../../environments/environment';
import { DeliverableAttributesComponent } from './deliverable-attributes/deliverable-attributes.component';

@NgModule({
  declarations: [
    FgChipComponent,
    FgBreadcrumbComponent,
    FgFilterChipComponent,
    FgSearchBarComponent,
    InfoCardWithActionComponent,
    SubtitleDescriptionComponent,
    LoadingComponent,
    FileUploadComponent,
    RoundedCardComponent,
    IconAsideComponent,
    MissionCardComponent,
    DeliverableAttributesComponent,
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonXModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgScrollbarModule,
    MaterialSharedModule,
    AgGridModule,
    FormsModule,
    FlyguysMapModule,
    FlyguysGeofenceModule,
    FlyguysServicesModule.forRoot({
      google: {
        apiKey: environment.googleMaps.apiKey,
      },
    }),
    FlyguysFormsModule,
    FlyguysComponentsModule,
    FlyguysGeofenceModule,
    FlyguysModalsModule,
    FlyguysComponentsModule,
    NgxActionFrameworkModule,
    QuestionnaireModule,
    NgxSpinnerModule.forRoot({ type: 'ball-square-clockwise-spin' }),
    GridFiltersModule,
    CustomErrorPagesModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonXModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgScrollbarModule,
    MaterialSharedModule,
    AgGridModule,
    FgChipComponent,
    FgBreadcrumbComponent,
    FgFilterChipComponent,
    FgSearchBarComponent,
    FlyguysMapModule,
    FlyguysGeofenceModule,
    FlyguysModalsModule,
    FlyguysComponentsModule,
    FlyguysGeofenceModule,
    FlyguysFormsModule,
    InfoCardWithActionComponent,
    SubtitleDescriptionComponent,
    FileUploadComponent,
    NgxActionFrameworkModule,
    QuestionnaireModule,
    NgxSpinnerModule,
    FormsModule,
    RoundedCardComponent,
    IconAsideComponent,
    MissionCardComponent,
    GridFiltersModule,
    CustomErrorPagesModule,
    DeliverableAttributesComponent,
  ],
  providers: [],
})
export class SharedModule {}
