import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AddressesCreateDto, AddressesDto, AddressesExcelDownloadDto, AddressesUpdateDto, GetAddressInput } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  apiName = 'missionsService';
  

  create = (input: AddressesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AddressesDto>({
      method: 'POST',
      url: '/api/missions-service/address',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/address/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AddressesDto>({
      method: 'GET',
      url: `/api/missions-service/address/${id}`,
    },
    { apiName: this.apiName,...config });

    getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/address/download-token',
    },
    { apiName: this.apiName,...config });

  getList = (input: GetAddressInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AddressesDto>>({
      method: 'GET',
      url: '/api/missions-service/address',
      params: { filterText: input.filterText, streetAddress: input.streetAddress, city: input.city, zipCode: input.zipCode, country: input.country, stateProvince: input.stateProvince, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });

    getListAsExcelFile = (input: AddressesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/address/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: AddressesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AddressesDto>({
      method: 'PUT',
      url: `/api/missions-service/address/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
