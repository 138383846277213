import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Proxy console.warn to detect if its being called by ABPs LocalizationService
const abpMessage = 'The localization source separator (::) not found.';

// Firefox removed -moz prefix from their engine and broke some properties
// set by ngx-datatable. With this snippet we monkey-patch `setProperty` to
// use the proper prefix.
const setProperty = CSSStyleDeclaration.prototype.setProperty;
CSSStyleDeclaration.prototype.setProperty = function () {
  const args = [...arguments];
  if (args[0] === '-moz-transform') {
    args[0] = 'transform';
  }
  return setProperty.apply(this, args);
};

console.warn = new Proxy(console.warn, {
  apply: (target, _, args) => {
    // We're targetting one specific log that we'll always be the first element
    const [message] = args;

    if (message !== abpMessage) {
      target(...args);
    }
  },
});

if (environment.production) {
  enableProdMode();
}

if (window.location.host.includes('qa')) {
  // QA gets disabled console logging
  console.log = () => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
