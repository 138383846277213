<div class="container h-100 d-flex">
  <div class="row">
    <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
      <img src="assets/images/errors/Error403.png" class="mb-25" alt="acces-forbidden" />
    </div>
    <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
      <h2 class="giant-title text-center">403</h2>
      <h2 class="text-center">Oops!!</h2>
      <p class="description text-center">You don't have permission to access to this page</p>

      <div class="d-flex justify-content-center align-items-center gap-3 mt-3">
        <a href="/" class="btn common-button color-white" mat-stroked-button>Back to Home</a>
        <p class="m-0 p-0">or</p>
        <a class="btn common-button color-white" mat-stroked-button (click)="logout()">Logout</a>
      </div>
    </div>
  </div>
</div>
