import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'orders-list',
  templateUrl: './orders-list.component.html',
  styles: [],
})
export class OrdersListComponent {
  constructor(public themeService: CustomizerSettingsService, private router: Router) {}

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}
