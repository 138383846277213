<abp-page [title]="'Missions'">
  <abp-page-toolbar-container class="col">
    <div class="text-lg-end pt-1">
      <button *abpPermission="'missionsService.Mission.Create'" class="btn btn-primary btn-sm" type="button"
        (click)="create()">
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'missionsService::NewMissions' | abpLocalization }}
      </button>
    </div>
  </abp-page-toolbar-container>

</abp-page>

<div class="card">
  <div class="card-body">
    <app-grid #gridComponent [columns]="this.columnDefs" [data]="this.rowData"></app-grid>
  </div>
</div>