import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageSuccessDto } from '../../../models/message/message-success-dto';

@Component({
  selector: 'app-messagesuccess',
  templateUrl: './message.success.component.html',
  styleUrls: ['./message.success.component.css'],
})
export class MessageSucessComponent {
  message: string;

  constructor(
    public dialogRef: MatDialogRef<MessageSucessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageSuccessDto
  ) { }

  onClickClose(): void {
    this.dialogRef.close();
  }
}
