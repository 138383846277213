import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetPilotNoteInput, PilotNotesCreateDto, PilotNotesDto, PilotNotesExcelDownloadDto, PilotNotesUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class PilotNotesService {
  apiName = 'pilotsService';
  

  create = (input: PilotNotesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotNotesDto>({
      method: 'POST',
      url: '/api/pilots-service/pilot-note',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/pilot-note/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotNotesDto>({
      method: 'GET',
      url: `/api/pilots-service/pilot-note/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/pilot-note/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetPilotNoteInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotNotesDto>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-note',
      params: { filterText: input.filterText, subject: input.subject, body: input.body, categoryId: input.categoryId, descriptionCategory: input.descriptionCategory, pilotId: input.pilotId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: PilotNotesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/pilot-note/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

  getPilotLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-note/pilot-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: PilotNotesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotNotesDto>({
      method: 'PUT',
      url: `/api/pilots-service/pilot-note/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
