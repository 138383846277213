import { ErrorHandler, NgModule, Injector, inject, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

import { httpInterceptorProviders } from './http-Interceptors';

import { CoreModule, ReplaceableComponentsService } from '@abp/ng.core';
import { HTTP_ERROR_HANDLER, ThemeSharedModule, ToasterService } from '@abp/ng.theme.shared';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
// import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
// import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AccountAdminConfigModule } from 'projects/account/admin/config/src/public-api';
import { AccountPublicConfigModule } from 'projects/account/public/config/src/public-api';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { ThemeLeptonXModule, eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
// import { AccountConfigModule } from '@abp/ng.account/config';
import { AccountLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/account';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { ProductServiceConfigModule } from 'product-service/config';
import { CoreServiceConfigModule } from 'core-service/config';
import { SharedModule } from './shared/shared.module';
// import { AccountModule } from './account/account.module';

import { FileManagementConfigModule } from 'projects/file-management/config/src/file-management-config.module';

import { ChatConfigModule } from 'projects/chat/config/src/chat-config.module';
import { MissionsServiceConfigModule } from 'missions-service/config';
import { PilotsServiceConfigModule } from 'pilots-service/config';
import { CustomersServiceConfigModule } from 'customers-service/config';
import { NotificationsServiceConfigModule } from 'notifications-service/config';
import { RedirectGuardComponent } from './guards/redirect.component';

import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';

import { MessageSucessComponent } from './components/common/message/message.success.component';

import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm.dialog.component';

import { ActionsCellRendererComponent } from './components/table/renderers/actions-cell-renderer/actions-cell-renderer.component';
import { ButtonCellRendererComponent } from './components/table/renderers/button-cell-renderer/button-cell-renderer.component';
import { Router } from '@angular/router';
import { NotFoundComponent } from './shared/custom-error-pages/not-found/not-found.component';
import { FLGErrorHandler } from './flg-error-handler/flg-error-handler';

import { ToastrModule } from 'ngx-toastr';

import { FlyguysToastService } from '@flyguys/toast';
import { FlyguysToastComponent } from '@flyguys/toast';
import { FileSizePipe } from './pipes/file-size.pipe';
import { HelpModuleModule } from './components/help-module/help-module.module';
// import { ManageProfileTabsService } from '@volo/abp.ng.account/public/config';
import { ManageProfileTabsService } from 'projects/account/public/config/src/public-api';
import { OrdersModule } from './components/orders/orders.module';
import { DistanceModalComponent } from './components/dialog/distance-modal/distance-modal.component';

export function handleHttpErrors(injector: Injector, httpError: HttpErrorResponse) {

  console.log("GLOBAL ERROR: ", httpError)
  if (
    httpError.status === 403 &&
    httpError.error?.error?.code.toLocaleLowerCase() !== 'volo.abp.identity:invalidusername' &&
    httpError.error?.error?.code.toLocaleLowerCase() !==
      'volo.abp.identity:passwordrequiresnonalphanumeric'
  ) {
    const router = injector.get(Router);
    router.navigateByUrl('/forbidden');
    return;
  }

  if (httpError.status === 500) {
    const router = injector.get(Router);
    router.navigateByUrl('/500');
    return;
  }

  if (
    httpError.status === 400 ||
    httpError.error?.error?.code.toLocaleLowerCase() === 'volo.abp.identity:invalidusername' ||
    httpError.error?.error?.code.toLocaleLowerCase() ===
      'volo.abp.identity:passwordrequiresnonalphanumeric'
  ) {
    const toaster = injector.get(ToasterService);
    toaster.error(httpError.error?.error?.message || 'Unknown error ocurred.');
    return;
  }

  if (
    httpError.status === 0 &&
    httpError.statusText?.toLowerCase() === 'unknown error' &&
    !httpError.url?.includes('mediastorage')
  ) {
    console.log("REDIRECTION TO NO-INTERNET PAGE: ", httpError)
    const router = injector.get(Router);
    router.navigateByUrl('/no-internet');
    return;
  }

  if (httpError.status === 0 && httpError.statusText?.toLowerCase() === 'unknown error' && httpError.url?.includes('mediastorage')) {
    const toaster = injector.get(ToasterService);
    if (toaster)
      toaster.warn(`Please check your internet connection.`);
    return;
  }

  return throwError(httpError);
}

function initEmptyLayout() {
  const replaceableComponents = inject(ReplaceableComponentsService);
  return function () {
    replaceableComponents.add({
      key: eThemeLeptonXComponents.EmptyLayout,
      component: NotFoundComponent,
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ActionsCellRendererComponent,
    ButtonCellRendererComponent,
    MessageSucessComponent,
    RedirectGuardComponent,
    ConfirmDialogComponent,
    DistanceModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    SharedModule,
    AccountLayoutModule.forRoot(),
    //AccountConfigModule.forRoot(),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    ProductServiceConfigModule.forRoot(),
    CoreServiceConfigModule.forRoot(),
    FileManagementConfigModule.forRoot(),
    // AccountModule,
    ChatConfigModule.forRoot(),
    MissionsServiceConfigModule.forRoot(),
    PilotsServiceConfigModule.forRoot(),
    CustomersServiceConfigModule.forRoot(),
    NotificationsServiceConfigModule.forRoot(),
    OrdersModule,
    HelpModuleModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    ToastrModule.forRoot({
      toastComponent: FlyguysToastComponent,
      toastClass: 'flyguys-toast',
      titleClass: 'flyguys-toast__title',
      messageClass: 'flyguys-toast__message',
    }),
  ],
  providers: [
    { provide: HTTP_ERROR_HANDLER, useValue: handleHttpErrors },
    {
      provide: APP_INITIALIZER,
      useFactory: initEmptyLayout,
      multi: true,
    },
    APP_ROUTE_PROVIDER,
    {
      provide: ErrorHandler,
      useClass: FLGErrorHandler,
    },
    {
      provide: ToasterService,
      useClass: FlyguysToastService,
    },
    // RedirectGuard,
    httpInterceptorProviders,
    //Pipes
    FileSizePipe,
    ManageProfileTabsService
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
