import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'orders-portfolio',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './orders-portfolio.component.html',
  styles: [],
})
export class OrdersPortfolioComponent {
  constructor() {}
}
