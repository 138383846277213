import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  ConfigurationTypesDto,
  ConfigurationAttributeTypesDto,
  GetConfigurationTypeInput,
  GetConfigurationAttributeTypeInput,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  ConfigurationTypesService,
  ConfigurationAttributeTypesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';

import type {
  ContactsDto,
  GetContactInput,
  GetLocationInput,
  GetMissionInput,
  GetOrderInput,
  GetPortafolioInput,
  GetProjectInput,
  LocationsDto,
  MissionsDto,
  OrdersDto,
  PortafoliosDto,
  ProjectsDto,
} from '../../../proxy/missions-service/basics/models';
import { MissionsService } from '../../../proxy/missions-service/controllers/basics/missions.service';
import {
  enumState,
  EnumStateOptions,
} from '../../../proxy/missions-service/shared/enum-state.enum';
import {
  GetIndustryInput,
  GetPriorityInput,
  IndustriesDto,
  PrioritiesDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  CustomersDto,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import {
  IndustriesService,
  PrioritiesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  ContactsService,
  LocationsService,
  OrdersService,
  PortafoliosService,
  ProjectsService,
} from '../../../proxy/missions-service/controllers/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { missionStatusOptions } from '../../../proxy/missions-service/shared/mission-status.enum';
import { ColumnAction } from 'projects/flyguys-pilot/src/app/components/columns/components/column-actions/column-actions.component';
import { ColumnStatus } from 'projects/flyguys-pilot/src/app/components/columns/components/column-displayer/column-displayer.component';
import { stateRules } from 'projects/flyguys-pilot/src/app/components/columns/rules/lookup-rules';
@Component({
  selector: 'lib-missions',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './missions.component.html',
  styleUrls: [],
})
export class MissionsComponent implements OnInit {
  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';

  data: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  industries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };

  projects: PagedResultDto<ProjectsDto> = {
    items: [],
    totalCount: 0,
  };

  customers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };

  orders: PagedResultDto<OrdersDto> = {
    items: [],
    totalCount: 0,
  };

  locations: PagedResultDto<LocationsDto> = {
    items: [],
    totalCount: 0,
  };

  priorities: PagedResultDto<PrioritiesDto> = {
    items: [],
    totalCount: 0,
  };

  contacts: PagedResultDto<ContactsDto> = {
    items: [],
    totalCount: 0,
  };

  portfolios: PagedResultDto<PortafoliosDto> = {
    items: [],
    totalCount: 0,
  };

  configurationTypes: PagedResultDto<ConfigurationTypesDto> = {
    items: [],
    totalCount: 0,
  };

  configurationAttributeTypes: PagedResultDto<ConfigurationAttributeTypesDto> = {
    items: [],
    totalCount: 0,
  };

  filters = { isPaginated: true } as GetMissionInput;

  orderFilter = { state: enumState.Enabled, isPaginated: true } as GetOrderInput;

  locationFilter = { state: enumState.Enabled, isPaginated: true } as GetLocationInput;

  contactFilter = { state: enumState.Enabled, isPaginated: true } as GetContactInput;

  projectFilter = { state: enumState.Enabled, isPaginated: true } as GetProjectInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: MissionsDto;

  states = EnumStateOptions;

  public readonly missionStatus = missionStatusOptions;

  projectDict: { [id: string]: string } = {};

  customerDict: { [id: string]: string } = {};

  orderDict: { [id: string]: string } = {};

  locationDict: { [id: string]: string } = {};

  priorityDict: { [id: string]: string } = {};

  contactDict: { [id: string]: string } = {};

  portfolioDict: { [id: string]: string } = {};

  @Input() export: Boolean = false;

  clientMaxResultCount = 10;
  valuesMaxResultCount = [];
  pageNumber = 0;

  action: any;
  MaxResultCount = 100;

  showedColumns = {
    actions: true,
    name: true,
    customer: true,
    priority: true,
    location: true,
    startData: true,
    state: true,
    project: true,
    missionStatus: true,
    pilotSourcingMode: true,
    qaqcMode: true,
    invoiceMode: true,
    cost: true,
  };

  stateRules = stateRules;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionsService,
    public readonly projectService: ProjectsService,
    public readonly projectList: ListService,
    public readonly customerService: CustomersService,
    public readonly customerList: ListService,
    public readonly orderService: OrdersService,
    public readonly orderList: ListService,
    public readonly locationService: LocationsService,
    public readonly locationList: ListService,
    public readonly priorityService: PrioritiesService,
    public readonly priorityList: ListService,
    public readonly contactService: ContactsService,
    public readonly contactList: ListService,
    private confirmation: ConfirmationService,
    public readonly portafoliosService: PortafoliosService,
    public readonly portfolioList: ListService,
    public readonly industryService: IndustriesService,
    public readonly industriesList: ListService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationTypesOptionsList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    public readonly configurationAttributeTypesOptionsList: ListService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  setAction(act: any) {
    this.action = act;
  }

  ngOnInit() {
    this.getPageAttributeValues();
    this.getMaxCountAttribute();
    this.getMissionValues();
    this.getIndustryValues();
    this.getCustomerValues();
    this.getPriorityValues();
    this.getProjectValues();
    this.getOrderValues();
    this.getLocationValues();
    this.getContactValues();
  }

  clearFilters() {
    this.filters = { isPaginated: true } as GetMissionInput;
  }

  buildForm() {
    const {
      name,
      summary,
      customerId,
      startData,
      finishData,
      industryId,
      projectId,
      state,
      locationId,
      additionalCharges,
      orderId,
      thirdPartyId,
      priorityId,
      cost,
      priorityDescription,
      missionStatus,
      pilotSourcingMode,
      qaqcMode,
      invoiceMode,
    } = this.selected || {};

    this.form = this.fb.group({
      name: [name ?? null, [Validators.required, Validators.maxLength(100)]],
      summary: [summary ?? null, [Validators.required, Validators.maxLength(100)]],
      customerId: [customerId ?? null, [Validators.required]],
      startData: [startData ? new Date(startData) : null, [Validators.required]],
      finishData: [finishData ? new Date(finishData) : null, [Validators.required]],
      industryId: [industryId ?? null, [Validators.required]],
      projectId: [projectId ?? null, [Validators.required]],
      state: [state ?? '1', [Validators.required]],
      locationId: [locationId ?? null, [Validators.required]],
      additionalCharges: [additionalCharges ?? null, [Validators.maxLength(500)]],
      orderId: [orderId ?? null, [Validators.required]],
      thirdPartyId: [thirdPartyId ?? null, [Validators.required]],
      priorityId: [priorityId ?? null, [Validators.required]],
      cost: [cost ?? null, [Validators.required]],
      priorityDescription: [priorityDescription ?? null, []],
      missionStatus: [missionStatus ?? null, []],
      pilotSourcingMode: [pilotSourcingMode ?? null, []],
      qaqcMode: [qaqcMode ?? null, []],
      invoiceMode: [invoiceMode ?? null, []],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.id, this.form.value)
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: MissionsDto) {
    this.selected = record;
    this.showForm();
  }

  delete(record: MissionsDto) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'Missions.xlsx');
      });
  }

  changePazeSize(e) {
    this.list.maxResultCount = this.clientMaxResultCount;
    this.pageNumber = 0;
  }

  updateProject(value) {
    this.projectFilter.name = value;
    this.getProjectValues();
  }

  updateContacts(value) {
    this.contactFilter.firstName = value;
    this.getContactValues();
  }

  updateOrders(value) {
    this.orderFilter.filterText = value;
    this.getOrderValues();
  }

  updateLocations(value) {
    this.locationFilter.gpsCoordinates = value;
    this.getLocationValues();
  }

  private getMissionValues(): void {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<MissionsDto>) => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  private getIndustryValues(): void {
    const query = {} as ABP.PageQueryParams;

    const industryFilter = { state: enumState.Enabled } as GetIndustryInput;

    this.industryService
      .getList({
        ...query,
        ...industryFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.industries = res;
      });
  }

  private getCustomerValues(): void {
    const query = {} as ABP.PageQueryParams;

    const customerFilter = { state: enumState.Enabled } as GetCustomerInput;

    this.customerService
      .getList({
        ...query,
        ...customerFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.customers = res;
        for (let dep of this.customers.items) {
          this.customerDict[dep.id] = dep.name;
        }
      });
  }

  private getPriorityValues(): void {
    const query = {} as ABP.PageQueryParams;
    const priorityFilter = { state: enumState.Enabled } as GetPriorityInput;

    this.priorityService
      .getList({
        ...query,
        ...priorityFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.priorities = res;
        for (let pr of this.priorities.items) {
          this.priorityDict[pr.id] = pr.description;
        }
      });
  }

  private getProjectValues(): void {
    const query = {} as ABP.PageQueryParams;
    this.projectFilter.maxResultCount = this.MaxResultCount;

    this.projectService
      .getList({
        ...query,
        ...this.projectFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.projects = res;
        for (let dep of this.projects.items) {
          this.projectDict[dep.id] = dep.name;
        }
      });
  }

  private getOrderValues(): void {
    const query = {} as ABP.PageQueryParams;
    this.orderFilter.maxResultCount = this.MaxResultCount;

    this.orderService
      .getList({
        ...query,
        ...this.orderFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.orders = res;
        for (let dep of this.orders.items) {
          this.orderDict[dep.id] = dep.id;
        }
      });
  }

  private getLocationValues(): void {
    const query = {} as ABP.PageQueryParams;
    this.locationFilter.maxResultCount = this.MaxResultCount;

    this.locationService
      .getList({
        ...query,
        ...this.locationFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.locations = res;
        for (let dep of this.locations.items) {
          this.locationDict[dep.id] = dep.gpsCoordinates;
        }
      });
  }

  private getContactValues(): void {
    const query = {} as ABP.PageQueryParams;
    this.contactFilter.maxResultCount = this.MaxResultCount;

    this.contactService
      .getList({
        ...query,
        ...this.contactFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contacts = res;
        for (let dep of this.contacts.items) {
          this.contactDict[dep.id] = dep.firstName + ' ' + dep.lastName;
        }
      });
  }

  private getPageAttributeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        })
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
      });
  }

  private getMaxCountAttribute(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        })
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.MaxResultCount = parseInt(attribute.description);
      });
  }

  columnActions(record: MissionsDto) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'edit',
        abpPermission: 'missionsService.Mission.Edit',
        action: { callAction: () => this.update(record) },
      },
      {
        actionIcon: 'delete',
        abpPermission: 'missionsService.Mission.Delete',
        action: { callAction: () => this.delete(record) },
      },
    ];

    return columnActions;
  }

  toggler = (column: string, checked: boolean) => (this.showedColumns[column] = checked);

  columnsStatus() {
    //TODO Adding columns summary and additionalnotes permissions. Adrian Scarinci.09062023.
    const columnsStatus: ColumnStatus[] = [
      {
        columnName: 'actions',
        softColumnName: 'Actions',
        defaultChecked: this.showedColumns.actions,
        abpPermission: 'missionsService.Mission.ShowColumn.Actions',
      },
      {
        columnName: 'name',
        softColumnName: 'Name',
        defaultChecked: this.showedColumns.name,
        abpPermission: 'missionsService.Mission.ShowColumn.Name',
      },
      {
        columnName: 'customer',
        softColumnName: 'Customer',
        defaultChecked: this.showedColumns.customer,
        abpPermission: 'missionsService.Mission.ShowColumn.Customer',
      },
      {
        columnName: 'priority',
        softColumnName: 'Priority',
        defaultChecked: this.showedColumns.priority,
        abpPermission: 'missionsService.Mission.ShowColumn.Priority',
      },
      {
        columnName: 'location',
        softColumnName: 'Location',
        defaultChecked: this.showedColumns.location,
        abpPermission: 'missionsService.Mission.ShowColumn.Location',
      },
      {
        columnName: 'startData',
        softColumnName: 'Start Data',
        defaultChecked: this.showedColumns.startData,
        abpPermission: 'missionsService.Mission.ShowColumn.StartData',
      },
      {
        columnName: 'state',
        softColumnName: 'State',
        defaultChecked: this.showedColumns.state,
        abpPermission: 'missionsService.Mission.ShowColumn.State',
      },
      {
        columnName: 'project',
        softColumnName: 'Project',
        defaultChecked: this.showedColumns.project,
        abpPermission: 'missionsService.Mission.ShowColumn.Project',
      },
      {
        columnName: 'missionStatus',
        softColumnName: 'Mission Status',
        defaultChecked: this.showedColumns.missionStatus,
        abpPermission: 'missionsService.Mission.ShowColumn.MissionStatus',
      },
      {
        columnName: 'pilotSourcingMode',
        softColumnName: 'Pilot Sourcing Mode',
        defaultChecked: this.showedColumns.pilotSourcingMode,
        abpPermission: 'missionsService.Mission.ShowColumn.PilotSourcingMode',
      },
      {
        columnName: 'qaqcMode',
        softColumnName: 'QAQC Mode',
        defaultChecked: this.showedColumns.qaqcMode,
        abpPermission: 'missionsService.Mission.ShowColumn.QaqcMode',
      },
      {
        columnName: 'invoiceMode',
        softColumnName: 'Invoice Mode',
        defaultChecked: this.showedColumns.invoiceMode,
        abpPermission: 'missionsService.Mission.ShowColumn.InvoiceMode',
      },
      {
        columnName: 'cost',
        softColumnName: 'Cost',
        defaultChecked: this.showedColumns.cost,
        abpPermission: 'missionsService.Mission.ShowColumn.Cost',
      },
    ];

    return columnsStatus;
  }
}
