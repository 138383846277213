import { Component, Input } from '@angular/core';
import { Badge } from '../../models/badges.enum';
import { PilotSourcingDto, PilotSourcingTableDto } from '../../models/pilot-sourcing-dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MissionPilotsService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals';
import { MissionPilotsCreateDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/relationals';
import {
  enumState,
  EnumStateOptions,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/shared';
import {
  AvailabilityService,
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  MissionPilotRequestStatusService,
  PilotExperienceLevelesService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  AvailabilityDto,
  GetAvailabilitiesInput,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetMissionPilotRequestStateInput,
  GetPilotExperienceLevelInput,
  MissionPilotRequestStatusDto,
  PilotExperienceLevelesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { switchMap } from 'rxjs/operators';
import { GetPilotsForSourcingInput } from '../../models/pilot-for-sorucing-request';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PilotSourcingService } from '../../../services/pilot-sourcing.service';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-pilot-sourcing-sourcing',
  templateUrl: './pilot-sourcing-sourcing.component.html',
  styleUrls: ['./pilot-sourcing-sourcing.component.scss'],
  providers: [ListService],
})
export class PilotSourcingSourcingComponent {
  @Input() titleKey: string | null = null;

  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';
  filters = { isPaginated: true } as GetPilotsForSourcingInput;

  columns = [
    { prop: 'id' },
    { name: 'Select', prop: 'checkbox', checkboxable: true },
    { prop: 'name', name: 'Pilot Name' },
    { prop: 'requestStatus', name: 'Request Status' },
    { prop: 'distance', name: 'Distance From Job' },
    { prop: 'upcomingJobs', name: 'Upcoming Jobs' },
    { prop: 'missionCount', name: 'Mission Count' },
    { prop: 'perfectMissionPercentage', name: '% Perfect Mission' },
    { prop: 'badges', name: 'Badges' },
    { prop: 'rating', name: 'Rating' },
    { prop: 'availability', name: 'Availability' },
    { name: 'Actions' },
  ];

  pilotsForSourcingData: PagedResultDto<PilotSourcingTableDto> = {
    items: [],
    totalCount: 0,
  };

  states = EnumStateOptions;
  experienceLevels: PagedResultDto<PilotExperienceLevelesDto> = {
    items: [],
    totalCount: 0,
  };
  requestStatuses: PagedResultDto<MissionPilotRequestStatusDto> = {
    items: [],
    totalCount: 0,
  };
  availabilities: PagedResultDto<AvailabilityDto> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup;

  isFiltersHidden = true;
  isModalBusy = false;
  isModalOpen = false;
  missionId: string;
  captureId: string;
  breadcrumbs: any[] = [];

  statusOptions: any[] = [];
  selectedPilot: PilotSourcingDto;

  public page = 1;
  public totalCount = 0;
  public sorting = '';
  valuesMaxResultCount = [];
  MaxResultCount = 100;

  clientMaxResultCount = 10;
  pageNumber = 0;

  selectedPilotIds: string[] = [];

  constructor(
    private fb: FormBuilder,
    public readonly pilotSourcingService: PilotSourcingService,
    private missionPilotsService: MissionPilotsService,
    private availabilityService: AvailabilityService,
    private requestStatusService: MissionPilotRequestStatusService,
    private pilotExperienceLevelService: PilotExperienceLevelesService,
    public readonly list: ListService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    private route: ActivatedRoute,
    private confirmation: ConfirmationService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      status: [''],
    });

    this.route.queryParams.subscribe(params => {
      this.captureId = params['captureId'];
    });

    this.getMissionId()
      .pipe(
        switchMap(() => {
          this.getPageAttributeValues();
          this.getMaxCountAttribute();
          this.getPilotSourcingValues();
          this.getPilotExperienceLevelValues();
          this.getRequestStatusValues();
          this.getAvailabilityValues();
          return of(null);
        })
      )
      .subscribe();
  }

  private getMissionId(): Observable<any> {
    return new Observable(observer => {
      this.route.parent?.paramMap.subscribe(params => {
        this.missionId = params.get('missionId');
        this.breadcrumbs = [
          { text: 'Captures', link: `/pilot-sourcing/missions/${this.missionId}/captures` },
          { text: 'Pilot Sourcing' },
        ];
        observer.next();
        observer.complete();
      });
    });
  }

  private getPilotSourcingValues(): void {
    // Needed to filter the request statuses by this specific mission id only
    this.filters.missionId = this.missionId;
    console.log(this.missionId);
    console.log(this.filters);
    const getData = (query: ABP.PageQueryParams) =>
      this.pilotSourcingService.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<PilotSourcingTableDto>) =>
      (this.pilotsForSourcingData = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  private getPilotExperienceLevelValues(): void {
    const query = {} as ABP.PageQueryParams;
    const experienceLevelFilter = { state: enumState.Enabled } as GetPilotExperienceLevelInput;

    this.pilotExperienceLevelService
      .getList({
        ...query,
        ...experienceLevelFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.experienceLevels = res;
      });
  }

  private getRequestStatusValues(): void {
    const query = {} as ABP.PageQueryParams;
    const requestStatusFilter = { state: enumState.Enabled } as GetMissionPilotRequestStateInput;

    this.requestStatusService
      .getList({
        ...query,
        ...requestStatusFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.requestStatuses = res;
      });
  }

  private getAvailabilityValues(): void {
    const query = {} as ABP.PageQueryParams;
    const availabilityFilter = { state: enumState.Enabled } as GetAvailabilitiesInput;

    this.availabilityService
      .getList({
        ...query,
        ...availabilityFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.availabilities = res;
      });
  }
  toggleCheckbox(row: PilotSourcingDto) {
    const isSelected = this.selectedPilotIds.includes(row.id);

    if (isSelected) {
      const index = this.selectedPilotIds.indexOf(row.id);
      if (index !== -1) {
        this.selectedPilotIds.splice(index, 1);
      }
    } else {
      this.selectedPilotIds.push(row.id);
    }
  }

  assignPilots() {
    const isSinglePilot = this.selectedPilotIds.length === 1;
    const pilotCountText = isSinglePilot ? 'pilot' : 'pilots';
    const confirmationMessage = `Are you sure you want to assign ${this.selectedPilotIds.length} ${pilotCountText} to this capture?`;

    this.confirmation.warn(confirmationMessage, 'Assign Pilots').subscribe(status => {
      if (status === Confirmation.Status.confirm) {
        const assignedStatus = this.requestStatuses.items.find(
          status => status.description.trim().toLowerCase() === 'assigned'
        );
        if (!assignedStatus) {
          console.error('Assigned status not found.');
          return;
        }
        const assignedStatusId = assignedStatus.id;

        // TODO we also need to create one or more records for the CapturePilots table, the table exists already but the Crud logic is in progress...

        const missionPilotApiCalls = this.selectedPilotIds.map(pilotId => {
          const createDto: MissionPilotsCreateDto = {
            pilotId: pilotId,
            missionId: this.missionId,
            assigned: true,
            state: enumState.Enabled,
            assignedDate: new Date().toISOString(),
            distance: 0,
            requestStatusId: assignedStatusId,
            descriptionMission: 'mission description',
          };
          return this.missionPilotsService.create(createDto);
        });

        const sequentialApiCalls = missionPilotApiCalls.reduce((prevCall, nextCall) => {
          return prevCall.pipe(switchMap(() => nextCall));
        }, of(null));

        sequentialApiCalls.subscribe({
          next: response => {
            this.getPilotSourcingValues();
            this.selectedPilotIds = [];            
            this.isModalOpen = false;
          },
          error: err => {
            console.log(err);
            this.isModalOpen = false;
          },
        });
      }
    });
  }

  openUpdateRequestStatusModal(row: PilotSourcingDto) {
    this.selectedPilot = row;
    this.isModalOpen = true;
  }

  submitUpdateRequestStatus() {
    const selectedStatusId = this.form.get('status')?.value;

    const selectedStatus = this.statusOptions.find(status => status.id === selectedStatusId);
    const isAssigned = selectedStatus?.description.trim().toLowerCase() === 'assigned';

    // TODO: Add logic to calculate the distance
    const calculatedDistance = 0;

    if (!this.missionId) {
      alert('MissionId is required to perform this action!');
      return;
    }

    const createDto: MissionPilotsCreateDto = {
      pilotId: this.selectedPilot.id,
      missionId: this.missionId,
      assigned: isAssigned,
      state: enumState.Enabled,
      assignedDate: new Date().toISOString(),
      distance: calculatedDistance,
      requestStatusId: selectedStatusId,
      descriptionMission: 'mission description',
    };

    this.missionPilotsService.create(createDto).subscribe({
      next: response => {
        this.isModalOpen = false;
        this.getPilotSourcingValues();
      },
      error: err => {
        console.log(err);
        this.isModalOpen = false;
      },
    });
  }

  openBadges(row: any) {
    console.log('openBadges', row);
    alert('work in progress!');
  }

  sendRequest(row: any) {
    console.log('handleJobHistory', row);
    alert('work in progress!');
  }

  sendEmail(row: any) {
    console.log('handleSendEmail', row);
    alert('work in progress!');
  }

  makeCall(row: any) {
    console.log('handleMakeCall', row);
    alert('work in progress!');
  }

  clearFilters() {
    this.filters = { isPaginated: true } as GetPilotsForSourcingInput;
  }

  getStars(rating: number): string[] {
    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = Math.floor(roundedRating);
    const halfStars = roundedRating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    for (let i = 0; i < fullStars; i++) {
      stars.push('filled');
    }

    if (halfStars) {
      stars.push('half');
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push('empty');
    }

    return stars;
  }

  getBadgeString(badge: Badge): string {
    switch (badge) {
      case Badge.BADGE_ONE:
        return 'new_releases';
      case Badge.BADGE_TWO:
        return 'co_present';
      case Badge.BADGE_THREE:
        return 'cameraswitch';
      case Badge.BADGE_FOUR:
        return 'military_tech';
      default:
        return '';
    }
  }

  getRequestStatusChipType(requestStatus: string): string {
    const trimmedStatus = requestStatus.trim().toLowerCase();

    switch (trimmedStatus) {
      case 'not requested':
        return 'grayChip';
      case 'accepted':
        return 'greenChip';
      case 'rejected':
        return 'pinkChip';
      case 'awaiting response':
        return 'orangeChip';
      case 'assigned':
        return 'blueChip';
      default:
        return 'grayChip';
    }
  }

  changePageSize() {
    this.list.maxResultCount = this.clientMaxResultCount;
    this.pageNumber = 0;
  }

  private getPageAttributeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        })
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
      });
  }

  private getMaxCountAttribute(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        })
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.MaxResultCount = parseInt(attribute.description);
      });
  }
}
