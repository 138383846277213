import { ChunkModel } from "./chunk-model";

export class ChunkFileModel {

    totalChunks: number;
    fileName: string;
    blobName: string;
    chunks: Array<ChunkModel>;
    thumbnail: string;

    constructor() {
        this.totalChunks = 0;
        this.fileName = "";
        this.chunks = [];
        this.thumbnail = null;
    }
}
