<h2>{{ 'AbpAccount::Menu:Account' | abpLocalization }}</h2>
<hr class="my-2"/>

<div id="AccountSettingsForm" class="row">
  <div class="col-md-12">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink>{{ 'AbpAccount::AccountSettingsGeneral' | abpLocalization }}</a>
        <ng-template ngbNavContent>
          <abp-account-settings-general [isTenant]="isTenant"></abp-account-settings-general>
        </ng-template>
      </li>
      <li ngbNavItem *ngIf="isTwoFactorSettingsEnabled">
        <a ngbNavLink>{{ 'AbpAccount::AccountSettingsTwoFactor' | abpLocalization }}</a>
        <ng-template ngbNavContent>
          <abp-account-settings-two-factor [isTenant]="isTenant"></abp-account-settings-two-factor>
        </ng-template>
      </li>
      <li ngbNavItem *ngIf="isCaptchaEnabled$ | async">
        <a ngbNavLink>
          {{ 'AbpAccount::Captcha' | abpLocalization }}
        </a>
        <ng-template ngbNavContent>
          <abp-account-settings-captcha [isTenant]="isTenant"></abp-account-settings-captcha>
        </ng-template>
      </li>
      <ng-container *ngIf="isExternalProviderEnabled$ | async">
        <li ngbNavItem *ngIf="isExternalProviderExists$ | async">
          <a ngbNavLink>
            {{ 'AbpAccount::AccountExternalProviderSettings' | abpLocalization }}
          </a>
          <ng-template ngbNavContent>
            <abp-account-settings-external-provider
              [isTenant]="isTenant"
            ></abp-account-settings-external-provider>
          </ng-template>
        </li>
      </ng-container>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
