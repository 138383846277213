<abp-page [title]="'missionsService::Revenues' | abpLocalization">
  <abp-page-toolbar-container class="col">
    <div class="text-lg-end pt-2">
      <button
        class="btn btn-primary btn-sm mx-2"
        type="button"
        [disabled]="isExportToExcelBusy"
        (click)="exportToExcel()"
      >
        {{ 'missionsService::ExportToExcel' | abpLocalization }}
      </button>

      <!-- <button
        *abpPermission="'missionsService.MissionFinancialRevenues.Create'"
        class="btn btn-primary btn-sm"
        type="button"
        (click)="create()"
      >
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'missionsService::NewMissionFinancialRevenue' | abpLocalization }}
      </button> -->

      <button class="btn btn-primary btn-sm" type="button" *ngIf="action">
        <lib-action-form
          [row]="{ title: 'New Revenue' }"
          [gridAction]="action"
          [isMobile]="false"
          [config]="{ modalWidth: '900px', showCloseOnModal: true }"
        >
          <span style="color: white !important">
            <i class="fa fa-plus me-1" aria-hidden="true"></i>
            {{ 'missionsService::NewMissionFinancialRevenue' | abpLocalization }}
          </span>
        </lib-action-form>
      </button>
    </div>
  </abp-page-toolbar-container>

  <div class="card">
    <div class="card-body">
      <ngx-datatable
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        default
        [externalPaging]="true"
        [offset]="pageNumber"
        [limit]="clientMaxResultCount"
        #dataTable
      >
        <ngx-datatable-column
          name="{{ 'missionsService::Revenue Type' | abpLocalization }}"
          prop="missionFinancialRevenue.revenueTypeId"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ revenueTypes[row.missionFinancialRevenue.revenueTypeId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Units' | abpLocalization }}"
          prop="missionFinancialRevenue.units"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionFinancialRevenue.units }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::ItemPrice' | abpLocalization }}"
          prop="missionFinancialRevenue.itemPrice"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionFinancialRevenue.itemPrice }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::TotalPrice' | abpLocalization }}"
          prop="missionFinancialRevenue.totalPrice"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionFinancialRevenue.totalPrice }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::Deliverables' | abpLocalization }}"
          prop="deliverables.name"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.deliverables?.name }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::Creation Time' | abpLocalization }}"
          prop="missionFinancialRevenue.creationTime"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionFinancialRevenue.creationTime | date }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <div ngbDropdown container="body" class="d-inline-block">
              <!-- <button
              class="btn btn-primary btn-sm dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              ngbDropdownToggle
            >
              <i class="fa fa-cog me-1" aria-hidden="true"></i>
              {{ 'AbpUi::Actions' | abpLocalization }}
            </button>

            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                *abpPermission="'missionsService.MissionFinancialRevenues.Edit'"
                (click)="update(row)"
              >
                {{ 'AbpUi::Edit' | abpLocalization }}
              </button>

              <button
                ngbDropdownItem
                *abpPermission="'missionsService.MissionFinancialRevenues.Delete'"
                (click)="delete(row)"
              >
                {{ 'AbpUi::Delete' | abpLocalization }}
              </button>
            </div>  -->
              <lib-action-form
                *ngIf="action"
                [row]="{
                  id: row.missionFinancialRevenue.id,
                  defItemPrice: row.missionFinancialRevenue.itemPrice + '',
                  title: 'Edit Revenue',
                  defUnits: row.missionFinancialRevenue.units,
                  defTotalPrice: row.missionFinancialRevenue.totalPrice,
                  defDeliverable: row.missionFinancialRevenue.deliverablesId ?? '',
                  defRevenueId: row.missionFinancialRevenue.revenueTypeId
                }"
                [gridAction]="action"
                [isMobile]="false"
                [config]="{ modalWidth: '900px', showCloseOnModal: true }"
              >
                <i class="fa fa-pen me-1" aria-hidden="true"></i>
              </lib-action-form>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
              style="width: 100%"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{
        (selected ? 'AbpUi::Edit' : 'missionsService::NewMissionFinancialRevenue') | abpLocalization
      }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form
      [formGroup]="form"
      id="mission-financial-revenue"
      (ngSubmit)="submitForm()"
      validateOnSubmit
    >
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="mission-financial-revenue-revenue-type-id">
            {{ 'missionsService::RevenueTypeId' | abpLocalization }}
          </label>

          <input
            id="mission-financial-revenue-revenue-type-id"
            formControlName="revenueTypeId"
            class="form-control"
            autofocus
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-revenue-units">
            {{ 'missionsService::Units' | abpLocalization }}
          </label>

          <input
            type="number"
            id="mission-financial-revenue-units"
            formControlName="units"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-revenue-item-price">
            {{ 'missionsService::ItemPrice' | abpLocalization }}
          </label>

          <input
            type="number"
            id="mission-financial-revenue-item-price"
            formControlName="itemPrice"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-revenue-total-price">
            {{ 'missionsService::TotalPrice' | abpLocalization }}
          </label>

          <input
            type="number"
            id="mission-financial-revenue-total-price"
            formControlName="totalPrice"
            class="form-control"
            [readonly]="selected"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="mission-financial-revenue-deliverables-id">
            {{ 'missionsService::Deliverables' | abpLocalization }}
          </label>
          <abp-lookup-input
            cid="mission-financial-revenue-deliverables-id"
            formControlName="deliverablesId"
            displayNameProp="name"
            lookupNameProp="displayName"
            [getFn]="service.getDeliverablesLookup"
            [editingData]="selected?.deliverables"
          ></abp-lookup-input>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="mission-financial-revenue">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>

<app-responsive-grid
  class="d-none"
  gridName="Revenues"
  (action)="setAction($event)"
></app-responsive-grid>
