import { NavItemsService } from '@abp/ng.theme.shared';
import { APP_INITIALIZER } from '@angular/core';
import { ChatIconComponent } from '../components/chat-icon.component';
import { eChatPolicyNames } from '../enums/policy-names';
import { Router } from '@angular/router';

export const CHAT_NAV_ITEM_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureNavItems,
    deps: [NavItemsService, Router],
    multi: true,
  },
];

export function configureNavItems(navItems: NavItemsService, router: Router) {
  return () => {
    navItems.addItems([
      {
        id: 'Chat.ChatIconComponent',
        requiredPolicy: eChatPolicyNames.Messaging,
        component: ChatIconComponent,
        order: 99.99,
        action: () => {
          router.navigate(['/chat']);
        },
      },
    ]);
  };
}
