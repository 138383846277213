import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ProfileImageDto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class AuthServerService {
  apiName = 'AbpAccountPublic';

  GetProfilePicture = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProfileImageDto>(
      {
        method: 'GET',
        url: `/api/account/profile-picture/${userId}`,
      },
      { apiName: this.apiName, ...config },
    );

  deleteUserData = (password: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: '/api/account/delete-my-data',
        body: { password },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
