import { downloadBlob } from '@abp/ng.core';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileDescriptorService, FileInfoDto } from 'projects/file-management/proxy/src/lib';
import { AttributeTypesEnum } from 'projects/missions-service/src/lib/proxy/missions-service/basics/attribute-types.enum';
import { finalize, switchMap } from 'rxjs';
import { MessageSucessComponent } from '../../components/common/message/message.success.component';
import { OrderFormDeliverableFieldModel } from '../../components/orders/dto/order-form-deliverable-field.model';
import { OrderFormDeliverableModel } from '../../components/orders/dto/order-form-deliverable.model';
import { DeliverableAttribute } from '../../models/missions/deliverable-attribute';
import { LoadingOverlayService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-deliverable-attributes',
  templateUrl: './deliverable-attributes.component.html',
  styleUrls: ['./deliverable-attributes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliverableAttributesComponent implements OnInit {

  public readonlyMode: boolean;
  maxFileSizeFiles: number = 200 * 1024 * 1024;

  readonly DEFAULT_MAX_LENGTH = 100;

  deliverableAttribute: DeliverableAttribute;
  fileUpdated: boolean;

  public get attributeTypes(): typeof AttributeTypesEnum {
    return AttributeTypesEnum;
  }

  constructor(
    public dialogRef: MatDialogRef<DeliverableAttributesComponent>,
    public readonly fileDescriptorService: FileDescriptorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      deliverableData: OrderFormDeliverableModel;
      readonly: boolean;
      isEdition: boolean;
      fromMissionDetail?: boolean;
    },
    public dialogService: MatDialog,
    public loadingService: LoadingOverlayService
  ) {

    this.readonlyMode = data.readonly;
  }

  ngOnInit() {

    this.createInitialModel();
  }

  private createInitialModel(): void {

    if (!this.data.deliverableData.fields)
      this.data.deliverableData.fields = [];

    const deliverableAttribute = <DeliverableAttribute>{
      deliverableId: this.data.deliverableData.deliverableId,
      productId: this.data.deliverableData.productId,
      deliverableName: this.data.deliverableData.deliverableName,
      quantity: this.data.deliverableData.quantity,
      sameConfiguration: this.data.isEdition ? this.data.deliverableData.sameConfiguration : true,
      fields: this.data.deliverableData.fields.map(x => {

        const attr = { ...x };

        attr.fieldControlName = `ctrl-${attr.id}-${this.data.deliverableData.productId}`;

        if (this.data.isEdition) {

          attr.stringValue = x.stringValue;

          if (attr.typeCode === AttributeTypesEnum.Bool)
            attr.boolValue = attr.stringValue === true.toString();

          if (attr.typeCode === AttributeTypesEnum.Number)
            attr.numericValue = new Number(attr.stringValue);

          if (attr.typeCode === AttributeTypesEnum.File || attr.typeCode === AttributeTypesEnum.Kml) {

            attr.loadingFile = true;
            this.fileDescriptorService.get(attr.stringValue).subscribe({
              next: res => {
                attr.fileValue = res.name;
                attr.loadingFile = false;

              },
              error: () => {
                attr.loadingFile = false;
              }
            });

          }
          attr.numericValue = new Number(attr.stringValue);
        }
        else {

          if (attr.defaultValue) {

            if (attr.typeCode === AttributeTypesEnum.Bool)
              attr.boolValue = attr.defaultValue === true.toString();
            else if (attr.typeCode === AttributeTypesEnum.Number)
              attr.numericValue = new Number(attr.defaultValue);
            else
              attr.stringValue = x.defaultValue;
          }
        }

        return attr;
      })
    }

    deliverableAttribute.detailedAttributes = [];

    for (let i = 0; i < deliverableAttribute.quantity; i++) {

      if (this.data.isEdition) {

        if (this.data.deliverableData.detailedAttributes && this.data.deliverableData.detailedAttributes.length > i) {

          const fields = [...this.data.deliverableData.detailedAttributes[i]]

          const fieldsCopy = fields.map(x => x);

          deliverableAttribute.detailedAttributes.push(fieldsCopy);

        }
        else {

          const newArray = deliverableAttribute.fields.map(x => {

            const attr = { ...x };
            attr.fieldControlName = `ctrl-${i}-${x.id}-${this.data.deliverableData.productId}`;
            attr.stringValue = '';
            attr.loadingFile = false;

            if (attr.defaultValue) {

              if (attr.typeCode === AttributeTypesEnum.Bool)
                attr.boolValue = attr.defaultValue === true.toString();
              else if (attr.typeCode === AttributeTypesEnum.Number)
                attr.numericValue = new Number(attr.defaultValue);
              else
                attr.stringValue = x.defaultValue;
            }

            return attr;
          });

          deliverableAttribute.detailedAttributes.push(newArray);
        }
      }
      else {

        const newArray = deliverableAttribute.fields.map(x => {

          const attr = { ...x };
          attr.fieldControlName = `ctrl-${i}-${x.id}-${this.data.deliverableData.productId}`;
          attr.stringValue = '';

          if (attr.defaultValue) {
            if (attr.typeCode === AttributeTypesEnum.Bool)
              attr.boolValue = attr.defaultValue === true.toString();
            else if (attr.typeCode === AttributeTypesEnum.Number)
              attr.numericValue = new Number(attr.defaultValue);
            else
              attr.stringValue = x.defaultValue;
          }
          return attr;
        });

        deliverableAttribute.detailedAttributes.push(newArray);

      }

    }

    this.deliverableAttribute = deliverableAttribute;

  }


  public isControlInvalid(form: NgForm, controlName: string): boolean {

    return form?.controls[controlName]?.invalid ?? false;
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveDeliverable(): void {

    const boolAttribute = this.deliverableAttribute.fields.filter(x => x.typeCode === AttributeTypesEnum.Bool)
    for (const attr of boolAttribute) {
      attr.stringValue = attr.boolValue.toString();
    }

    const numericAttributes = this.deliverableAttribute.fields.filter(x => x.typeCode === AttributeTypesEnum.Number)
    for (const attr of numericAttributes) {
      if (attr.numericValue !== null && attr.numericValue !== undefined)
        attr.stringValue = attr.numericValue.toString();
      else
        attr.stringValue = "0";
    }

    for (const fields of this.deliverableAttribute.detailedAttributes) {
      const boolAttribute = fields.filter(x => x.typeCode === AttributeTypesEnum.Bool)
      for (const attr of boolAttribute) {
        attr.stringValue = attr.boolValue.toString();
      }

      const numericAttributes = fields.filter(x => x.typeCode === AttributeTypesEnum.Number)
      for (const attr of numericAttributes) {
        if (attr.numericValue !== null && attr.numericValue !== undefined)
          attr.stringValue = attr.numericValue.toString();
        else
          attr.stringValue = "0";
      }
    }

    this.dialogRef.close(this.deliverableAttribute);
  }


  onFileSelected(files: File[], field: OrderFormDeliverableFieldModel) {

    this.fileUpdated = true;

    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum File Size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
    } else {
      this.uploadFile(files[0], field);
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  uploadFile(file: File, field: OrderFormDeliverableFieldModel): void {

    if (!file) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    field.loadingFile = true;
    const formData = new FormData();
    formData.append('fileList', file);

    this.fileDescriptorService.uploadAttachMentsFolder(
      'Deliverables_Attributes',
      `Attribute_ ${field.fieldControlName}`,
      formData
    ).subscribe(
      (res: FileInfoDto[]) => {

        if (res[0]?.id && res[0]?.fileAttachmentUrl) {

          field.stringValue = res[0].id;
          field.fileValue = res[0].name;
        }

        field.loadingFile = false;
      },
      error => {
        field.loadingFile = false;
      }

    );
  }

  downloadDocument(field: OrderFormDeliverableFieldModel) {
    this.loadingService.showOverlay();

    this.fileDescriptorService
      .getDownloadToken(field.stringValue)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(field.stringValue, token)),
        finalize(() => this.loadingService.hideOverlay())
      )
      .subscribe(result => {

        downloadBlob(result, field.fileValue);
      });
  }

  onFileRemoved(field: OrderFormDeliverableFieldModel): void {

    this.fileUpdated = true;

    field.stringValue = '';
    field.fileValue = '';
  }

  public thereAreMissingFiles(): boolean {

    let missingValues = false;

    if (this.deliverableAttribute.sameConfiguration) {

      missingValues = !!this.deliverableAttribute.fields.find(x => (x.typeCode === AttributeTypesEnum.File || x.typeCode === AttributeTypesEnum.Kml) && x.isRequired && !x.stringValue)
    }
    else {

      for (const fields of this.deliverableAttribute.detailedAttributes) {

        missingValues = !!fields.find(x => (x.typeCode === AttributeTypesEnum.File || x.typeCode === AttributeTypesEnum.Kml) && x.isRequired && !x.stringValue)

        if (missingValues)
          break;
      }
    }

    return missingValues;
  }

}
