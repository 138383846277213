import { ModuleWithProviders, NgModule } from '@angular/core';
import { PILOTS_PROFILE_ROUTE_PROVIDERS } from './providers/pilot-profile-route.provider';

@NgModule()
export class PilotsServiceConfigModule {
  static forRoot(): ModuleWithProviders<PilotsServiceConfigModule> {
    return {
      ngModule: PilotsServiceConfigModule,
      providers: [
        PILOTS_PROFILE_ROUTE_PROVIDERS,
      ],
    };
  }
}
