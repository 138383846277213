import { ABP, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type {
  GetMissionFinancialRevenuesInput,
  MissionFinancialRevenueWithNavigationPropertiesDto,
} from '../../../proxy/missions-service/relationals/models';
import { MissionFinancialRevenueService } from '../../../proxy/missions-service/controllers/relationals/mission-financial-revenue.service';
import { RevenueTypeService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/revenue-type.service';
@Component({
  selector: 'lib-mission-financial-revenue',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './mission-financial-revenue.component.html',
  styles: [],
})
export class MissionFinancialRevenueComponent implements OnInit {
  data: PagedResultDto<MissionFinancialRevenueWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetMissionFinancialRevenuesInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: MissionFinancialRevenueWithNavigationPropertiesDto;

  revenueTypes: { [id: string]: string } = {};
  action:any;
  clientMaxResultCount = 10;
  pageNumber = 0;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionFinancialRevenueService,
    private confirmation: ConfirmationService,
    private revenueService: RevenueTypeService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<MissionFinancialRevenueWithNavigationPropertiesDto>) =>
    {
      this.data = list;
      console.log(this.data);
    }

    this.list.hookToQuery(getData).subscribe(setData);

    this.revenueService.getList({maxResultCount: 100}).subscribe(r =>{
      this.revenueTypes = r.items?.reduce((result, dto) => {
        result[dto.id] = dto.description;
        return result; 
      }, {} as { [id: string]: string })
    });
  }

  setAction(act: any){
    this.action = act;
  }

  clearFilters() {
    this.filters = {} as GetMissionFinancialRevenuesInput;
  }

  buildForm() {
    const { revenueTypeId, units, itemPrice, totalPrice, deliverablesId } =
      this.selected?.missionFinancialRevenue || {};

    this.form = this.fb.group({
      revenueTypeId: [revenueTypeId ?? null, []],
      units: [units ?? '0', []],
      itemPrice: [itemPrice ?? '0', []],
      totalPrice: [totalPrice ?? '0', []],
      deliverablesId: [deliverablesId ?? null, []],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.missionFinancialRevenue.id, {
          ...this.form.value,
          concurrencyStamp: this.selected.missionFinancialRevenue.concurrencyStamp,
        })
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: MissionFinancialRevenueWithNavigationPropertiesDto) {
    this.selected = record;
    this.showForm();
  }

  delete(record: MissionFinancialRevenueWithNavigationPropertiesDto) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.missionFinancialRevenue.id))
      )
      .subscribe(this.list.get);
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'MissionFinancialRevenue.xlsx');
      });
  }
}
