import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import type { enumState } from './enum-state.enum';

export interface AddressTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_ADDRESS_TYPE_ID: number;
  code?: string;
}

export interface AddressTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_ADDRESS_TYPE_ID: number;
  code?: string;
}

export interface AddressTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface AddressTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_ADDRESS_TYPE_ID: number;
  code?: string;
}

export interface AirSpaceClassificationsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_AIRSPACE_CLASS_ID?: number;
  code?: string;
}

export interface AirSpaceClassificationsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_AIRSPACE_CLASS_ID?: number;
  code?: string;
}

export interface AirSpaceClassificationsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface AirSpaceClassificationsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_AIRSPACE_CLASS_ID?: number;
  code?: string;
}

export interface AvailabilityCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface AvailabilityDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface AvailabilityExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface AvailabilityUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CaptureTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CaptureTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CaptureTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CategoriesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CategoriesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CategoriesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ConfigurationAttributeTypesCreateDto {
  description: string;
  configurationTypeId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ConfigurationAttributeTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  configurationTypeId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ConfigurationAttributeTypesUpdateDto {
  description: string;
  configurationTypeId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ConfigurationTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
  pk_CONFIGURATION_TYPE_ID?: number;
}

export interface ConfigurationTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
  pk_CONFIGURATION_TYPE_ID?: number;
}

export interface ConfigurationTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ConfigurationTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
  pk_CONFIGURATION_TYPE_ID?: number;
}

export interface ContactMethodsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ContactMethodsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ContactMethodsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ContactTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_CONTACT_TYPE_ID?: number;
  code?: string;
}

export interface ContactTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_CONTACT_TYPE_ID?: number;
  code?: string;
}

export interface ContactTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ContactTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_CONTACT_TYPE_ID?: number;
  code?: string;
}

export interface CountriesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_COUNTRY_ID: number;
  code?: string;
}

export interface CountriesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_COUNTRY_ID: number;
  code?: string;
}

export interface CountriesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CountriesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_COUNTRY_ID: number;
  code?: string;
}

export interface CustomerNotificationTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerNotificationTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerNotificationTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerStatesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerStatesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerStatesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface DepartamentsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface DepartamentsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface DepartamentsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface DeviceTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface DeviceTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface DeviceTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface DeviceTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface EmailNotificationsStatesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface EmailNotificationsStatesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface EmailNotificationsStatesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface EmailNotificationsStatesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface EquipmentTypeCreateDto {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
}

export interface EquipmentTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
}

export interface EquipmentTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface EquipmentTypeUpdateDto {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
}

export interface ExpenseTypeCreateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface ExpenseTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface ExpenseTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ExpenseTypeUpdateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface ExportsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EXPORT_TYPE_ID?: number;
  code?: string;
}

export interface ExportsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EXPORT_TYPE_ID?: number;
  code?: string;
}

export interface ExportsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ExportsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EXPORT_TYPE_ID?: number;
  code?: string;
}

export interface GetAddressTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_ADDRESS_TYPE_ID?: number;
  code?: string;
}

export interface GetAirSpaceClassificationInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_AIRSPACE_CLASS_ID?: number;
  code?: string;
}

export interface GetAvailabilitiesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetCaptureTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetCategoryInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetConfigurationAttributeTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  configurationTypeId?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetConfigurationTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
  pk_CONFIGURATION_TYPE_ID?: number;
}

export interface GetContactMethodInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetContactTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_CONTACT_TYPE_ID?: number;
  code?: string;
}

export interface GetCountryInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_COUNTRY_ID?: number;
  code?: string;
}

export interface GetCustomerNotificationTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetCustomerStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetDepartamentInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetDeviceTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetEmailNotificationsStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetEquipmentTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
}

export interface GetExpenseTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetExportInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_EXPORT_TYPE_ID?: number;
  code?: string;
}

export interface GetIndustryInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  image_Path?: string;
  name?: string;
  sortorder?: number;
  pk_INDUSTRY_TYPE_ID?: number;
  code?: string;
  industryTypeId?: string;
}

export interface GetIndustriesTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetLanguageInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  language_code?: string;
  lcid?: string;
  pk_LANGUAGE_ID?: number;
  code?: string;
}

export interface GetLevelCommunicationInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetLevelInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetMissionPilotRequestStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetMissionsTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetNotificationTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetPaymentTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetPilotExperienceLevelInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  name?: string;
  pk_EXPERIENCE_LEVEL_ID?: number;
  code?: string;
}

export interface GetPilotStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_EMPLOYEE_STATUS_ID?: number;
  code?: string;
}

export interface GetPriorityInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetProductTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_PRODUCT_TYPE?: number;
  code?: string;
}

export interface GetPushNotificationsStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetQuestionDataTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetQuestionTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetQuestionnaireV2Input {
  filterText?: string;
  groupId?: string;
}

export interface GetQuestionV2Input {
  filterText?: string;
  questionnaireId: string;
}

export interface GetQuestionTypeV2Input {
  filterText?: string;
}

export interface QuestionnaireAnswersDto {
  userId?: string;
  dataLink: string;
  questionnaireId: string;
  answers: AnswerV2CreateDto[];
}

export interface GetListAnswerV2Input {
  questionId?: string;
  answerGroupId?: string;
}

export interface GetAnswerGroupV2Input {
  userId: string;
  dataLink: string;
  questionnaireId: string;
}

export interface GetReportCategoryInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_REPORT_CATEGORY_ID?: number;
  code?: string;
}

export interface GetReportColumnTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetReportParameterTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetRequestStatusesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
}

export interface GetResponseTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetRevenueTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;

  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetServiceTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  countryId?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_STATE_ID?: number;
  abbreviation?: string;
  code?: string;
}

export interface GetSubscriptionsTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetTypeEquipmentInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_EQUIPMENT_TYPE_ID?: number;
  code?: string;
}

export interface GetCustomerAttributeTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetControlTypeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetEquipmentManufactureInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetUomInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetReasonInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface GetMissionsStatusInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface IndustriesCreateDto {
  description?: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  image_Path?: string;
  name: string;
  sortorder?: number;
  pk_INDUSTRY_TYPE_ID?: number;
  code?: string;
  industryTypeId?: string;
}

export interface IndustriesDto extends FullAuditedEntityDto<string> {
  description?: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  image_Path?: string;
  name: string;
  sortorder?: number;
  pk_INDUSTRY_TYPE_ID?: number;
  code?: string;
  industryTypeId?: string;
}

export interface IndustriesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface IndustriesUpdateDto {
  description?: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  image_Path?: string;
  name: string;
  sortorder?: number;
  pk_INDUSTRY_TYPE_ID?: number;
  code?: string;
  industryTypeId?: string;
}

export interface IndustriesTypeCreateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface IndustriesTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface IndustriesTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface IndustriesTypeUpdateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface LanguagesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  language_code?: string;
  lcid?: string;
  pk_LANGUAGE_ID?: number;
  code?: string;
}

export interface LanguagesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  language_code?: string;
  lcid?: string;
  pk_LANGUAGE_ID?: number;
  code?: string;
}

export interface LanguagesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface LanguagesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  language_code?: string;
  lcid?: string;
  pk_LANGUAGE_ID?: number;
  code?: string;
}

export interface LevelCommunicationsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface LevelCommunicationsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface LevelCommunicationsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface LevelesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface LevelesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface LevelesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionPilotRequestStatusCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionPilotRequestStatusDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionPilotRequestStatusExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionPilotRequestStatusUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionsTypeCreateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionsTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface MissionsTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionsTypeUpdateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface NotificationTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface NotificationTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface NotificationTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface PaymentTypeCreateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface PaymentTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface PaymentTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PaymentTypeUpdateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface PilotExperienceLevelesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  name?: string;
  pk_EXPERIENCE_LEVEL_ID?: number;
  code?: string;
}

export interface PilotExperienceLevelesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  name?: string;
  pk_EXPERIENCE_LEVEL_ID?: number;
  code?: string;
}

export interface PilotExperienceLevelesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PilotExperienceLevelesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  name?: string;
  pk_EXPERIENCE_LEVEL_ID?: number;
  code?: string;
}

export interface PilotStatesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EMPLOYEE_STATUS_ID?: number;
  code?: string;
}

export interface PilotStatesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EMPLOYEE_STATUS_ID?: number;
  code?: string;
}

export interface PilotStatesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PilotStatesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EMPLOYEE_STATUS_ID?: number;
  code?: string;
}

export interface PrioritiesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface PrioritiesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface PrioritiesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ProductTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_PRODUCT_TYPE?: number;
  code?: string;
}

export interface ProductTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_PRODUCT_TYPE?: number;
  code?: string;
}

export interface ProductTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ProductTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_PRODUCT_TYPE?: number;
  code?: string;
}

export interface PushNotificationsStatesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface PushNotificationsStatesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface PushNotificationsStatesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PushNotificationsStatesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface QuestionDataTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface QuestionDataTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface QuestionDataTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface QuestionTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface QuestionTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface QuestionV2Dto extends FullAuditedEntityDto<string> {
  title: string;
  description: string;
  options: string;
  triggers: string;
  isOptional: boolean;
  orderIndex: number;
  questionTypeV2Id: string;
  questionnaireV2Id: string;
  parentId?: string;
  code?: string;
}

export interface QuestionnaireV2Dto extends FullAuditedEntityDto<string> {
  name: string;
  groupV2Id: string;
  allowEdit: boolean;
}

export interface QuestionTypeV2Dto extends FullAuditedEntityDto<string> {
  name: string;
}

export interface AnswerV2Dto extends FullAuditedEntityDto<string> {
  questionV2Id: string;
  answerGroupV2Id: string;
  answer: string;
}

export interface AnswerGroupV2Dto extends FullAuditedEntityDto<string> {
  userId: string;
  dataLink: string;
  questionnaireV2Id: string;
}

export interface AnswerV2CreateDto {
  questionId: string;
  answer: string;
}

export interface QuestionTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReportCategoriesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_REPORT_CATEGORY_ID?: number;
  code?: string;
}

export interface ReportCategoriesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_REPORT_CATEGORY_ID?: number;
  code?: string;
}

export interface ReportCategoriesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ReportCategoriesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_REPORT_CATEGORY_ID?: number;
  code?: string;
}

export interface ReportColumnTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReportColumnTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReportColumnTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ReportColumnTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReportParameterTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReportParameterTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReportParameterTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ReportParameterTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface RequestStatusCreateDto {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
}

export interface RequestStatusDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
}

export interface RequestStatusExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface RequestStatusUpdateDto {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
}

export interface ResponseTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ResponseTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ResponseTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface RevenueTypeCreateDto {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface RevenueTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface RevenueTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface RevenueTypeUpdateDto {
  description: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface ServiceTypeCreateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface ServiceTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface ServiceTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ServiceTypeUpdateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface StatesCreateDto {
  description: string;
  countryId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_STATE_ID?: number;
  abbreviation?: string;
  code?: string;
}

export interface StatesDto extends FullAuditedEntityDto<string> {
  description: string;
  countryId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_STATE_ID?: number;
  abbreviation?: string;
  code?: string;
}

export interface StatesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface StatesUpdateDto {
  description: string;
  countryId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_STATE_ID?: number;
  abbreviation?: string;
  code?: string;
}

export interface SubscriptionsTypeCreateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  code?: string;
}

export interface SubscriptionsTypeDto extends FullAuditedEntityDto<string> {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface SubscriptionsTypeExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface SubscriptionsTypeUpdateDto {
  description: string;
  state?: enumState;
  migrated?: boolean;
  idOld?: number;
  concurrencyStamp?: string;
  code?: string;
}

export interface TypeEquipmentsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EQUIPMENT_TYPE_ID?: number;
  code?: string;
}

export interface TypeEquipmentsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EQUIPMENT_TYPE_ID?: number;
  code?: string;
}

export interface TypeEquipmentsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface TypeEquipmentsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_EQUIPMENT_TYPE_ID?: number;
  code?: string;
}

export interface CustomerAttributeTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerAttributeTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface CustomerAttributeTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CustomerAttributeTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ControlTypesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ControlTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ControlTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ControlTypesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface EquipmentManufacturesCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_ADDRESS_TYPE_ID: number;
  code?: string;
}

export interface EquipmentManufacturesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_ADDRESS_TYPE_ID: number;
  code?: string;
}

export interface EquipmentManufacturesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface EquipmentManufacturesUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface UomCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface UomDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface UomExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface UomUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReasonsCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReasonsDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ReasonsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ReasonsUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionsStatusCreateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionsStatusDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface MissionsStatusExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionsStatusUpdateDto {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  code?: string;
}

export interface ResponseTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface QuestionTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface QuestionDataTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PrioritiesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CustomerNotificationTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface NotificationTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PilotExperienceLevelesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface LevelCommunicationsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface LevelesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface DepartamentsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CustomerStatesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ContactMethodsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ConfigurationAttributeTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ReportCategoriesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CaptureTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CategoriesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ActionCreateDto {
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
}

export interface ActionDto extends FullAuditedEntityDto<string> {
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
  concurrencyStamp?: string;
}

export interface ActionExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ActionCreateDto {
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
}

export interface ActionDto extends FullAuditedEntityDto<string> {
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
  concurrencyStamp?: string;
}

export interface ActionExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ActionOrderCreateDto {
  statusId?: string;
  statusName?: string;
  actionId?: string;
  actionName?: string;
  roleId?: string;
  roleName?: string;
  order?: number;
}

export interface ActionOrderDto extends FullAuditedEntityDto<string> {
  statusId?: string;
  statusName?: string;
  actionId?: string;
  actionName?: string;
  roleId?: string;
  roleName?: string;
  order?: number;
  concurrencyStamp?: string;
}

export interface ActionOrderExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ActionOrderUpdateDto {
  statusId?: string;
  statusName?: string;
  actionId?: string;
  actionName?: string;
  roleId?: string;
  roleName?: string;
  order?: number;
  concurrencyStamp?: string;
}

export interface ActionUpdateDto {
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
  concurrencyStamp?: string;
}

export interface GetActionOrdersInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  statusId?: string;
  actionId?: string;
  roleId?: string;
  orderMin?: number;
  orderMax?: number;
}

export interface GetActionsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
}

export interface GetStatusesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  code?: string;
  state?: enumState;
}

export interface StatusCreateDto {
  description?: string;
  code?: string;
  state?: enumState;
}

export interface StatusDto extends FullAuditedEntityDto<string> {
  description?: string;
  code?: string;
  state?: enumState;
  concurrencyStamp?: string;
}

export interface StatusExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface StatusUpdateDto {
  description?: string;
  code?: string;
  state?: enumState;
  concurrencyStamp?: string;
}

export interface ActionOrderCreateDto {
  statusId?: string;
  actionId?: string;
  roleId?: string;
  order?: number;
}

export interface ActionOrderDto extends FullAuditedEntityDto<string> {
  statusId?: string;
  actionId?: string;
  roleId?: string;
  order?: number;
  concurrencyStamp?: string;
}

export interface ActionOrderExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ActionOrderUpdateDto {
  statusId?: string;
  actionId?: string;
  roleId?: string;
  order?: number;
  concurrencyStamp?: string;
}

export interface ActionUpdateDto {
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
  concurrencyStamp?: string;
}

export interface GetActionOrdersInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  statusId?: string;
  actionId?: string;
  roleId?: string;
  orderMin?: number;
  orderMax?: number;
}

export interface GetActionsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  code?: string;
  state?: number;
  helperText?: string;
}

export interface GetStatusesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  code?: string;
  state?: enumState;
}

export interface StatusCreateDto {
  description?: string;
  code?: string;
  state?: enumState;
}

export interface StatusDto extends FullAuditedEntityDto<string> {
  description?: string;
  code?: string;
  state?: enumState;
  concurrencyStamp?: string;
}

export interface StatusExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface StatusUpdateDto {
  description?: string;
  code?: string;
  state?: enumState;
  concurrencyStamp?: string;
}

export interface ProfileImageDto {
  type: number;
  source?: string;
  fileContent: Blob;
}

export interface StatesShowPilotsDto extends FullAuditedEntityDto<string> {
  state: number;
  description: string;
  code: string;
}

export interface GenderDto extends FullAuditedEntityDto<string> {
  id: string;
  name: string;
  description?: string;
  state: enumState;
  migrated: boolean;
  idOld: number;
  code: string;
}

export interface GetGendersInput extends PagedAndSortedResultRequestDto {
  FilterText?: string;
  Name?: string;
  Description?: string;
  State?: enumState;
  Migrated?: boolean;
  IdOld?: number;
  Code?: string;
}

export interface EthnicityDto extends FullAuditedEntityDto<string> {
  id: string;
  name: string;
  description?: string;
  state: enumState;
  migrated: boolean;
  idOld: number;
  code: string;
}

export interface GetEthnicityInput extends PagedAndSortedResultRequestDto {
  FilterText?: string;
  Name?: string;
  Description?: string;
  State?: enumState;
  Migrated?: boolean;
  IdOld?: number;
  Code?: string;
}

export interface GenderDto{
  id: string;
  name: string;
}

export interface EthnicityDto{
  id: string;
  name: string;
}

export interface CountriesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_COUNTRY_ID: number;
  code?: string;
}

export interface GetCountryInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_COUNTRY_ID?: number;
  code?: string;
}

export interface StatesDto extends FullAuditedEntityDto<string> {
  description: string;
  countryId: string;
  state: enumState;
  migrated: boolean;
  idOld?: number;
  pk_STATE_ID?: number;
  abbreviation?: string;
  code?: string;
}

export interface GetStateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  countryId?: string;
  state?: number;
  migrated?: boolean;
  idOld?: number;
  pk_STATE_ID?: number;
  abbreviation?: string;
  code?: string;
}

export interface GetSensorTypesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  state?: enumState;
  code?: string;
  isPaginated?: boolean;
}

export interface SensorTypesCreateDto {
  description: string;
  state: enumState;
  code?: string;
  orderPosition?: number;
}

export interface SensorTypesDto extends FullAuditedEntityDto<string> {
  description: string;
  state: enumState;
  code?: string;
  orderPosition?: number;
}

export interface SensorTypesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface SensorTypesUpdateDto {
  description: string;
  state: enumState;
  code?: string;
  orderPosition?: number;
}