import { ColumnColoringRules } from '../components/column-stylizer/column-stylizer.component';

export const statusRules: ColumnColoringRules[] = [
  {
    columnValue: 0,
    softValue: 'Rejected',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'rgb(255, 230, 230)',
      color: 'crimson',
      'border-radius': '0.2rem',
    },
  },
  {
    columnValue: 1,
    softValue: 'Accepted',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'rgb(230, 255, 230)',
      color: 'green',
      'border-radius': '0.2rem',
    },
  },
];

export const stateRules: ColumnColoringRules[] = [
  {
    columnValue: 1,
    softValue: 'Enabled',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'rgb(230, 255, 230)',
      color: 'green',
      'border-radius': '0.2rem',
    },
  },
  {
    columnValue: 0,
    softValue: 'Disabled',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'rgb(255, 230, 230)',
      color: 'crimson',
      'border-radius': '0.2rem',
    },
  },
  {
    columnValue: 'default',
    softValue: 'Undefined',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'lightgray',
      color: '#333',
      'border-radius': '0.2rem',
    },
  },
];

export const migratedRules: ColumnColoringRules[] = [
  {
    columnValue: 1,
    containerClass: 'text-success',
    valueClass: 'fa fa-check',
  },
  {
    columnValue: 0,
    containerClass: 'text-danger',
    valueClass: 'fa fa-times',
  },
  {
    columnValue: 'default',
    valueClass: 'fa fa-times',
  },
];

export const assignedRules: ColumnColoringRules[] = [
  {
    columnValue: 1,
    containerClass: 'text-success',
    valueClass: 'fa fa-check',
  },
  {
    columnValue: 0,
    containerClass: 'text-danger',
    valueClass: 'fa fa-times',
  },
  {
    columnValue: 'default',
    valueClass: 'fa fa-times',
  },
];

export const PilotStateRules: ColumnColoringRules[] = [
  {
    columnValue: 'Active',
    softValue: 'Active',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'rgb(230, 255, 230)',
      color: 'green',
      'border-radius': '0.2rem',
    },
  },
  {
    columnValue: 'InActive',
    softValue: 'Inactive',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'rgb(255, 230, 230)',
      color: 'crimson',
      'border-radius': '0.2rem',
    },
  },
  {
    columnValue: 'default',
    softValue: 'Undefined',
    valueClass: 'badge',
    valueStyle: {
      'background-color': 'lightgray',
      color: '#333',
      'border-radius': '0.2rem',
    },
  },
];

export const RatingRules: ColumnColoringRules[] = [];

export const contactTypeRules: ColumnColoringRules[] = [
  {
    columnValue: 0,
    softValue: 'SubClient',
    valueClass: 'datatable-body-cell-label',
  },
  {
    columnValue: 1,
    softValue: 'Company',
    valueClass: 'datatable-body-cell-label',
  },
  {
    columnValue: 2,
    softValue: 'External',
    valueClass: 'datatable-body-cell-label',
  },
  {
    columnValue: 'default',
    softValue: 'Undefined',
    valueClass: 'datatable-body-cell-label',
  },
];
