import { Component, OnInit } from '@angular/core';
import { DeliverablesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.scss'],
})
export class FinancialsComponent implements OnInit {

  deliverables: { [id: string] : string; } = {};

  constructor(private delivService : DeliverablesService) {

  }

  ngOnInit(): void {
    this.delivService.getList({ maxResultCount: 100}).subscribe((data)=>{
      this.deliverables = data.items?.reduce((result, r) => {
        result[r.id] = r.name;
        return result;
      }, {} as { [id: string]: string })
    })
  }
}
