export interface QueuedFilePreviewData {
  name: string;
  webkitRelativePath?: string;
  directoryId: string;
  progress: number;
  status: QueuedFilePreviewStatus;
  minutesUntilRetry: number;
  startDate?: Date;
  size?: number;
  uploadNetworkRate: string;
}

export enum QueuedFilePreviewStatus {
  Awaiting,
  Uploading,
  Finishing,
  Succeeded,
  Canceled,
  Failed,
  Retrying,
  RetryingFailed,
}
