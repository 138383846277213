import { OrderFormContactModel } from './order-form-contact.model';
import { OrderFormDelirableModel } from './order-form-delirable.model';
import { OrderFormProductModel } from './order-form-product-model';
import { OrderFormSiteModel } from './order-form-site.model';

export class OrderFormModel {
  customerId: string = '';
  customerDescription: string = '';
  customerContactId: string = '';
  customerContactDescription: string = '';
  customerContactEmail: string = '';
  customerContactNumber: string = '';
  subcustomerId: string = '';
  subclientName: string = '';
  billingCustomerId: string = '';
  billingCustomerName: string = '';
  billingCustomerContactId: string = '';
  billingCustomerContactEmail: string = '';
  billingCustomerConctactNumber: string = '';
  additionalContacts: OrderFormContactModel[] = [];
  orderSites: OrderFormSiteModel[] = [];
  priorityId: string = '';
  priorityDescription: string = '';
  industryid: string = '';
  industryDescription: string = '';
  products: OrderFormProductModel[] = [];
  additionalDeliverables: OrderFormDelirableModel[] = [];
  projectName: string = '';
  portfolioName: string = '';
  packageId: string = '';
  productDescription: string = '';
}
