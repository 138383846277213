import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetReportInput, ReportsCreateDto, ReportsDto, ReportsExcelDownloadDto, ReportsUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  apiName = 'pilotsService';
  

  create = (input: ReportsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportsDto>({
      method: 'POST',
      url: '/api/pilots-service/report',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/report/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportsDto>({
      method: 'GET',
      url: `/api/pilots-service/report/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/report/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetReportInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ReportsDto>>({
      method: 'GET',
      url: '/api/pilots-service/report',
      params: { filterText: input.filterText, description: input.description, reportCategoryId: input.reportCategoryId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: ReportsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/report/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: ReportsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ReportsDto>({
      method: 'PUT',
      url: `/api/pilots-service/report/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
