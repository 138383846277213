import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AnswerV2Dto, GetListAnswerV2Input, QuestionnaireAnswersDto } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class AnswerV2Service {
  apiName = 'CoreService';

  PostAnswers = (input: QuestionnaireAnswersDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AnswerV2Dto>>({
      method: 'POST',
      url: '/api/core-service/answer-v2',
      body: input
    },
      { apiName: this.apiName, ...config });

  UpdateAnswers = (input: QuestionnaireAnswersDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AnswerV2Dto>>({
      method: 'PUT',
      url: '/api/core-service/answer-v2',
      body: input
    },
      { apiName: this.apiName, ...config });

  GetAnswers = (input: GetListAnswerV2Input, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AnswerV2Dto>>({
      method: 'GET',
      url: '/api/core-service/answer-v2/get-list',
      params: input
    },
      { apiName: this.apiName, ...config });

  constructor(private restService: RestService) { }
}
