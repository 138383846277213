import { mapEnumToOptions } from '@abp/ng.core';

export enum enumState {
    Disabled = 0,
    Enabled = 1
}

export const EnumStateOptions = mapEnumToOptions(enumState);


