import { Component, Input, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormHelpers } from 'projects/flyguys-pilot/src/app/form-helpers';
import { OrderFormDelirableModel } from '../../model/order-form-delirable.model';

@Component({
  selector: 'app-deliverable',
  templateUrl: './deliverable.component.html',
  styleUrls: ['./deliverable.component.scss'],
})
export class DeliverableComponent {
  @Input() deliverable: OrderFormDelirableModel;
  formDelirable: FormGroup;

  constructor() {
    this.formDelirable = FormHelpers.buildValidatorsDeliverables();
  }
}
