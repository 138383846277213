<div class="container">
  <div class="row">
    <div class="col-6">
      <app-info-card-with-action
        [icon]="'person_raised_hand'"
        [title]="'Requesting Customer'"
        [actionIcon]="'edit'"
        (actionClicked)="openRequestingModal()"
      >
        <div>
          <app-subtitle-description
            [subtitle]="'Company Name'"
            [description]="requestingCustomer.companyName"
          >
          </app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'Project Manager'"
            [description]="requestingCustomer.projectManager"
          >
          </app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'Project Manager Email'"
            [description]="requestingCustomer.projectManagerEmail"
          >
          </app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'Project Manager Phone'"
            [description]="requestingCustomer.projectManagerPhone"
          >
          </app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'Subclient Name'"
            [description]="requestingCustomer.subclientName"
          >
          </app-subtitle-description>
        </div>
      </app-info-card-with-action>
    </div>
    <div class="col-6 card-customers">
      <app-info-card-with-action
        [icon]="'person_raised_hand'"
        [title]="'Billing Customer'"
        [actionIcon]="'edit'"
        (actionClicked)="openBillingModal()"
      >
        <div>
          <app-subtitle-description
            [subtitle]="'Billing Customer Name'"
            [description]="billingCustomer.customerName"
          >
          </app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'Billing Customer Email'"
            [description]="billingCustomer.customerEmail"
          >
          </app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'Billing Customer Phone'"
            [description]="billingCustomer.customerPhone"
          >
          </app-subtitle-description>
          <app-subtitle-description
            *ngIf="billingCustomer.sameAsSubclient === true"
            [subtitle]="'Subclient Name'"
            [description]="requestingCustomer.subclientName"
          >
          </app-subtitle-description>
        </div>
      </app-info-card-with-action>
    </div>
  </div>
  <div class="row">
    <div class="header d-flex justify-content-between align-items-center">
      <h3 class="title mb-0">Contacts</h3>
      <button class="btn btn-primary button-text" (click)="openContactModal()">
        <span class="material-symbols-rounded">add</span> Add New
      </button>
    </div>

    <ngx-datatable [rows]="contacts" [columns]="contactColumns" [scrollbarH]="true" default>
      <ngx-datatable-column name="Contact From" prop="contactFrom">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.contactFrom }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Name" prop="name">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Email" prop="email">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.email }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Phone" prop="phone">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.phone }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Share Data" prop="sharedData">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.sharedData ? 'Yes' : 'No' }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Actions">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <i
            _ngcontent-kii-c355=""
            style="margin: 10px 50px 20px 0"
            class="edit"
            (click)="openContactModal(row)"
          ></i>
          &emsp;
          <i _ngcontent-kii-c355="" class="fa fa-trash-o" (click)="delete(row)"></i>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <ng-template #contactModal>
      <div class="modal-content container">
        <div
          _ngcontent-uhd-c48=""
          id="abp-modal-header"
          class="modal-header abp-modal-header ng-star-inserted"
        >
          <h3 class="ng-star-inserted">Contact</h3>
          &ZeroWidthSpace;
          <button
            _ngcontent-uhd-c48=""
            id="abp-modal-close-button"
            type="button"
            aria-label="Close"
            class="btn-sm btn-close"
            (click)="closeModal()"
          ></button>
        </div>
        <div _ngcontent-uhd-c48="" id="abp-modal-body" class="modal-body ng-star-inserted">
          <form
            novalidate=""
            id="contact-types"
            validateonsubmit=""
            class="ng-pristine ng-invalid ng-star-inserted ng-touched"
          >
            <div class="mt-2 fade-in-top">
              <div class="mb-3">
                <label for="contact-types-state" class="form-label"> Contact From * </label>
                <select
                  id="air-space-classifications-state"
                  class="form-control ng-untouched ng-pristine ng-valid"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="contact.contactFrom"
                >
                  <option value="Company" class="ng-star-inserted">Company</option>
                  <option value="Subclient" class="ng-star-inserted">Subclient</option>
                  <option value="External" class="ng-star-inserted">External</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="contact-types-description" class="form-label"> Full Name * </label>
                <input
                  id="contact-types-description"
                  [(ngModel)]="contact.name"
                  maxlength="100"
                  [ngModelOptions]="{ standalone: true }"
                  autofocus=""
                  class="form-control ng-pristine ng-invalid ng-touched"
                />
              </div>

              <div class="mb-3">
                <label for="contact-types-description" class="form-label"> Email * </label>
                <input
                  id="contact-types-description"
                  [(ngModel)]="contact.email"
                  type="email"
                  [ngModelOptions]="{ standalone: true }"
                  maxlength="100"
                  autofocus=""
                  class="form-control ng-pristine ng-invalid ng-touched"
                />
              </div>

              <div class="mb-3">
                <label for="contact-types-description" class="form-label"> Phone * </label>
                <input
                  id="contact-types-description"
                  [(ngModel)]="contact.phone"
                  type="number"
                  [ngModelOptions]="{ standalone: true }"
                  maxlength="100"
                  autofocus=""
                  class="form-control ng-pristine ng-invalid ng-touched"
                />
              </div>

              <div class="mb-3">
                <input
                  name="sharedData"
                  type="checkbox"
                  [(ngModel)]="contact.sharedData"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Share data with this contact"
                  style="margin: 10px 10px 20px 0"
                />
                <label for="contact-types-description" class="form-label">
                  Share data with this contact
                </label>
              </div>
            </div>
          </form>
        </div>
        <div _ngcontent-uhd-c48="" id="abp-modal-footer" class="modal-footer ng-star-inserted">
          <button
            type="button"
            abpclose=""
            style="margin: 0px 10px 0 0"
            class="btn btn-secondary ng-star-inserted"
            (click)="closeModal()"
          >
            Cancel
          </button>
          <button
            id=""
            type="submit"
            form="contact-types"
            class="btn btn-primary"
            (click)="saveContact()"
          >
            <i class="me-1 fa fa-check"></i> Save
          </button>
        </div>
        <br />
        <br />
      </div>
    </ng-template>
  </div>
</div>

<ng-template #requestingModal>
  <div class="modal-content container">
    <div
      _ngcontent-uhd-c48=""
      id="abp-modal-header"
      class="modal-header abp-modal-header ng-star-inserted"
    >
      <h3 class="ng-star-inserted">Edit Requesting Customer</h3>
      &ZeroWidthSpace;
      <button
        _ngcontent-uhd-c48=""
        id="abp-modal-close-button"
        type="button"
        aria-label="Close"
        class="btn-sm btn-close"
        (click)="closeModal()"
      ></button>
    </div>
    <div _ngcontent-uhd-c48="" id="abp-modal-body" class="modal-body ng-star-inserted">
      <form
        novalidate=""
        id="contact-types"
        validateonsubmit=""
        class="ng-pristine ng-invalid ng-star-inserted ng-touched"
      >
        <div class="mt-2 fade-in-top">
          <div class="mb-3">
            <label for="contact-types-description" class="form-label"> Company Name * </label>
            <input
              id="contact-types-description"
              [(ngModel)]="requestingCustomer.companyName"
              maxlength="100"
              [ngModelOptions]="{ standalone: true }"
              autofocus=""
              class="form-control ng-pristine ng-invalid ng-touched"
            />
          </div>

          <div class="mb-3">
            <label for="contact-types-description" class="form-label"> Project Manager * </label>
            <input
              id="contact-types-description"
              [(ngModel)]="requestingCustomer.projectManager"
              [ngModelOptions]="{ standalone: true }"
              maxlength="100"
              autofocus=""
              class="form-control ng-pristine ng-invalid ng-touched"
            />
          </div>

          <div class="row mb-3">
            <div class="col-6">
              <label for="contact-types-description" class="form-label">
                Project Manager Email *
              </label>
              <input
                id="contact-types-description"
                [(ngModel)]="requestingCustomer.projectManagerEmail"
                type="email"
                [ngModelOptions]="{ standalone: true }"
                maxlength="100"
                autofocus=""
                class="form-control ng-pristine ng-invalid ng-touched"
              />
            </div>

            <div class="col-6">
              <label for="contact-types-description" class="form-label">
                Project Manager Phone *
              </label>
              <input
                id="contact-types-description"
                [(ngModel)]="requestingCustomer.projectManagerPhone"
                [ngModelOptions]="{ standalone: true }"
                maxlength="100"
                autofocus=""
                class="form-control ng-pristine ng-invalid ng-touched"
              />
            </div>
          </div>
          <div class="mb-3">
            <label for="contact-types-description" class="form-label"> SubclientName</label>
            <input
              id="contact-types-description"
              [(ngModel)]="requestingCustomer.subclientName"
              [ngModelOptions]="{ standalone: true }"
              maxlength="100"
              autofocus=""
              class="form-control ng-pristine ng-invalid ng-touched"
            />
          </div>
        </div>
      </form>
    </div>
    <div _ngcontent-uhd-c48="" id="abp-modal-footer" class="modal-footer ng-star-inserted">
      <button
        type="button"
        abpclose=""
        style="margin: 0px 10px 0 0"
        class="btn btn-secondary ng-star-inserted"
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        id=""
        type="submit"
        form="contact-types"
        class="btn btn-primary"
        (click)="saveRequestingCustomer()"
      >
        <i class="me-1 fa fa-check"></i> Save
      </button>
    </div>
    <br />
    <br />
  </div>
</ng-template>

<ng-template #billingModal>
  <div class="modal-content container">
    <div
      _ngcontent-uhd-c48=""
      id="abp-modal-header"
      class="modal-header abp-modal-header ng-star-inserted"
    >
      <h3 class="ng-star-inserted">Billing Requesting Customer</h3>
      &ZeroWidthSpace;
      <button
        _ngcontent-uhd-c48=""
        id="abp-modal-close-button"
        type="button"
        aria-label="Close"
        class="btn-sm btn-close"
        (click)="closeModal()"
      ></button>
    </div>
    <div _ngcontent-uhd-c48="" id="abp-modal-body" class="modal-body ng-star-inserted">
      <form
        novalidate=""
        id="contact-types"
        validateonsubmit=""
        class="ng-pristine ng-invalid ng-star-inserted ng-touched"
      >
        <div class="mt-2 fade-in-top">
          <div class="mb-3">
            <label for="contact-types-description" class="form-label">
              Billing Customer Name *
            </label>
            <input
              id="contact-types-description"
              [(ngModel)]="billingCustomer.customerName"
              maxlength="100"
              [ngModelOptions]="{ standalone: true }"
              autofocus=""
              class="form-control ng-pristine ng-invalid ng-touched"
            />
          </div>

          <div class="row mb-3">
            <div class="col-6">
              <label for="contact-types-description" class="form-label">
                Billing Customer Email *
              </label>
              <input
                id="contact-types-description"
                [(ngModel)]="billingCustomer.customerEmail"
                type="email"
                [ngModelOptions]="{ standalone: true }"
                maxlength="100"
                autofocus=""
                class="form-control ng-pristine ng-invalid ng-touched"
              />
            </div>

            <div class="col-6">
              <label for="contact-types-description" class="form-label">
                Billing Customer Phone Number *
              </label>
              <input
                id="contact-types-description"
                [(ngModel)]="billingCustomer.customerPhone"
                [ngModelOptions]="{ standalone: true }"
                maxlength="100"
                autofocus=""
                class="form-control ng-pristine ng-invalid ng-touched"
              />
            </div>
          </div>

          <div class="mb-3">
            <input
              name="sameAsSubclient"
              type="checkbox"
              [(ngModel)]="billingCustomer.sameAsSubclient"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Same as sub client requesting"
              style="margin: 10px 10px 20px 0"
            />
            <label for="contact-types-description" class="form-label">
              Same as sub client requesting
            </label>
          </div>
        </div>
      </form>
    </div>
    <div _ngcontent-uhd-c48="" id="abp-modal-footer" class="modal-footer ng-star-inserted">
      <button
        type="button"
        abpclose=""
        style="margin: 0px 10px 0 0"
        class="btn btn-secondary ng-star-inserted"
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        id=""
        type="submit"
        form="contact-types"
        class="btn btn-primary"
        (click)="saveBillingCustomer()"
      >
        <i class="me-1 fa fa-check"></i> Save
      </button>
    </div>
    <br />
    <br />
  </div>
</ng-template>
