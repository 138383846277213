import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { OrderFormSiteModel } from '../model/order-form-site.model';
import { FlyguysMapComponent, FlyguysMapMarker } from '@flyguys/map';

@Component({
  selector: 'app-mission-overview',
  templateUrl: './mission-overview.component.html',
  styleUrls: ['./mission-overview.component.scss'],
})
export class MissionOverviewComponent implements AfterViewInit {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  @Input() mission!: OrderFormSiteModel;

  ngAfterViewInit(): void {
    const marker: FlyguysMapMarker = {
      id: 1,
      lat: Number(this.mission.loclatitude),
      lng: Number(this.mission.loclongitude),
    };

    this.map.addMarker(marker, true);
  }

  /**
   * Indicates if one of the preferences is set
   * @returns boolean
   */
  hasPreferredContactMethod(): boolean {
    return (this.mission.preferredContactPhone || this.mission.preferredContactPhone) as boolean;
  }

  /**
   * Indicates if the level of coordination is set
   * @returns boolean
   */
  hasLevelOfCoordination(): boolean {
    return (this.mission.levelCoordinationCoordinate ||
      this.mission.levelCoordinationMeet ||
      this.mission.levelCoordinationNotify) as boolean;
  }
}
