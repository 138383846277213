import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
    GetOrderHistoryInput,
  GetOrderInput,
  OrdersCreateDto,
  OrdersDto,
  OrdersExcelDownloadDto,
  OrdersHistoryDto,
  OrdersUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { OrderRequestDTO } from 'projects/flyguys-pilot/src/app/components/orders/dto/order-request.dto';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  apiName = 'missionsService';

  create = (input: OrdersCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/order/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'GET',
        url: `/api/missions-service/order/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/order/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: OrdersExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/order/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetOrderInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<OrdersDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/order',
        params: {
          filterText: input.filterText,
          portfolioId: input.portfolioId,
          requestingCustomerId: input.requestingCustomerId,
          billingCustomerId: input.billingCustomerId,
          endCustomerId: input.endCustomerId,
          totalPriceMin: input.totalPriceMin,
          totalPriceMax: input.totalPriceMax,
          industryId: input.industryId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getJoinList = (input: GetOrderHistoryInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<OrdersHistoryDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/order/pilots/'+input.pilotId,
        params: {
          filterText: input.filterText,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
          name : input.name,
          type : input.type,
          customer : input.customer,
          location : input.location,
          numberMissions : input.numberMissions,
          lastCaptureDate : input.lastCaptureDate,
          cumulativeRating : input.cumulativeRating,
          industryId : input.industryId,
          industryDescription : input.industryDescription
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: OrdersUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/order/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  createOrderRequest = (input: OrderRequestDTO, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order/create-mission',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
