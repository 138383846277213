import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}
  sessionImpersonateLabel = 'impersonate';
  cookieAccessToken = 'cookie_access_token';
  cookieIdUser = 'cookie_id_user';

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(error => {
        if (this.catchImpersonateUser(req)) {
          return new Observable<any>();
        }

        throw new HttpErrorResponse({
          error: error?.error?.error_description,
          headers: req.headers,
          status: error?.status ?? 500,
          statusText: error?.statusText,
          url: req.url,
        });
      })
    );
  }

  catchImpersonateUser(req: HttpRequest<any>): boolean {
    if (!req.url.includes('connect/token')) {
      return false;
    }

    const impersonateLabel = sessionStorage.getItem(this.sessionImpersonateLabel) ?? '0';
    const cookieAccessToken = this.getCookie(this.cookieAccessToken);
    const cookieUserId = this.getCookie(this.cookieIdUser)

    if (impersonateLabel == '1' || cookieAccessToken  || cookieUserId) {
      return true;
    }

    return false;
  }

  getCookie(nombre: string) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [key, value] = cookie.trim().split('=');
      if (key === nombre) {        
        const finalValue = decodeURIComponent(value);
        return finalValue;
      }
    }
    return null;
  }

  delete_cookie(name: string) {
    document.cookie = `${name}=expired;domain=flyguys.com;path=/`;    
  }
}