<mat-card class="card">
  <div class="card-body">
    <div class="d-flex justify-content-between mb-2">
      <h3 class="fs-16 bold">{{ 'pilotsService::Equipment' | abpLocalization }}</h3>
      <button
        *abpPermission="'pilotsService.PilotInventorie.Create && General.Common.Create'"
        mat-flat-button
        class="common-button"
        type="button"
        (click)="showForm()"
      >
        <mat-icon aria-hidden="false" fontIcon="add"></mat-icon>
        {{ 'pilotsService::AddEquipment' | abpLocalization }}
      </button>
    </div>
    <ngx-datatable
      class="flg-grid-table flg-grid-table--borderless"
      [headerHeight]="45"
      [footerHeight]="55"
      [rowHeight]="45"
      [rows]="equipments.items"
      [count]="equipments.totalCount"
      [list]="list"
      default
    >
      <ngx-datatable-column
        name="{{ 'pilotsService::Type' | abpLocalization }}"
        prop="equipmentType"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.equipmentType }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::Equipment' | abpLocalization }}"
        prop="equipmentName"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.equipmentName }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'pilotsService::Model' | abpLocalization }}" prop="model">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.model }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::FAARegistration' | abpLocalization }}"
        prop="faaRegistration "
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.faaRegistration }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'pilotsService::Status' | abpLocalization }}" prop="state">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <app-column-stylizer
            [columnValue]="row.state"
            [typeColumn]="'text'"
            [columnRules]="stateRules"
          ></app-column-stylizer>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [name]="'AbpUi::Actions' | abpLocalization"
        [maxWidth]="110"
        [width]="110"
        [sortable]="false"
      >
        <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
          <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</mat-card>

<ng-template #modal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{
        (isEditing ? 'pilotsService::EditPilotEquipment' : 'pilotsService::NewPilotInventories')
          | abpLocalization
      }}
    </ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form" id="pilot-inventories" skipValidation>
          <mat-form-field class="w-100">
            <mat-label> {{ 'pilotsService::EquimentId' | abpLocalization }} </mat-label>

            <input type="text" matInput formControlName="equimentId" [matAutocomplete]="auto" />

            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="formatEquipment.bind(this)"
              [hideSingleSelectionIndicator]="true"
            >
              <mat-option
                *ngFor="let equipment of filteredEquipment | async"
                [value]="equipment.id"
              >
                {{ equipment.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <p class="text-danger mb-3" *ngIf="equipmentExists">Equipment already exists</p>

          <mat-form-field class="w-100">
            <mat-label> {{ 'pilotsService::FAARegistration' | abpLocalization }} </mat-label>

            <input matInput formControlName="fAARegistration" />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> {{ 'pilotsService::Status' | abpLocalization }} </mat-label>

            <mat-select formControlName="state">
              <mat-option [value]="0"> Disabled </mat-option>
              <mat-option [value]="1"> Enabled </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitForm()"
        [disabled]="form.invalid || isModalBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
