import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule()
export class CoreServiceConfigModule {
  static forRoot(): ModuleWithProviders<CoreServiceConfigModule> {
    return {
      ngModule: CoreServiceConfigModule,
      providers: [ ],
    };
  }
}
