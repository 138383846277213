<div class="container">
  <div class="row justify-content-center">
    <h5 class="p-3 mb-0">{{ modalConfig.title || 'Add Contact ' }}</h5>
    <mat-dialog-content class="contact-content">
      <form [formGroup]="formOrderContact">
        <mat-form-field class="w-100">
          <mat-label>Contact From</mat-label>
          <mat-select name="contactFromId" [(ngModel)]="ordercontactModel.contactFromId"
            (ngModelChange)="setDescriptionFromId(ordercontactModel.contactFromId,dataContactFrom,'contactFromDescription','description')"
            formControlName="contactFromDescription">
            <mat-option *ngFor="let data of dataContactFrom" [value]="data.value">
              {{ data.description }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="formOrderContact.get('contactFromDescription').hasError('required')">
            Please enter a value
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Ex. email@domain.com"[(ngModel)]="ordercontactModel.email" formControlName="email"
            skipValidation />
            <mat-error *ngIf="formOrderContact.get('email').hasError('email') && !formOrderContact.get('email').hasError('required')">
              Please enter a proper email address
            </mat-error>
            <mat-error *ngIf="formOrderContact.get('email').hasError('required')">
              Please enter a value
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-50 pe-2">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" [(ngModel)]="ordercontactModel.name" formControlName="name" />
        </mat-form-field>
        <mat-form-field class="w-50">
          <mat-label>Last name</mat-label>
          <input matInput placeholder="Last Name" [(ngModel)]="ordercontactModel.lastname"
            formControlName="lastname" />
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="Phone Number" type="tel" [(ngModel)]="ordercontactModel.number"
            formControlName="number" pattern="[0-9]*" digitOnly placeholder="0000000000" skipValidation />
          <mat-error *ngIf="this.formOrderContact.controls.number.hasError('minlength')">
            The phone number must contain at least 10 digits
          </mat-error>
        </mat-form-field>
        <mat-checkbox [(ngModel)]="ordercontactModel.shareData" formControlName="shareData"><span
            class="checkbox-label">Share data with this contact</span></mat-checkbox>
      </form>
    </mat-dialog-content>
  </div>
  <div class="d-flex justify-content-end mt-4">
    <button mat-flat-button class="button-cancel me-2" type="button" (click)="onClickClose()">
      <span class="button-cancel-text">{{ modalConfig.actions.cancel || 'Cancel ' }}</span>
    </button>
    <button mat-flat-button color="primary" class="add-button" type="button" (click)="saveContact()"
      [disabled]="!IsValid()" [class.disabled-button]="!IsValid()">
      {{ modalConfig.actions.confirm || 'Add ' }}
    </button>
  </div>
</div>