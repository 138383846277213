import { EnvironmentService } from '@abp/ng.core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-help-component',
  templateUrl: './help-component.component.html',
  styleUrls: ['./help-component.component.scss'],
})
export class HelpComponentComponent {
  faqUrl: string;
  trainingVideosUrl: string;

  constructor(private readonly _environmentService: EnvironmentService) {
    const { helpUrls } = _environmentService.getEnvironment();
    this.faqUrl = helpUrls?.faqUrl ?? 'https://flyguys.com/faq/';
    this.trainingVideosUrl = helpUrls?.trainingVideosUrl ?? 'https://flyguys.com/training-videos/';
  }
}
