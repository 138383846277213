import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AnswerGroupV2Dto, GetAnswerGroupV2Input } from '../../lookups/models';

@Injectable({
  providedIn: 'root',
})
export class AnswerGroupV2Service {
  apiName = 'CoreService';

  GetAnswerGroup = (input: GetAnswerGroupV2Input, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AnswerGroupV2Dto>({
      method: 'GET',
      url: '/api/core-service/answer-group-v2',
      params: input
    },
      { apiName: this.apiName, ...config });

  constructor(private restService: RestService) { }
}
