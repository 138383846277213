<abp-page>
  <h1 *ngIf="!this.export">
    {{ 'missionsService::Project' | abpLocalization }}
  </h1>
  <abp-page-toolbar-container class="col" *ngIf="!this.export">
    <div class="text-lg-end pt-2">
      <app-column-displayer [columnsStatus]="columnsStatus()" [toggler]="toggler" [showColumnPermission]="'missionsService.Project.ShowColumn'"></app-column-displayer>

      <button class="btn btn-primary btn-sm ms-2" type="button" [disabled]="isExportToExcelBusy" *abpPermission="'missionsService.Project.Export'"
        (click)="exportToExcel()">
        {{ 'missionsService::ExportToExcel' | abpLocalization }}
      </button>
      <button *abpPermission="'missionsService.Project.Create'" class="btn btn-primary btn-sm ms-2" type="button"
        (click)="create()">
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'missionsService::NewProjects' | abpLocalization }}
      </button>
    </div>
  </abp-page-toolbar-container>

  <abp-advanced-entity-filters localizationSourceName="missionsService" [list]="list">
    <abp-advanced-entity-filters-form *abpPermission="'missionsService.Project.Filters'">
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Project.Filters.Name'">
            <div class="mb-3">
              <label class="form-label" for="nameFilter">
                {{ 'missionsService::Name' | abpLocalization }}
              </label>

              <input id="nameFilter" class="form-control" [(ngModel)]="filters.name"
                [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Project.Filters.Portafolio'">
            <div class="mb-3">
              <label class="form-label" for="portafolioIdFilter">
                {{ 'missionsService::PortafolioId' | abpLocalization }}
              </label>

              <select class="form-control" id="portafolioIdFilter" [(ngModel)]="filters.portafolioId"
                [ngModelOptions]="{ standalone: true }" (change)="list.get()">
                <option [ngValue]=""></option>
                <option [ngValue]="portafolio.id" *ngFor="let portafolio of portafolios.items"> {{ portafolio.name |
                  abpLocalization }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="minRecurence">
                {{ 'missionsService::MinRecurence' | abpLocalization }}
              </label>

              <input id="minRecurence" type="number" class="form-control" [(ngModel)]="filters.recurenceMin"
                [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>

          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="maxRecurence">
                {{ 'missionsService::MaxRecurence' | abpLocalization }}
              </label>

              <input id="maxRecurence" type="number" class="form-control" [(ngModel)]="filters.recurenceMax"
                [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="locationIdFilter">
                {{ 'missionsService::LocationId' | abpLocalization }}
              </label>

              <select class="form-control" id="locationIdFilter" [(ngModel)]="filters.locationId"
                [ngModelOptions]="{ standalone: true }" (change)="list.get()">
                <option [ngValue]=""></option>
                <option [ngValue]="location.id" *ngFor="let location of locations.items"> {{ location.siteContact |
                  abpLocalization }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="state">
                {{ 'missionsService::State' | abpLocalization }}
              </label>

              <select id="state" class="form-select form-control" [(ngModel)]="filters.state"
                [ngModelOptions]="{ standalone: true }" (change)="list.get()">
                <option [ngValue]=""></option>
                <option [ngValue]="state.value" *ngFor="let state of states">
                  {{ state.key | abpLocalization }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>

  <div class="card">
    <div class="card-body">
      <ngx-datatable [rows]="data.items" [count]="data.totalCount" [list]="list" default [externalPaging]="true" [offset]="pageNumber" [limit]="clientMaxResultCount" #dataTable>
        <ngx-datatable-column name="{{ 'missionsService::Name' | abpLocalization }}" prop="name" *ngIf="showedColumns.name">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::PortafolioId' | abpLocalization }}" prop="portafolioId" *ngIf="showedColumns.portafolio">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ portafolioDict[row.portafolioId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::Recurence' | abpLocalization }}" prop="recurence" *ngIf="showedColumns.recurence">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.recurence }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::LocationId' | abpLocalization }}" prop="locationId" *ngIf="showedColumns.location">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ locationDict[row.locationId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::State' | abpLocalization }}" prop="state" *ngIf="showedColumns.state">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer [columnValue]="row.state" [typeColumn]="'text'" [columnRules]="stateRules"></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'AbpUi::Actions' | abpLocalization" [maxWidth]="150" [width]="150"
          [sortable]="false" *ngIf="!this.export && showedColumns.actions">
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage">
            <app-table-paginator [(maxResultCount)]="list.maxResultCount" [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount" [pageSize]="pageSize" [(pageNumber)]="pageNumber" [curPage]="curPage" [dataTable]="dataTable"
              style="width: 100%;">
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [options]="{ size: 'lg' }" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ (selected ? 'AbpUi::Edit' : 'missionsService::NewProjects') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form" id="projects" (ngSubmit)="submitForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="projects-name">
            {{ 'missionsService::Name' | abpLocalization }} *
          </label>

          <input id="projects-name" formControlName="name" class="form-control" maxlength="100" autofocus />
        </div>
        <div class="mb-3">
          <mat-label class="form-label" for="projects-portafolio-id">
            {{ 'missionsService::PortafolioId' | abpLocalization }} *
          </mat-label>

          <mat-select class="form-control" id="projects-portafolio-id" formControlName="portafolioId">
            <input placeholder="Search" matInput type="text" (keyup)="updatePortafolio($event.target.value)">
            <mat-option [value]="portafolio.id" *ngFor="let portafolio of portafolios.items"> {{ portafolio.name |
              abpLocalization }}
            </mat-option>
          </mat-select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="projects-recurence">
            {{ 'missionsService::Recurence' | abpLocalization }} *
          </label>

          <input type="number" id="projects-recurence" formControlName="recurence" class="form-control" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="projects-state">
            {{ 'missionsService::State' | abpLocalization }} *
          </label>

          <select class="form-control" id="projects-state" formControlName="state">
            <option [ngValue]="state.value" *ngFor="let state of states">
              {{ state.key | abpLocalization }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="projects">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>