import { LocalizationService } from '@abp/ng.core';
import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  readonly _100MBInByte = 102400;
  readonly _1GBInByte = 1073741824;
  readonly _1TBInByte = 1099511627776;

  readonly toKB = 1024;
  readonly toMB = 1048576;

  constructor(private localizationService: LocalizationService) {}

  transform(value: number, ...args: any[]): string {
    const locale = this.localizationService.currentLang;

    if (value < this._100MBInByte) 
      return `${formatNumber(value / this.toKB, locale, '1.0-1')} KB`;

    if (value < this._1GBInByte) 
      return `${formatNumber(value / this.toMB, locale, '1.1-1')} MB`;

    if (value < this._1TBInByte)
      return `${formatNumber(value / this._1GBInByte, locale, '1.1-1')} GB`;

    return `${formatNumber(value / this._1TBInByte, locale, '1.1-1')} TB`;
  }
}
