import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetPilotReportInput, PilotReportsCreateDto, PilotReportsDto, PilotReportsExcelDownloadDto, PilotReportsUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class PilotReportsService {
  apiName = 'pilotsService';
  

  create = (input: PilotReportsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotReportsDto>({
      method: 'POST',
      url: '/api/pilots-service/pilot-report',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/pilot-report/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotReportsDto>({
      method: 'GET',
      url: `/api/pilots-service/pilot-report/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/pilot-report/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetPilotReportInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotReportsDto>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-report',
      params: { filterText: input.filterText, reportId: input.reportId, pilotId: input.pilotId, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: PilotReportsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/pilot-report/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

  getPilotLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-report/pilot-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });

  getReportLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-report/report-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  
  update = (id: string, input: PilotReportsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotReportsDto>({
      method: 'PUT',
      url: `/api/pilots-service/pilot-report/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
