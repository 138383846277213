import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetStatusesInput,
  StatusCreateDto,
  StatusDto,
  StatusExcelDownloadDto,
  StatusUpdateDto,
} from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  apiName = 'CoreService';

  create = (input: StatusCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StatusDto>(
      {
        method: 'POST',
        url: '/api/core-service/statuses',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/core-service/statuses/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StatusDto>(
      {
        method: 'GET',
        url: `/api/core-service/statuses/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/core-service/statuses/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetStatusesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<StatusDto>>(
      {
        method: 'GET',
        url: '/api/core-service/statuses',
        params: {
          filterText: input.filterText,
          description: input.description,
          code: input.code,
          stateMin: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: StatusExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/core-service/statuses/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: StatusUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StatusDto>(
      {
        method: 'PUT',
        url: `/api/core-service/statuses/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
