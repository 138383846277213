import { Component, Input, OnInit } from '@angular/core';
import { ABP, ListService, TrackByService, PagedResultDto } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import {
  GetOrderHistoryInput,
  OrdersHistoryDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { OrdersService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { GoogleMapsService } from '@flyguys/services';
import { MapGeocoderResponse } from '@angular/google-maps';

@Component({
  selector: 'app-pilot-profile-history',
  templateUrl: './pilot-profile-history.component.html',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  styleUrls: ['./pilot-profile-history.component.scss'],
})
export class PilotProfileHistoryComponent implements OnInit {
  @Input() pilotId: string;

  ordersHistory: PagedResultDto<OrdersHistoryDto> = {
    items: [],
    totalCount: 0,
  };

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: OrdersService,
    private googleMapsService: GoogleMapsService
  ) {}

  ngOnInit() {
    var filters = { isPaginated: false, pilotId: this.pilotId } as GetOrderHistoryInput;
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getJoinList({
        ...query,
        ...filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<OrdersHistoryDto>) => {
      list.items.forEach(order => {
        this.googleMapsService.getReverseGeoCode(order.location).subscribe({
          next: (selection: MapGeocoderResponse) => {
            const [response] = selection.results;
            response.address_components.forEach(ac => {
              if (
                ac.types.includes('locality') ||
                ac.types.includes('administrative_area_level_2') ||
                ac.types.includes('administrative_area_level_1')
              ) {
                order.location = ac.long_name;
              }
            });
          },
          error: err => {
            order.location = '';
            console.error('Geocoding Input: ', err);
          },
        });
      });
      this.ordersHistory = list;
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }
}
