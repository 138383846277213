import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetMissionFinancialRevenuesInput, MissionFinancialRevenueCreateDto, MissionFinancialRevenueDto, MissionFinancialRevenueExcelDownloadDto, MissionFinancialRevenueUpdateDto, MissionFinancialRevenueWithNavigationPropertiesDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MissionFinancialRevenueService {
  apiName = 'missionsService';
  

  create = (input: MissionFinancialRevenueCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialRevenueDto>({
      method: 'POST',
      url: '/api/missions-service/mission-financial-revenues',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/mission-financial-revenues/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialRevenueDto>({
      method: 'GET',
      url: `/api/missions-service/mission-financial-revenues/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDeliverablesLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/missions-service/mission-financial-revenues/deliverables-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/mission-financial-revenues/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetMissionFinancialRevenuesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionFinancialRevenueWithNavigationPropertiesDto>>({
      method: 'GET',
      url: '/api/missions-service/mission-financial-revenues',
      params: { filterText: input.filterText, revenueTypeId: input.revenueTypeId, unitsMin: input.unitsMin, unitsMax: input.unitsMax, itemPriceMin: input.itemPriceMin, itemPriceMax: input.itemPriceMax, totalPriceMin: input.totalPriceMin, totalPriceMax: input.totalPriceMax, deliverablesId: input.deliverablesId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: MissionFinancialRevenueExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/mission-financial-revenues/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialRevenueWithNavigationPropertiesDto>({
      method: 'GET',
      url: `/api/missions-service/mission-financial-revenues/with-navigation-properties/${id}`,
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: MissionFinancialRevenueUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFinancialRevenueDto>({
      method: 'PUT',
      url: `/api/missions-service/mission-financial-revenues/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
