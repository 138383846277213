import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BadgesCreateDto, BadgesDto, BadgesExcelDownloadDto, BadgesUpdateDto, GetBadgeInput } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class BadgesService {
  apiName = 'missionsService';
  

  create = (input: BadgesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgesDto>({
      method: 'POST',
      url: '/api/missions-service/badge',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/badge/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgesDto>({
      method: 'GET',
      url: `/api/missions-service/badge/${id}`,
    },
    { apiName: this.apiName,...config });
  
getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/badge/download-token',
    },
    { apiName: this.apiName,...config });

getListAsExcelFile = (input: BadgesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/badge/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetBadgeInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<BadgesDto>>({
      method: 'GET',
      url: '/api/missions-service/badge',
      params: { filterText: input.filterText, name: input.name, model: input.model, brand: input.brand, manufacturer: input.manufacturer, specs: input.specs, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: BadgesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgesDto>({
      method: 'PUT',
      url: `/api/missions-service/badge/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
