<mat-card class="px-1 py-2">
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center">
      <div class="icon-container d-flex justify-content-center align-items-center">
        <div class="rounded-circle">
          <span class="material-symbols-outlined">{{ icon }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end m-1">
      <div>
        <div>
          <span class="text-muted card-subtitle h6 mb-1 card-title" title="{{ title }}">{{
            title
          }}</span>
        </div>

        <div class="text-dark text-dark h5 mb-0">
          <b>{{ description }}</b>
        </div>
      </div>
    </div>
  </div>
</mat-card>
