import { EnvironmentService } from '@abp/ng.core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDescriptorDto } from '@volo/abp.ng.file-management/proxy';
import { Observable, Subject, empty, finalize, map, retry, takeWhile, timer } from 'rxjs';
import { concat } from 'rxjs';
import { ChunkCompleteModel } from '../models/file-upload/chunk-complete.model';
import { ChunkFileModel } from '../models/file-upload/chunk-file.model';
import { ChunkModel } from '../models/file-upload/chunk-model';
import { ChunkUploadResultModel } from '../models/file-upload/chunk-upload-result.model';
import { UploadFileProgressModel } from '../models/file-upload/upload-file-progress.model';

@Injectable({
  providedIn: 'root'
})
export class ChunkUploaderService {

  private readonly MAX_CHUNK_SIZE = 50000000;

  apiName = 'FileManagement';

  get apiUrl() {
    return this.environment.getApiUrl(this.apiName);
  }

  constructor(private http: HttpClient,
    private environment: EnvironmentService) { }

  get chunkSize(): number {
    const { apis } = this.environment.getEnvironment();
    return Number(apis[this.apiName]?.uploadChunkSizeInBytes || this.MAX_CHUNK_SIZE);
  }

  uploadFile(file: File, directoryId: string = null, cancelControlSubject: Subject<boolean> = null): Subject<UploadFileProgressModel> {

    const subjectResult = new Subject<UploadFileProgressModel>();

    const chunkFileModel = this.splitFileInChunks(this.chunkSize, file);

    this.http.get(`${this.apiUrl}/api/file-management/file-descriptor/file/id`)
      .subscribe({
        next: (res: { id: string }) => {
          chunkFileModel.blobName = res.id;
          subjectResult.next({ fileFinished: undefined, fileId: undefined, currentProgress: 1, currentFileInfo: chunkFileModel });
          this.processChunks(chunkFileModel, file, directoryId, subjectResult, cancelControlSubject);
        },
        error: err => {
          console.error("Unable to create field to upload file:\n", err);
        }
      });

    return subjectResult;
  }

  private splitFileInChunks(maxChunkSize: number, file: File): ChunkFileModel {

    const totalChunks = Math.ceil(file.size / maxChunkSize);

    const chunkFileModel = new ChunkFileModel();
    chunkFileModel.totalChunks = totalChunks;
    chunkFileModel.fileName = file.name;
    chunkFileModel.chunks = [];

    for (let i = 0; i < totalChunks; i++) {
      const start = i * maxChunkSize;
      const end = Math.min(start + maxChunkSize, file.size);

      const chunkModel = new ChunkModel();
      chunkModel.end = end;
      chunkModel.start = start;
      chunkModel.chunkNumber = i;

      chunkFileModel.chunks.push(chunkModel);
    }

    return chunkFileModel;
  }

  private processChunks(chunkFileModel: ChunkFileModel, file: File, directoryId: string,
    subjectResult: Subject<UploadFileProgressModel>, cancelControlSubject: Subject<boolean> = null): void {

    const obs: Array<Observable<any>> = [];
    let uploadCanceled = false;

    if (cancelControlSubject) {
      cancelControlSubject.subscribe(cancel => {
        uploadCanceled = cancel;
      });
    }

    for (const chk of chunkFileModel.chunks) {

      const chunk = file.slice(chk.start, chk.end);
      const formData = new FormData();

      formData.append('file', chunk, file.name);

      const headers = new HttpHeaders()
        .set('Content-Range', `${chk.start}-${chk.end - 1}`)
        .set('Chunk-Number', `${chk.chunkNumber}`)
        .set('File-Size', `${file.size}`)
        .set('Blob-Name', `${chunkFileModel.blobName ? chunkFileModel.blobName : ''}`)
        .set("WebAppChunkId", chk.webAppChunkId);

      obs.push(this.http.post(`${this.apiUrl}/api/file-management/file-descriptor/chunkUpload`, formData, { headers }));
    }

    concat(...obs)
      .pipe(
        takeWhile(_ => {
          return uploadCanceled == false
        }),
        map((value: ChunkUploadResultModel) => {
          if (!value.chunkIdentifier || !value.chunkNumber) {
            console.error("Chunk identifier not found:\n", value);
            throw new Error("Chunk identifier not found");
          }
          return value;
        }),
        retry({
          delay: (error, retryCount: number) => {
            if (retryCount >= 100 || uploadCanceled) {
              throw error;
            }
            return timer(1000);
          }
        }),
        finalize(() => {
          if (uploadCanceled) {
            console.log("Upload canceled");
            return;
          }
          this.completeChunksUpload(chunkFileModel, file, directoryId, subjectResult);
        }),
      )
      .subscribe({
        next: (res: ChunkUploadResultModel) => {
          const uploaded = chunkFileModel.chunks.find(x => (x.chunkNumber == res.chunkNumber) || (x.identifier == res.chunkIdentifier));

          if (!uploaded) {
            console.error(`Uploaded chunk [${res.chunkNumber}] not found`);
            return;
          }
          uploaded.identifier = res.chunkIdentifier;
          uploaded.success = true;

          if (!chunkFileModel.blobName)
            chunkFileModel.blobName = res.blobName;

          const progress = this.calculateProgress(chunkFileModel.totalChunks, Number(res.chunkNumber));

          subjectResult.next({ fileFinished: undefined, fileId: undefined, currentProgress: progress, currentFileInfo: chunkFileModel });

          if (res.thumbnail)
            chunkFileModel.thumbnail = res.thumbnail;
        },
        error: err => {
          console.error("Chunk upload error:\n", err);
        }
      });
  }

  private calculateProgress(totalChunks: number, chunkNumber: number): number {

    return ((chunkNumber + 1) * 100 / totalChunks);
  }

  private completeChunksUpload(chunkFileModel: ChunkFileModel, file: File, directoryId: string, subjectResult: Subject<UploadFileProgressModel>): void {

    const completeUpload: ChunkCompleteModel = {
      fileName: file.name,
      chunkIds: chunkFileModel.chunks.map(x => x.identifier),
      contentLenght: file.size,
      thumbnailUrl: chunkFileModel.thumbnail,
      directoryId: directoryId,
      blobName: chunkFileModel.blobName
    }

    this.http.post(`${this.apiUrl}/api/file-management/file-descriptor/completeUpload`,
      completeUpload)
      .subscribe({
        next: (res: FileDescriptorDto) => {
          subjectResult.next({ fileFinished: file.name, fileId: res.id, currentProgress: 100, currentFileInfo: chunkFileModel });
        },
        error: err => {
          subjectResult.next(null);
          console.error("Unable to complete upload:\n", err);
        }
      });
  }
}
