import { Injectable } from '@angular/core';
import { Rest, RestService } from '@abp/ng.core';
import { CaptureWithDeliverablesDto } from '../pilot-sourcing/models/capture-with-deliverables-dto';
import { OrderRequestModel } from '../pilot-sourcing/models/order-request-model';
import { ActionOrdered } from '../pilot-sourcing/models/action-ordered-dto';
import { OrderResumeDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals/models';
import { MissionFlowDto } from '../pilot-sourcing/models/mission-flow-dto';
import { CaptureInfoDto } from '../models/products-deliverables/package-dto';
import { MissionAssignmentsDto } from '../models/missions/mission-assignments-dto';

@Injectable({
  providedIn: 'root',
})
export class MissionFlowService {
  apiName = 'missionsService';
  coreServiceApi = 'CoreService';

  constructor(private restService: RestService) {}

  getCapturesWithDeliverables = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CaptureWithDeliverablesDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/captures-with-deliverables/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getOrderRequestData = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderRequestModel>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/order-request/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getOrderResume = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderResumeDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-orderaf/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getActionsPermissions = (missionStatusCode: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrdered[]>(
      {
        method: 'GET',
        url: `/api/core-service/action-orders/actions-permissions/${missionStatusCode}`,
      },
      { apiName: this.coreServiceApi, ...config }
    );

  getMissionData = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFlowDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/mission-data/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );
  getByMissionAndDeliverableIdAsync = (
    missionId: string,
    deliverableId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, CaptureInfoDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/mission/${missionId}/deliverable/${deliverableId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getMissionAssignmentByRoleName = (
    missionId: string,
    roleName: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, MissionAssignmentsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/get-mission-assignment-by-role-name/${missionId}/${roleName}`,
      },
      { apiName: this.apiName, ...config }
    );
}
