import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  PilotPreferredLocationsCreateDto,
  PilotPreferredLocationsDto,
} from '../../basics/models';

@Injectable({
  providedIn: 'root',
})
export class PilotPreferredLocationsService {
  apiName = 'pilotsService';

  create = (input: PilotPreferredLocationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilots/preferredLocations',
        body: input,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  update = (input: PilotPreferredLocationsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto>(
      {
        method: 'PUT',
        url: `/api/pilots-service/pilots/preferredLocations/${input.id}`,
        body: input,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto>(
      {
        method: 'DELETE',
        url: `/api/pilots-service/pilots/preferredLocations/${id}`,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  createList = (input: PilotPreferredLocationsCreateDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto[]>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilots/preferredLocations/list',
        body: input,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  updateList = (input: PilotPreferredLocationsDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto[]>(
      {
        method: 'PUT',
        url: '/api/pilots-service/pilots/preferredLocations/list',
        body: input,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  constructor(private restService: RestService) {}
}
