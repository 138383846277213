<div class="d-flex paginator-container">
  <div class="d-flex page-size align-items-center">
    <label for="pageSize" class="me-1">
      {{ 'CoreService::PageSize' | abpLocalization }}
    </label>

    <select
      id="pageSize"
      [(ngModel)]="clientMaxResultCount"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="!(rowCount / pageSize > 1)"
      (change)="pageSizeSelected()"
      >
      <option [ngValue]="value" *ngFor="let value of valuesMaxResultCount">
        {{ value }}
      </option>
    </select>
  </div>


  <datatable-pager
    class="d-flex justify-content-center align-items-center"
    [pagerLeftArrowIcon]="'datatable-icon-left'"
    [pagerRightArrowIcon]="'datatable-icon-right'"
    [pagerPreviousIcon]="'datatable-icon-prev'"
    [pagerNextIcon]="'datatable-icon-skip'"
    [page]="curPage"
    [size]="pageSize"
    [count]="rowCount"
    (change)="dataTable.onFooterPage($event)"
  >
  </datatable-pager>
  <div class="d-flex page-count justify-content-end">
    {{ rowCount?.toLocaleString() }} total
  </div>
</div>