<h1>{{ 'Flyguys::Help' | abpLocalization }}</h1>
<div class="container">
  <div class="row d-flex w-100 justify-content-center align-items-center">
    <div class="col-12 mx-auto help-title">
      <h3>{{ 'Flyguys::FAQAndTrainingVideosTitle' | abpLocalization }}</h3>
    </div>
    <div class="col-7">
      <p class="help-description">
        {{ 'Flyguys::FAQAndTrainingVideosDescription' | abpLocalization }}
      </p>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center">
        <a [href]="faqUrl" class="btn btn-primary me-2 w-25" target="_blank">
          {{ 'Flyguys::FAQ' | abpLocalization }}
        </a>
        <a [href]="trainingVideosUrl" class="btn btn-primary w-25" target="_blank">
          {{ 'Flyguys::TrainingVideos' | abpLocalization }}
        </a>
      </div>
    </div>
  </div>
</div>
