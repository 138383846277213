import { Component, OnDestroy, OnInit } from '@angular/core';

import { PackageDto } from '../../../models/products-deliverables/package-dto';
import { DeliverableDto } from '../../../models/products-deliverables/deliverable-dto';
import { DeliverableTypeModel } from '../../../models/products-deliverables/deliverable-type.model';
import { ActivatedRoute } from '@angular/router';
import { ProductsDeliverablesService } from '../../../services/products-deliverables.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-deliverables',
  templateUrl: './deliverables.component.html',
  styleUrls: ['./deliverables.component.scss'],
})
export class DeliverablesComponent implements OnInit, OnDestroy {
  selectedOption: 'package' | 'deliverable' = 'package';
  missionId: string;
  deliverables: DeliverableDto[] = [];
  packages: PackageDto[] = [];
  deliverableTypes: DeliverableTypeModel[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private productsDeliverablesService: ProductsDeliverablesService
  ) {}

  deliverableColumns = [
    { name: 'Deliverable', prop: 'deliverableName' },
    { name: 'Product', prop: 'product' },
    { name: 'Package', prop: 'package' },
    { name: 'Actions' },
    { name: 'DeliverableId', prop: 'deliverableId' },
  ];

  isAddModifyModalBusy = false;

  isAddModifyModalOpen = false;

  selectedDeliverableType: number | null = null;

  ngOnInit() {
    this.missionId = this.route.snapshot.paramMap.get('missionId');

    this.subscriptions.push(
      this.productsDeliverablesService.getDeliverablesByMission(this.missionId).subscribe({
        next: response => {
          this.deliverables = response;
        },
        error: error => {
          console.log(error);
        },
      })
    );

    this.subscriptions.push(
      this.productsDeliverablesService.getPackagesByMission(this.missionId).subscribe({
        next: response => {
          this.packages = response;
        },
        error: error => {
          console.log(error);
        },
      })
    );

    this.subscriptions.push(
      this.productsDeliverablesService.getDeliverableTypes().subscribe({
        next: response => {
          this.deliverableTypes = response;
        },
        error: error => {
          console.log(error);
        },
      })
    );
  }

  addOrModify() {
    this.isAddModifyModalOpen = true;
  }

  navigateView(row) {
    console.log(row);
  }

  openInfo(row) {
    console.log(row);
  }

  delete(row) {
    console.log(row);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
