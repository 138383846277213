import { Injectable } from '@angular/core';
import { PagedResultDto, Rest, RestService } from '@abp/ng.core';
import { PilotSourcingTableDto, PilotsEquipments, PilotsBadges, PilotsNotes, PilotDocument } from '../pilot-sourcing/models/pilot-sourcing-dto';
import { GetPilotsForSourcingInput, PilotRequest } from '../pilot-sourcing/models/pilot-for-sorucing-request';

@Injectable({
  providedIn: 'root',
})
export class PilotSourcingService {
  apiName = 'pilotsService';

  constructor(private restService: RestService) {}

  getList = (input: GetPilotsForSourcingInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotSourcingTableDto>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-sourcing/pilots-for-sourcing',
        params: {
          filterText: input.filterText,
          firstName: input.firstName,
          lastName: input.lastName,
          pilotExperienceLevelId: input.pilotExperienceLevelId,
          pilotStateId: input.pilotStateId,
          stateMin: input.stateMin,
          stateMax: input.stateMax,
          ratingMin: input.ratingMin,
          ratingMax: input.ratingMax,
          upcomingMissionsMin: input.upcomingMissionsMin,
          upcomingMissionsMax: input.upcomingMissionsMax,
          missionCountMin: input.missionCountMin,
          missionCountMax: input.missionCountMax,
          perfectMissionPercentageMin: input.perfectMissionPercentageMin,
          perfectMissionPercentageMax: input.perfectMissionPercentageMax,
          address: input.address,
          phone: input.phone,
          email: input.email,
          requestStatusId: input.requestStatusId,
          availabilityId: input.availabilityId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
          missionId: input.missionId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getEquipments = (input: PilotRequest, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotsEquipments>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-sourcing/pilots-equipments',
        params: {
          pilotId: input.pilotId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount
        },
      },
      { apiName: this.apiName, ...config }
    );

  getBadges = (input: PilotRequest, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotsBadges>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-sourcing/pilots-badges',
        params: {
          pilotId: input.pilotId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount
        },
      },
      { apiName: this.apiName, ...config }
    );

  getNotes = (input: PilotRequest, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotsNotes>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-sourcing/pilots-notes',
        params: {
          pilotId: input.pilotId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount
        },
      },
      { apiName: this.apiName, ...config }
    );

  getFiles = (input: PilotRequest, config?: Partial<Rest.Config>) => {
    return this.restService.request<any, PagedResultDto<PilotDocument>>(
      {
        method: "GET",
        url: "/api/pilots-service/pilot-sourcing/pilot-files",
        params: {...input}
      },
      {
        apiName: this.apiName,
        ...config
      }
    );
  }
}
