import { Component } from '@angular/core';
import { RoundedCardComponent } from '../rounded-card/rounded-card.component';

@Component({
  selector: 'app-icon-aside',
  templateUrl: './icon-aside.component.html',
  styleUrls: ['./icon-aside.component.scss'],
})
export class IconAsideComponent extends RoundedCardComponent {
  constructor() {
    super();
  }
}
