import { ListService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderFormProductModel } from 'projects/flyguys-pilot/src/app/components/orders/model/order-form-product-model';
import { FormHelpers } from 'projects/flyguys-pilot/src/app/form-helpers';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent {
  @Input() product: OrderFormProductModel;
  formProduct: FormGroup;

  constructor() {
    this.formProduct = FormHelpers.buildValidatorsProduct();
  }
}
