import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule, PermissionService } from '@abp/ng.core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ColumnStylizerComponent } from './components/column-stylizer/column-stylizer.component';
import { ColumnActionsComponent } from './components/column-actions/column-actions.component';
import { ColumnDisplayerComponent } from './components/column-displayer/column-displayer.component';



@NgModule({
  declarations: [ColumnStylizerComponent, ColumnActionsComponent, ColumnDisplayerComponent],
  imports: [
    CommonModule,
    CoreModule,
    MatCheckboxModule
  ],
  exports: [ColumnStylizerComponent, ColumnActionsComponent, ColumnDisplayerComponent],
  providers: [PermissionService]
})
export class ColumnsModule { }
