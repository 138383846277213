import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule()
export class MissionsServiceConfigModule {
  static forRoot(): ModuleWithProviders<MissionsServiceConfigModule> {
    return {
      ngModule: MissionsServiceConfigModule,
      providers: [ ],
    };
  }
}
